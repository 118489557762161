/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.0
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
} from 'fuels';

import type { Enum, Vec } from "./common";

export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type AssetIdInput = { bits: string };
export type AssetIdOutput = AssetIdInput;
export type CombinedTroveDataInput = { address: IdentityInput, collateral: BigNumberish, collateral_rewards: BigNumberish, debt: BigNumberish, debt_rewards: BigNumberish };
export type CombinedTroveDataOutput = { address: IdentityOutput, collateral: BN, collateral_rewards: BN, debt: BN, debt_rewards: BN };
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;

export type MultiTroveGetterContractConfigurables = Partial<{
  SORTED_TROVES_CONTRACT: ContractIdInput;
}>;

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "struct CombinedTroveData",
      "concreteTypeId": "c69fc82df3ef957eb41058bd936bf5a3478fb6b6d81ee5ed2d4f354027cf4deb",
      "metadataTypeId": 4
    },
    {
      "type": "struct std::asset_id::AssetId",
      "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
      "metadataTypeId": 6
    },
    {
      "type": "struct std::contract_id::ContractId",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "metadataTypeId": 7
    },
    {
      "type": "struct std::vec::Vec<struct CombinedTroveData>",
      "concreteTypeId": "2a319aa999cf4b01cb2807bf6a75dccb571c340c0c3da154e78e54983989b458",
      "metadataTypeId": 9,
      "typeArguments": [
        "c69fc82df3ef957eb41058bd936bf5a3478fb6b6d81ee5ed2d4f354027cf4deb"
      ]
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    },
    {
      "type": "u8",
      "concreteTypeId": "c89951a24c6ca28c13fd1cfdc646b2b656d69e61a92b91023be7eb58eb914b6b"
    }
  ],
  "metadataTypes": [
    {
      "type": "b256",
      "metadataTypeId": 0
    },
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 1,
      "components": [
        {
          "name": "Address",
          "typeId": 5
        },
        {
          "name": "ContractId",
          "typeId": 7
        }
      ]
    },
    {
      "type": "generic T",
      "metadataTypeId": 2
    },
    {
      "type": "raw untyped ptr",
      "metadataTypeId": 3
    },
    {
      "type": "struct CombinedTroveData",
      "metadataTypeId": 4,
      "components": [
        {
          "name": "address",
          "typeId": 1
        },
        {
          "name": "collateral",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "collateral_rewards",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "debt",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "debt_rewards",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 5,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::asset_id::AssetId",
      "metadataTypeId": 6,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 7,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::vec::RawVec",
      "metadataTypeId": 8,
      "components": [
        {
          "name": "ptr",
          "typeId": 3
        },
        {
          "name": "cap",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "typeParameters": [
        2
      ]
    },
    {
      "type": "struct std::vec::Vec",
      "metadataTypeId": 9,
      "components": [
        {
          "name": "buf",
          "typeId": 8,
          "typeArguments": [
            {
              "name": "",
              "typeId": 2
            }
          ]
        },
        {
          "name": "len",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "typeParameters": [
        2
      ]
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "trove_manager_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "asset_id",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        },
        {
          "name": "start_indx",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "count",
          "concreteTypeId": "c89951a24c6ca28c13fd1cfdc646b2b656d69e61a92b91023be7eb58eb914b6b"
        }
      ],
      "name": "get_multiple_sorted_troves",
      "output": "2a319aa999cf4b01cb2807bf6a75dccb571c340c0c3da154e78e54983989b458",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [],
  "messagesTypes": [],
  "configurables": [
    {
      "name": "SORTED_TROVES_CONTRACT",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "offset": 10328
    }
  ]
};

const storageSlots: StorageSlot[] = [];

export class MultiTroveGetterContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    get_multiple_sorted_troves: FunctionFragment;
  };
}

export class MultiTroveGetterContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: MultiTroveGetterContractInterface;
  declare functions: {
    get_multiple_sorted_troves: InvokeFunction<[trove_manager_contract: ContractIdInput, asset_id: AssetIdInput, start_indx: BigNumberish, count: BigNumberish], Vec<CombinedTroveDataOutput>>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}

import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle } = createMultiStyleConfigHelpers(cardAnatomy.keys);

export const CardStyles = {
  variants: {
    darkCard: definePartsStyle({
      container: {
        w: "100%",
        alignItems: "center",
        borderRadius: 15,
        backgroundColor: "#202020",
      },
    }),
  },
};

import { Asset } from "fuels";
import { Contracts as MainnetContracts } from "../../shared/contracts.mainnet";
import { Contracts as TestnetContracts } from "../../shared/contracts.testnet";

export const WalletAssets: Array<Asset> = [
  {
    networks: [
      {
        type: "fuel",
        chainId: 0,
        decimals: 9,
        assetId: TestnetContracts.UsdfAssetId,
      },
      {
        type: "fuel",
        chainId: 9889,
        decimals: 9,
        assetId: MainnetContracts.UsdfAssetId,
      },
    ],
    icon: "https://i.imgur.com/hmOehB9.png",
    name: "USDF",
    symbol: "USDF",
  },
  {
    networks: [
      {
        type: "fuel",
        chainId: 0,
        decimals: 9,
        assetId: TestnetContracts.fptAssetId,
      },
      {
        type: "fuel",
        chainId: 9889,
        decimals: 9,
        assetId: MainnetContracts.fptAssetId,
      },
    ],
    icon: "https://i.imgur.com/SLGOAB7.png",
    name: "Fluid Protocol Token",
    symbol: "FPT",
  },
  {
    networks: [
      {
        type: "fuel",
        chainId: 0,
        decimals: 9,
        assetId: TestnetContracts.assets[0].assetId,
      },
    ],
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ethereum-icon-purple.svg/2048px-Ethereum-icon-purple.svg.png",
    name: "mock ETH",
    symbol: "fETH",
  },
  {
    networks: [
      {
        type: "fuel",
        chainId: 0,
        decimals: 9,
        assetId: TestnetContracts.assets[1].assetId,
      },
    ],
    icon: "https://cryptologos.cc/logos/steth-steth-logo.png",
    name: "mock wstETH",
    symbol: "wstETH",
  },
];

import { B256Address, Bech32Address, BN } from "fuels";
import { PRECISION } from "./format";
import { AssetContracts } from "./contracts";

export interface TrovePosition {
  owner: string;
  debt: BN;
  coll: BN;
  debt_reward: BN;
  coll_reward: BN;
}

export function getTroveCollateralRatio(trove: TrovePosition, price: BN) {
  return price
    .mul(trove.coll.add(trove.coll_reward.div(PRECISION)))
    .div(trove.debt.add(trove.debt_reward));
}

export interface AssetConfig {
  name: string;
  symbol: string;
  color: string;
  boxColor: string;
  imageUrl: string;
}

export const createAssetContracts = (
  index: number,
  assetConfig: AssetConfig,
  contracts: JSON
): AssetContracts => {
  const config = assetConfig;
  const contractData = (contracts as any).asset_contracts[index];

  return {
    asset_contract: contractData.asset_contract as Bech32Address,
    assetId: contractData.asset_id as string,
    assetName: config.name,
    assetSymbol: config.symbol,
    color: config.color,
    boxColor: config.boxColor,
    imageUrl: config.imageUrl,
    oracle: contractData.oracle as Bech32Address,
    troveManager: contractData.trove_manager as Bech32Address,
    pythContract: contractData.pyth_contract as Bech32Address,
    oracleImplementation: contractData.oracle_implementation_id as B256Address,
    troveManagerImplementation:
      contractData.trove_manager_implementation_id as B256Address,
    disabled: contractData.disabled,
  };
};

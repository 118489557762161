/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.0
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
  StrSlice,
} from 'fuels';

import type { Enum } from "./common";

export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type AssetIdInput = { bits: string };
export type AssetIdOutput = AssetIdInput;
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;
export type ProvideToStabilityPoolEventInput = { user: IdentityInput, amount_to_deposit: BigNumberish, initial_amount: BigNumberish, compounded_amount: BigNumberish };
export type ProvideToStabilityPoolEventOutput = { user: IdentityOutput, amount_to_deposit: BN, initial_amount: BN, compounded_amount: BN };
export type StabilityPoolLiquidationEventInput = { asset_id: AssetIdInput, debt_to_offset: BigNumberish, collateral_to_offset: BigNumberish };
export type StabilityPoolLiquidationEventOutput = { asset_id: AssetIdOutput, debt_to_offset: BN, collateral_to_offset: BN };
export type WithdrawFromStabilityPoolEventInput = { user: IdentityInput, amount_to_withdraw: BigNumberish, initial_amount: BigNumberish, compounded_amount: BigNumberish };
export type WithdrawFromStabilityPoolEventOutput = { user: IdentityOutput, amount_to_withdraw: BN, initial_amount: BN, compounded_amount: BN };

export type StabilityPoolContractConfigurables = Partial<{
  INITIALIZER: IdentityInput;
}>;

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "enum std::identity::Identity",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "metadataTypeId": 1
    },
    {
      "type": "str",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "type": "struct events::ProvideToStabilityPoolEvent",
      "concreteTypeId": "a567b58476a802421e3b6b8f5b1a982925c7dc4f6f6764f3d41f574d9fd7c747",
      "metadataTypeId": 2
    },
    {
      "type": "struct events::StabilityPoolLiquidationEvent",
      "concreteTypeId": "3ee6b6e70f9fd87e927dfb0add10cc9dc5b6c519afaddf82f2c126184b61c6e6",
      "metadataTypeId": 3
    },
    {
      "type": "struct events::WithdrawFromStabilityPoolEvent",
      "concreteTypeId": "7e66840a7ccd8e6c8e5cf2d46eb1f21321e2470519ab796c49d553df6249a8be",
      "metadataTypeId": 4
    },
    {
      "type": "struct std::asset_id::AssetId",
      "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
      "metadataTypeId": 6
    },
    {
      "type": "struct std::contract_id::ContractId",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "metadataTypeId": 7
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    }
  ],
  "metadataTypes": [
    {
      "type": "b256",
      "metadataTypeId": 0
    },
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 1,
      "components": [
        {
          "name": "Address",
          "typeId": 5
        },
        {
          "name": "ContractId",
          "typeId": 7
        }
      ]
    },
    {
      "type": "struct events::ProvideToStabilityPoolEvent",
      "metadataTypeId": 2,
      "components": [
        {
          "name": "user",
          "typeId": 1
        },
        {
          "name": "amount_to_deposit",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "initial_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "compounded_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct events::StabilityPoolLiquidationEvent",
      "metadataTypeId": 3,
      "components": [
        {
          "name": "asset_id",
          "typeId": 6
        },
        {
          "name": "debt_to_offset",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "collateral_to_offset",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct events::WithdrawFromStabilityPoolEvent",
      "metadataTypeId": 4,
      "components": [
        {
          "name": "user",
          "typeId": 1
        },
        {
          "name": "amount_to_withdraw",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "initial_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "compounded_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 5,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::asset_id::AssetId",
      "metadataTypeId": 6,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 7,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "trove_manager_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        },
        {
          "name": "oracle_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "add_asset",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "get_asset",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "depositor",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_compounded_usdf_deposit",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "depositor",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "get_depositor_asset_gain",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "depositor",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_depositor_fpt_gain",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_total_usdf_deposits",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "usdf_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "community_issuance_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "protocol_manager",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "active_pool_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "sorted_troves_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "initialize",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "debt_to_offset",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "coll_to_offset",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "offset",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "provide_to_stability_pool",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "withdraw_from_stability_pool",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "10098701174489624218",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "logId": "4532511178510817406",
      "concreteTypeId": "3ee6b6e70f9fd87e927dfb0add10cc9dc5b6c519afaddf82f2c126184b61c6e6"
    },
    {
      "logId": "11918694519390339650",
      "concreteTypeId": "a567b58476a802421e3b6b8f5b1a982925c7dc4f6f6764f3d41f574d9fd7c747"
    },
    {
      "logId": "9108112476981792364",
      "concreteTypeId": "7e66840a7ccd8e6c8e5cf2d46eb1f21321e2470519ab796c49d553df6249a8be"
    }
  ],
  "messagesTypes": [],
  "configurables": [
    {
      "name": "INITIALIZER",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "offset": 79368
    }
  ]
};

const storageSlots: StorageSlot[] = [
  {
    "key": "04af14dd7e7552021a0c571f2bc3d630b8573237f5a0e71ea149ec9a07a8953c",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2aa95707a42f70b4725e4552a7188e45ac9ed6175dafb119b55b2abc7e96247d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "38a1b038206f348ec947ba5770c6d713148c9d8bb0b312f47acc88777da1c09d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "40525a7c302daa1f559ef8e0fe32afc5d142817049e39ba4d5a59e54fc32e1ae",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "693c85e9b9b8418dee641529266ad1c271b844c9d3a1c18ad6f28443949adfc2",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "736bccd90a317bad16cdcd4535ef9a8cba68df3d0dc068e53e9b7e261fcf5990",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "74a452e9ada47da3c80b437c521ddd2686815d986928071dbf5214b1145771dc",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "7d087ecc6370658692dd5fa86925fa0677d6761ac7c060921166ad5b658ba9bf",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "87353d426df56d07c81ae9f5f8e646cf8ef8b797dd469ce4574d9166e9d12eda",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "891318a27eea9b54463000dff63c65b71879711a67d65bf73c42e996dc898faf",
    "value": "0000000000000000000000003b9aca0000000000000000000000000000000000"
  },
  {
    "key": "8acc4ae8995b85711fe6b544b21f80799f86f33621bd1cfebdbf42e9feb958e0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "95eedfed437e480dc7e2ee78939419fe38c47ad01631c8360a9c64fa43d57632",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "b7fdc956bc104f3a0d1b2d2d46bfca549a038a02b9f43a994d8e304b0380def2",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cb9577725f184fce78b06b13a63c5b7c001827a18cda13cb502b81451aabf25b",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cc4aebd93a7a806b3ea1a19b18d3ba721807d66771b1c2696f03c15fb84e1fd0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cf227e666ed3074e15eeb2923304091f467211295519917b0ba3f1dfd12c6207",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class StabilityPoolContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    add_asset: FunctionFragment;
    get_asset: FunctionFragment;
    get_compounded_usdf_deposit: FunctionFragment;
    get_depositor_asset_gain: FunctionFragment;
    get_depositor_fpt_gain: FunctionFragment;
    get_total_usdf_deposits: FunctionFragment;
    initialize: FunctionFragment;
    offset: FunctionFragment;
    provide_to_stability_pool: FunctionFragment;
    withdraw_from_stability_pool: FunctionFragment;
  };
}

export class StabilityPoolContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: StabilityPoolContractInterface;
  declare functions: {
    add_asset: InvokeFunction<[trove_manager_contract: ContractIdInput, asset_contract: AssetIdInput, oracle_contract: ContractIdInput], void>;
    get_asset: InvokeFunction<[asset_contract: AssetIdInput], BN>;
    get_compounded_usdf_deposit: InvokeFunction<[depositor: IdentityInput], BN>;
    get_depositor_asset_gain: InvokeFunction<[depositor: IdentityInput, asset_contract: AssetIdInput], BN>;
    get_depositor_fpt_gain: InvokeFunction<[depositor: IdentityInput], BN>;
    get_total_usdf_deposits: InvokeFunction<[], BN>;
    initialize: InvokeFunction<[usdf_contract: ContractIdInput, community_issuance_contract: ContractIdInput, protocol_manager: ContractIdInput, active_pool_contract: ContractIdInput, sorted_troves_contract: ContractIdInput], void>;
    offset: InvokeFunction<[debt_to_offset: BigNumberish, coll_to_offset: BigNumberish, asset_contract: AssetIdInput], void>;
    provide_to_stability_pool: InvokeFunction<[], void>;
    withdraw_from_stability_pool: InvokeFunction<[amount: BigNumberish], void>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}

import { Box, Spacer } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Footer } from "./Footer";
import { TitleBar } from "./TitleBar";
import FluidBg from "../../images/fluidbg.png";

export const PageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      textAlign="center"
      fontSize="xl"
      minH={"100vh"}
      justifyContent={"space-between"}
      backgroundImage={FluidBg}
      backgroundSize={"cover"}
      backgroundPosition={["left", "right"]}
      display={"flex"}
      flexDirection={"column"}
      pb={[0, 8]}
      pt={[0, 2]}
      px={[2, 4, 4, 12]}
    >
      <TitleBar />
      {children}
      <Spacer />
      <Footer />
    </Box>
  );
};

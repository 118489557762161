import {
  Text,
  VStack,
  Image,
  Button,
  HStack,
  Card,
  CardBody,
  Container,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { useAccount } from "@fuels/react";
import { useEffect, useState } from "react";
import { Address, Bech32Address, BN } from "fuels";
import { parseBN, PRECISION } from "../../shared/format";
import AnnotatedProgress from "../common/RatioBar";
import { AdjustCollateralModal } from "./AdjustCollateralModal";
import { IAssetContext, useFluid } from "../../hooks/FluidProvider";
import { AdjustDebtModal } from "./AdjustDebtModal";
import { getTroveCollateralRatio, TrovePosition } from "../../shared/utils";
import { useBorrowOperations } from "../../hooks/BorrowOperationsHook";
import { IdentityInput } from "../../types/token-contract/TokenContract";
export interface TroveCardProps {
  assetContext: IAssetContext;
  index: number;
}

const defaultTrovePosition: TrovePosition = {
  debt: new BN(0),
  coll: new BN(0),
  debt_reward: new BN(0),
  coll_reward: new BN(0),
  owner: "",
};

export const AdjustTroveCard = ({ assetContext, index }: TroveCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { closeTrove, isLoading } = useBorrowOperations();
  const {
    isOpen: isDebtOpen,
    onOpen: onDebtOpen,
    onClose: onDebtClose,
  } = useDisclosure();
  const { account } = useAccount();
  const { usdfBalance } = useFluid();
  const [trove, setTrove] = useState<TrovePosition>(defaultTrovePosition);

  const [troveRatio, setTroveRatio] = useState<number>(170);

  useEffect(() => {
    if (
      !trove.coll.isZero() &&
      !assetContext.price.isZero() &&
      !trove.debt.isZero()
    ) {
      let ratio = getTroveCollateralRatio(trove, assetContext.price);

      setTroveRatio(ratio.toNumber());
    }

    if (trove.debt.isZero() || assetContext.price.isZero()) {
      return;
    }
    let ratio = trove.coll
      .mul(100)
      .mul(assetContext.price)
      .div(trove.debt)
      .div(PRECISION);

    setTroveRatio(ratio.toNumber());
  }, [trove, assetContext.price]);

  useEffect(() => {
    if (account) {
      get_trove();
    } else {
      setTrove(defaultTrovePosition);
      setTroveRatio(200);
    }
  }, [account, assetContext.troveManagerContract]);

  async function get_trove() {
    if (assetContext.troveManagerContract && account) {
      let myB256Address = Address.fromString(account).toB256();

      let myAddress = {
        bits: myB256Address,
      };
      const identityInput: IdentityInput = {
        Address: myAddress,
      };

      assetContext.troveManagerContract.functions
        .get_entire_debt_and_coll(identityInput)
        .txParams({ tip: 1, gasLimit: 2000000 })
        .get()
        .then((res) => {
          let tempTrove = {
            debt: res.value[0],
            coll: res.value[1],
            debt_reward: res.value[2],
            coll_reward: res.value[3],
            owner: account as Bech32Address,
          };
          setTrove(tempTrove);
        });
    }
  }

  return (
    <>
      {!trove.coll.isZero() && (
        <>
          <Card
            variant={"darkCard"}
            w="100%"
            minW={["100%", "100%", "100%", "450px"]}
            h={"100%"}
            border={"2px solid transparent"}
            transition={"all 0.3s ease"}
            _hover={{
              border: `2px solid ${assetContext.troveColor};`,
              boxShadow: `${assetContext.troveBoxColor} 0px 0px 50px;`,
              transition:
                "box-shadow 0.25s ease-out 0s, border 0.25s ease-out 0s;",
            }}
          >
            <CardBody h={"100%"} w="100%" alignItems={"center"}>
              <VStack h={"100%"} w={"100%"} gap={2}>
                <Text variant={"cardTitle"} display={"flex"} flexDir={"row"}>
                  <Image
                    borderRadius="full"
                    alignSelf={"center"}
                    src={assetContext.contractIds.imageUrl}
                    alt="asset logo"
                    mr={2}
                    height={[6, 6, 6]}
                  />{" "}
                  {assetContext.symbol} Trove
                </Text>

                <Container
                  backgroundColor={"bgLightGrey"}
                  py={4}
                  h={"100%"}
                  borderRadius={10}
                  pb={2}
                >
                  <VStack
                    h={"100%"}
                    justifyContent={"space-between"}
                    w={"100%"}
                    gap="2"
                  >
                    <AnnotatedProgress
                      value={troveRatio}
                      topPosition={"105px"}
                    />

                    <HStack
                      w={"100%"}
                      justifyContent="space-between"
                      textAlign={"left"}
                    >
                      <Button
                        alignSelf={"flex-start"}
                        mt={4}
                        borderRadius={10}
                        fontSize="lg"
                        backgroundColor={"bgDarkGrey"}
                        paddingLeft={"10px !important"}
                        textAlign={"left"}
                        w={"100%"}
                        justifyContent="space-between"
                        fontWeight={"light"}
                        onClick={onOpen}
                      >
                        <HStack>
                          <Text>
                            {parseBN(trove.coll, 9, 3)}&nbsp;
                            {assetContext.symbol}
                          </Text>
                          <Text
                            alignSelf={"flex-end"}
                            fontSize={"sm"}
                            color={"textSecondary"}
                            ml={"1 !important"}
                          >
                            (${parseBN(trove.coll.mul(assetContext.price), 18)})
                          </Text>
                        </HStack>
                        <Text>Adjust Collateral</Text>
                      </Button>
                    </HStack>
                    <HStack
                      w={"100%"}
                      justifyContent="space-between"
                      textAlign={"left"}
                    >
                      <Button
                        alignSelf={"flex-start"}
                        borderRadius={10}
                        fontSize="lg"
                        backgroundColor={"bgDarkGrey"}
                        paddingLeft={"10px !important"}
                        textAlign={"left"}
                        w={"100%"}
                        justifyContent="space-between"
                        fontWeight={"light"}
                        onClick={onDebtOpen}
                      >
                        <Text>{parseBN(trove.debt, 9)}&nbsp;USDF</Text>
                        <Text>Adjust Debt</Text>
                      </Button>
                    </HStack>
                    <HStack
                      w={"100%"}
                      justifyContent="space-between"
                      textAlign={"left"}
                    >
                      <Tooltip
                        bg="textSecondary"
                        color="black"
                        hasArrow
                        label={
                          usdfBalance.lte(trove.debt)
                            ? "Insufficient USDF to close trove, buy additional USDF to close the trove"
                            : ""
                        }
                      >
                        <Button
                          alignSelf={"flex-start"}
                          borderRadius={10}
                          fontSize="lg"
                          mb={2}
                          isLoading={isLoading}
                          backgroundColor={"bgDarkGrey"}
                          paddingLeft={"10px !important"}
                          w={"100%"}
                          isDisabled={usdfBalance.lte(trove.debt)}
                          justifyContent="space-between"
                          fontWeight={"light"}
                          onClick={() => closeTrove(assetContext, trove, index)}
                        >
                          <Text>
                            {parseBN(usdfBalance, 9)}&nbsp;USDF in wallet
                          </Text>

                          <Text>Close Trove</Text>
                        </Button>
                      </Tooltip>
                    </HStack>
                  </VStack>
                </Container>
              </VStack>
            </CardBody>
          </Card>
          <AdjustCollateralModal
            isOpen={isOpen}
            onClose={onClose}
            trove={trove}
            assetContext={assetContext}
            index={index}
          />
          <AdjustDebtModal
            isOpen={isDebtOpen}
            onClose={onDebtClose}
            trove={trove}
            assetContext={assetContext}
            index={index}
          />
        </>
      )}
    </>
  );
};

import { TextProps } from "@chakra-ui/react";

type TextStylesVariants = {
  variants: { [key: string]: TextProps };
};

export const TextStyles: TextStylesVariants = {
  variants: {
    cardTitle: {
      fontWeight: "semibold",
      alignSelf: "start",
      fontFamily: "IBM Plex Mono",
      fontStyle: "bold",
    },
  },
};

// fontWeight={"semibold"}
// alignSelf={"start"}
// fontFamily={"IBM Plex Mono"}
// fontStyle={"bold"}

import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  UnorderedList,
  ListItem,
  Link,
  OrderedList,
} from "@chakra-ui/react";
import { PageWrapper } from "../components/common/PageWrapper";

const PrivacyPolicy = () => {
  return (
    <PageWrapper>
      <Box
        maxW="70%"
        mx="auto"
        fontSize={{ base: "sm", md: "lg" }}
        textAlign="left"
      >
        <Text as="h4" mb={8}>
          <Text as="strong" display="block" mb={4}>
            Introduction
          </Text>
          <p>
            This Privacy Notice (the “Privacy Notice”) explains how the personal
            data of individuals are handled – the “Data Subjects” or the “Data
            Subject”, or “you”, “your”, in connection with accessing and using
            the website, the Interface and any services available at Fluid
            (together referred to as the “Services”).
          </p>
          <br />
          <p>
            If you are interested in how cookies are used and how to change your
            cookies choice, please go to section “Cookies and Automatically
            Collected Data”
          </p>
          <br />
          <p>
            Categories of Personal Data Collected, Purposes of and Bases for the
            Processing When providing the Services, certain personal data can be
            processed for the following purposes:
          </p>
          <br />
          <p>
            When providing the Services, certain personal data can be processed
            for the following purposes:
          </p>
        </Text>

        <Table variant="simple" mb={8} borderColor="white">
          <Thead>
            <Tr>
              <Th borderColor="white" textAlign="left">
                Purpose of Processing
              </Th>
              <Th borderColor="white" textAlign="left">
                Personal Data
              </Th>
              <Th borderColor="white" textAlign="left">
                Legal Ground (basis)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td borderColor="white" textAlign="left">
                Communicating with you regarding your inquiries, questions or
                support tickets
              </Td>
              <Td borderColor="white" textAlign="left">
                Email address, subject of inquiry and its content, attachments
                and any other information you voluntarily provide
              </Td>
              <Td borderColor="white" textAlign="left">
                Legitimate interests / contractual obligations
              </Td>
            </Tr>
            <Tr>
              <Td borderColor="white" textAlign="left">
                Sending email newsletters
              </Td>
              <Td borderColor="white" textAlign="left">
                Email address
              </Td>
              <Td borderColor="white" textAlign="left">
                Your consent
              </Td>
            </Tr>
            <Tr>
              <Td borderColor="white" textAlign="left">
                Provides access to users of the website to a decentralized
                protocol
              </Td>
              <Td borderColor="white" textAlign="left">
                Wallet addresses, transaction and balance information
              </Td>
              <Td borderColor="white" textAlign="left">
                Contractual obligations (terms of use)
              </Td>
            </Tr>
            <Tr>
              <Td borderColor="white" textAlign="left">
                Analyzing website visitors' actions to improve Services
              </Td>
              <Td borderColor="white" textAlign="left">
                See section "Cookies and Automatically Collected Data"
              </Td>
              <Td borderColor="white" textAlign="left">
                Your consent
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <Text>
          Your personal data are collected directly from you or from other
          parties you have authorized such collection. There is no process for
          special categories of personal data about you unless you voluntarily
          provide such data.
          <br />
          <br />
        </Text>

        <Text mb={8}>
          <Text as="strong" display="block" mb={4}>
            Cookies and Automatically Collected Data
          </Text>
          As you navigate through and interact with website and the Services,
          you may be asked to consent to use cookies, which are small files
          placed on the hard drive of your computer or mobile device, and web
          beacons, which are small electronic files located on pages of the
          website, to collect certain information about your equipment, browsing
          actions, and patterns.
          <br />
          <br />
          The data is automatically collected from cookies and web beacons may
          include information from your web browser (such as browser type and
          browser language) and details of your visits to website, including
          traffic data, location data and logs, page views, length of visit and
          website navigation paths as well as information about your device and
          internet connection, including your IP address and how you interact
          with the Services. These data are collected this data in order to
          improve website and Services.
          <br />
          <br />
          The information is collected automatically and may also include
          statistical and performance information arising from your use of
          Services and website. This type of data will only be used in an
          aggregated and anonymized manner.
        </Text>

        <Text mb={4}>
          You can disable/delete the cookies set by website — please find the
          appropriate instructions by following these links:
        </Text>
        <UnorderedList mb={8} spacing={2}>
          <ListItem>
            For Google Chrome browser please refer{" "}
            <Link
              href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en"
              color="blue.400"
              isExternal
            >
              here
            </Link>
          </ListItem>
          <ListItem>
            For Firefox browser please refer
            [here](https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox)
          </ListItem>
          <ListItem>
            For Safari browser please refer
            [here](https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac)
          </ListItem>
          <ListItem>
            For Internet Explorer browser please refer
            [here](https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies)
          </ListItem>
        </UnorderedList>

        <Text>
          If you are a resident of the European Economic Area and you are under
          the age of 18, please do not submit any personal data through the
          Services and the website.
          <br />
          <br />
        </Text>

        <Text mb={8}>
          <Text as="strong" display="block" mb={4}>
            Your Rights With Regard to the Personal Data Processing
          </Text>
          In connection with the accessing, browsing of the website and using
          the Services, you shall be entitled to exercise certain rights laid
          down by the GDPR and outlined herein below, however exercise of some
          of those rights may not be possible in relation to the website and
          Services taking account of the Services’ nature, manner, form and
          other applicable circumstances. Right to Access: you may request all
          personal data via e-mail.
          <br />
          <br />
          Right to Rectification: exercise of the given right directly depends
          on the data category concerned: if it concerns online identifiers,
          then their rectification isn’t possible, but such categories of
          personal data as email address may be rectified by sending a request
          via e-mail.
          <br />
          <br />
          Right to Erasure (Right to be Forgotten): the personal data could be
          deleted by a request via e-mail.
          <br />
          <br />
          Consent Withdrawal Right: you shall be entitled to withdraw consent to
          the processing of the personal data to which you provided your
          consent. In particular, you can change your cookie choices by using
          cookie consent tool built in the website. You can exercise your right
          to withdraw consent by unsubscribing from email newsletter.
          <br />
          <br />
          Automated Decision-Making, Profiling: neither is being carried out,
          your consent will be sought before carrying out any such activities.
          <br />
          <br />
          You shall have the right to lodge a complaint with a competent data
          protection supervisory authority.
        </Text>

        <Text mb={8}>
          <Text as="strong" display="block" mb={4}>
            Personal Data Storage Period or Criteria for Such Storage
          </Text>
          Your Personal data will be stored till:
          <UnorderedList mt={4} spacing={2}>
            <ListItem>they are necessary to render you the Services;</ListItem>
            <ListItem>your consent is no longer valid;</ListItem>
            <ListItem>
              your personal data have been deleted following your data deletion
              request;
            </ListItem>
            <ListItem>
              in other circumstances prescribed by applicable laws.
            </ListItem>
          </UnorderedList>
          <br />
          In any event, your personal data will not being stored for periods
          longer than it is necessary for the purposes of the processing.
        </Text>

        <Text mb={8}>
          <Text as="strong" display="block" mb={4}>
            Personal Data Recipients and Transfer of Personal Data
          </Text>
          For the purposes of rendering the Services to you and operating the
          website, your personal data may be shared with certain categories of
          recipients and under circumstances mentioned below:
          <OrderedList mt={4} spacing={2}>
            <ListItem>
              providers, consultants, advisors, vendors and partners acting as
              data processors (meaning they process your personal data on behalf
              and according to your instructions), which may supply hosting
              services, web analytics services, email marketing and automation
              services to run and operate the website, maintain, deliver and
              improve the Services. With all such parties we enter into data
              processing agreements required to be concluded by the applicable
              laws between controllers and processors to protect and secure the
              personal data by using appropriate technical and organizational
              measures;
            </ListItem>
            <ListItem>
              only in strict compliance with the applicable provisions, the
              personal data may be shared with governmental authorities upon
              their decision, receipt of court orders mandating to disclose the
              personal data. In any such case, it will be strived to disclose
              only a portion of the personal data which is definitely required
              to be disclosed, while continuing to be treated the rest of the
              data in confidence;
            </ListItem>
            <ListItem>
              with any other third parties, if it has been explicitly requested
              been explicitly requested to do so by you and as long as it
              doesn’t infringe the applicable laws. Keep in mind that the use of
              services based on public blockchains intended to immutably record
              transactions across wide networks of computer systems. Many
              blockchains are open to forensic analysis which can lead to
              deanonymization and the unintentional revelation of personal data,
              in particular when blockchain data is combined with other data.
              Because blockchains are decentralized or third-party networks
              which are not controlled or operated, they can’t erase, modify, or
              alter personal data from such networks.
            </ListItem>
          </OrderedList>
        </Text>
        <Text mb={8}>
          <Text as="strong" display="block" mb={4}>
            Security of Processing
          </Text>
          Your personal data is protected from loss, misuse, or unauthorized
          access. It is utilized to protect the personal data, both during
          transmission and once it is received. Contacts and Requests; Changes
          to the Privacy Notice Please send all your requests and queries in
          connection with your rights and freedoms relating to the personal data
          protection and processing as part of providing the website and
          rendering the Services to you to: support@hydrogenlabs.xyz Changes to
          the Privacy Notice are represented by the “Page last updated:” date
          and all the versions of the Privacy Notice are archived.
        </Text>

        <Text mb={8}>
          <Text as="strong" display="block" mb={4}>
            Contacts and Requests; Changes to the Privacy Notice
          </Text>
          Please send all your requests and queries in connection with your
          lefts and freedoms relating to the personal data protection and
          processing as part of providing the website and rendering the Services
          to you to: support@hydrogenlabs.xyz
          <br />
          <br />
          Changes to the Privacy Notice are represented by the “Page last
          updated:” date and all the versions of the Privacy Notice are
          archived.
        </Text>
      </Box>
    </PageWrapper>
  );
};

export { PrivacyPolicy };

import {
  Text,
  VStack,
  Image,
  Button,
  HStack,
  Card,
  CardBody,
  Container,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Spinner,
  Link,
  ButtonGroup,
} from "@chakra-ui/react";
import { useWallet } from "../../hooks/useWallet";
import { useEffect, useState } from "react";
import { BN } from "fuels";
import { parseBN, PRECISION } from "../../shared/format";
import AnnotatedProgress from "../common/RatioBar";
import { TroveStats } from "./TroveStats";
import { ConnectWalletButton } from "../common/ConnectWalletButton";
import {
  defaultAssetContext,
  IAssetContext,
  useFluid,
} from "../../hooks/FluidProvider";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FaInfoCircle } from "react-icons/fa";
import { useBorrowOperations } from "../../hooks/BorrowOperationsHook";
import {
  MIN_COLLATERAL_RATIO_DECIMAL,
  MIN_COLLATERAL_RATIO,
  MIN_DEBT,
} from "../../shared/constants";
import { ETH_CONFIG } from "../../shared/asset-configs";
import { useLocation } from "react-router-dom";

export const OpenTroveCard = () => {
  const { account } = useWallet();
  const { isLoading, openTrove } = useBorrowOperations();
  const { assets, assetsLoaded } = useFluid();
  const [index, setIndex] = useState<number>(0);
  const [currentAsset, setCurrentAsset] =
    useState<IAssetContext>(defaultAssetContext);
  const [usdfFee, setUsdfFee] = useState<BN>(new BN("0"));
  const [collateralInput, setCollateralInput] = useState<BN | undefined>(
    undefined
  );
  const [collateralInputText, setCollateralInputText] = useState<string>("");
  const [debtInput, setDebtInput] = useState<BN>(new BN("0"));
  const [debtInputText, setDebtInputText] = useState<string>("");
  const [troveRatio, setTroveRatio] = useState<number | undefined>(undefined);
  const [hasLoadedData, setHasLoadedData] = useState<boolean>(false);
  const isTroveOpen = () => !currentAsset.trove.coll.isZero();
  const location = useLocation();

  useEffect(() => {
    if (assetsLoaded && account) {
      setHasLoadedData(true);
    } else {
      setHasLoadedData(false);
    }
  }, [assetsLoaded, account]);

  useEffect(() => {
    if (assets.length > 0) {
      setCurrentAsset(assets[index]);
      setDebtInput(new BN("0"));
      setCollateralInput(undefined);
      setCollateralInputText("");
      setDebtInputText("");
    }
  }, [assets, index]);

  useEffect(() => {
    if (
      collateralInput &&
      debtInput &&
      !debtInput.isZero() &&
      !currentAsset.price.isZero()
    ) {
      let ratio = collateralInput
        .mul(100)
        .mul(currentAsset.price)
        .div(debtInput)
        .div(PRECISION);

      setTroveRatio(ratio.toNumber());
    } else {
      setTroveRatio(0);
    }

    if (currentAsset.trove.debt.isZero() || currentAsset.price.isZero()) {
      return;
    }
    let ratio = !currentAsset.trove.debt.isZero()
      ? currentAsset.trove.coll
          .mul(100)
          .mul(currentAsset.price)
          .div(currentAsset.trove.debt.add(usdfFee))
          .div(PRECISION)
      : new BN(0);

    setTroveRatio(ratio.toNumber());
  }, [
    currentAsset.trove,
    currentAsset.price,
    collateralInput,
    debtInput,
    usdfFee,
  ]);

  useEffect(() => {
    if (isValidDebtInput()) {
      getUSDFFee();
    }
  }, [debtInput, isValidDebtInput, getUSDFFee]);

  useEffect(() => {
    if (location.state?.selectedAssetSymbol && assets.length > 0) {
      const selectedIndex = assets.findIndex(
        (asset) => asset.symbol === location.state.selectedAssetSymbol
      );
      if (selectedIndex !== -1) {
        setIndex(selectedIndex);
      }
    } else if (account) {
      let firstAssetNoTrove = assets.findIndex((asset) =>
        asset.trove.coll.isZero()
      );

      if (firstAssetNoTrove === -1) {
        firstAssetNoTrove = 0;
      }

      setIndex(firstAssetNoTrove);
    }
  }, [account, assets, location.state]);

  function canOpenTrove() {
    return (
      account !== null &&
      account !== "" &&
      isValidDebtInput() &&
      isValidCollateralInput() &&
      debtGreaterThanMin()
    );
  }

  function isValidDebtInput() {
    return (
      debtInput &&
      troveRatio &&
      debtInput.gt(new BN(0)) &&
      debtGreaterThanMin() &&
      troveRatio > MIN_COLLATERAL_RATIO_DECIMAL
    );
  }

  function isValidCollateralInput() {
    return (
      collateralInput &&
      collateralInput.lte(currentAsset.balance) &&
      collateralInput.gt(new BN(0))
    );
  }

  function getUSDFFee() {
    if (currentAsset.troveManagerContract && account && debtInput) {
      setUsdfFee(debtInput.mul(new BN(5)).div(new BN(1000)));
    }
  }

  function debtGreaterThanMin() {
    return debtInput ? debtInput.gte(new BN(MIN_DEBT)) : false;
  }

  const calculateMaxBorrowable = () => {
    if (collateralInput && currentAsset.price) {
      const collateralValue = collateralInput
        .mul(currentAsset.price)
        .div(PRECISION);
      return collateralValue.mul(PRECISION).div(MIN_COLLATERAL_RATIO);
    }
    return new BN(0);
  };

  const getTooltipLabel = (percentage: number) => {
    const maxBorrowable = calculateMaxBorrowable();
    const amount = maxBorrowable.mul(new BN(percentage)).div(new BN(100));
    return `${percentage}% of max borrowable: ${parseBN(amount, 9)} USDF`;
  };

  const setDebtSuggestion = (percentage: number) => {
    const maxBorrowable = calculateMaxBorrowable();
    const suggestedDebt = maxBorrowable
      .mul(new BN(percentage))
      .div(new BN(100));
    const finalDebt = BN.max(suggestedDebt, new BN(MIN_DEBT));
    setDebtInput(finalDebt);
    setDebtInputText(parseBN(finalDebt, 9));
  };

  const isButtonDisabled = (percentage: number) => {
    if (!collateralInput || collateralInput.isZero()) return true;
    const maxBorrowable = calculateMaxBorrowable();
    if (percentage === 0) {
      return maxBorrowable.lt(new BN(MIN_DEBT));
    }
    const suggestedDebt = maxBorrowable
      .mul(new BN(percentage))
      .div(new BN(100));
    return suggestedDebt.lt(new BN(MIN_DEBT));
  };

  const handleDebtInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    setDebtInputText(inputValue);

    // Remove commas and validate the number
    const sanitizedValue = inputValue.replace(/,/g, "");
    const isValidNumber = /^-?\d*(\.\d+)?$/.test(sanitizedValue);

    if (isValidNumber) {
      const parsedValue = parseFloat(sanitizedValue);
      setDebtInput(new BN(parsedValue * PRECISION));
    } else {
      setDebtInput(new BN(0));
    }
  };

  return (
    <>
      <Card variant={"darkCard"}>
        <CardBody w="100%" alignItems={"center"}>
          <VStack w={"100%"} gap={2}>
            <HStack w="100%" justifyContent="space-between">
              <Text variant={"cardTitle"}>Borrow USDF</Text>
              <Link
                style={{
                  marginTop: "-10px",
                  alignSelf: "end",
                  fontSize: "16px",
                  display: "flex",
                  lineHeight: "16px",
                  fontWeight: "100",
                }}
                href="https://docs.hydrogenlabs.xyz/fluid-protocol-community/protocol-design/borrowing"
                isExternal
              >
                <Text>Learn more</Text>
                <FaInfoCircle style={{ marginLeft: "5px" }} />
              </Link>
            </HStack>
            <Container
              backgroundColor={"bgLightGrey"}
              py={4}
              borderRadius={10}
              pb={2}
              minWidth={["min-content", "400px"]}
            >
              {assets.length > 0 && account ? (
                <VStack w={"100%"} gap="4">
                  {isTroveOpen() ? (
                    <Text
                      style={{ maxWidth: "400px" }}
                      fontFamily={"IBM Plex Mono"}
                      fontWeight={"bold"}
                    >
                      You already have a trove open for {assets[index].symbol}.
                      Please select another collateral type, or go to My Troves
                      to manage your {assets[index].symbol} trove.{" "}
                    </Text>
                  ) : (
                    <AnnotatedProgress
                      value={troveRatio}
                      topPosition={"115px"}
                    />
                  )}
                  <VStack w={"100%"} gap="1" mt={2}>
                    <HStack
                      w={"100%"}
                      justifyContent="space-between"
                      backgroundColor={"bgDarkGrey"}
                      borderRadius={10}
                    >
                      <Menu>
                        <MenuButton
                          alignSelf={"flex-start"}
                          borderRadius={"full"}
                          fontSize="xl"
                          backgroundColor={"bgDarkGrey"}
                          paddingLeft={"10px !important"}
                          paddingRight={"25px !important"}
                        >
                          <HStack
                            p={1}
                            pr={3}
                            w={"100%"}
                            justifyContent={"space-between"}
                          >
                            <HStack minW="100px">
                              <Image
                                borderRadius="full"
                                alignSelf={"center"}
                                src={
                                  assets[index]
                                    ? assets[index].contractIds.imageUrl
                                    : ETH_CONFIG.imageUrl
                                }
                                alt="Token Icon"
                                height={[6, 6, 6]}
                              />{" "}
                              <Text w="70px">
                                {assets[index] ? assets[index].symbol : "fETH"}
                              </Text>
                              <ChevronDownIcon />
                            </HStack>
                          </HStack>
                        </MenuButton>
                        <MenuList bg={"bgDarkGrey"} maxWidth={"fit-content"}>
                          {assets.map((asset, itemInd) => {
                            if (
                              index === itemInd ||
                              asset.contractIds.disabled
                            ) {
                              return "";
                            }
                            return (
                              <MenuItem
                                bg={"bgDarkGrey"}
                                key={asset.contractIds.assetId + "menuitem"}
                                onClick={() => {
                                  setIndex(itemInd);
                                }}
                                isDisabled={!asset.trove.coll.isZero()}
                                _hover={{ bg: "bgLightGrey" }}
                              >
                                <Tooltip
                                  bg="textSecondary"
                                  color="black"
                                  hasArrow
                                  label={
                                    !asset.trove.coll.isZero()
                                      ? "Go to the My Troves page to adjust your position"
                                      : ""
                                  }
                                >
                                  <HStack>
                                    <Image
                                      borderRadius="full"
                                      alignSelf={"center"}
                                      src={asset.contractIds.imageUrl}
                                      alt="Token Icon"
                                      height={[6, 6, 6]}
                                    />{" "}
                                    <Text>
                                      &nbsp; {asset.contractIds.assetSymbol}
                                    </Text>
                                  </HStack>
                                </Tooltip>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </Menu>
                      <InputGroup size="md">
                        <Input
                          backgroundColor={"bgDarkGrey"}
                          textAlign={"right"}
                          placeholder="2.5 ETH"
                          fontSize={"xl"}
                          border={"none"}
                          style={{ border: "none" }}
                          focusBorderColor="transparent"
                          isDisabled={account === null || isTroveOpen()}
                          isInvalid={
                            !isValidCollateralInput() &&
                            collateralInputText !== ""
                          }
                          borderRadius={"full"}
                          value={collateralInputText}
                          onChange={(e) => {
                            const inputValue = e.target.value.trim();
                            if (
                              inputValue.includes(".") &&
                              inputValue.split(".")[1]?.length > 9
                            ) {
                              return;
                            }
                            setCollateralInputText(inputValue);

                            const isValidNumber = /^-?\d*(\.\d{0,9})?$/.test(
                              inputValue
                            );
                            if (isValidNumber) {
                              const parsedValue = parseFloat(inputValue);
                              setCollateralInput(
                                new BN(parsedValue * PRECISION)
                              );
                            }
                          }}
                        ></Input>
                      </InputGroup>
                      <Button
                        h="1.75rem"
                        size="xs"
                        borderRadius={4}
                        px={4}
                        mr={2}
                        isDisabled={account === null || isTroveOpen()}
                        onClick={() => {
                          setCollateralInput(currentAsset.balance);
                          setCollateralInputText(
                            parseBN(currentAsset.balance, 9, 4)
                          );
                        }}
                      >
                        MAX
                      </Button>
                    </HStack>
                    <HStack w={"100%"} justifyContent="space-between">
                      <Text
                        ml={2}
                        alignSelf={"end"}
                        mt={"0 !important"}
                        fontSize={"sm"}
                        color={"textSecondary"}
                      >
                        {collateralInput
                          ? `$${parseBN(
                              collateralInput.mul(currentAsset.price),
                              18
                            )}`
                          : `$${parseBN(currentAsset.price, 9)}`}
                      </Text>

                      <Text
                        pr={2}
                        alignSelf={"start"}
                        mt={"0 !important"}
                        fontSize={"sm"}
                        color={"textSecondary"}
                      >
                        Balance: {parseBN(currentAsset.balance, 9, 4)}
                      </Text>
                    </HStack>
                  </VStack>

                  <HStack
                    w={"100%"}
                    justifyContent="space-between"
                    backgroundColor={"bgDarkGrey"}
                    borderRadius={10}
                  >
                    <Container
                      alignSelf={"flex-start"}
                      paddingLeft={"10px !important"}
                      flexDir={"row"}
                      display={"flex"}
                      placeSelf={"center"}
                    >
                      <Image
                        borderRadius="full"
                        alignSelf={"center"}
                        src={"https://i.imgur.com/qI2cyjF.jpg"}
                        alt="Green double couch with wooden legs"
                        height={[6, 6, 7]}
                      />{" "}
                      <Text fontSize="xl" fontWeight={"medium"}>
                        {" "}
                        &nbsp;USDF
                      </Text>
                    </Container>
                    <VStack maxW={"50%"} gap={0}>
                      <Input
                        backgroundColor={"bgDarkGrey"}
                        textAlign={"right"}
                        placeholder={`Min ${parseBN(new BN(MIN_DEBT), 9)}`}
                        fontSize={"xl"}
                        border={"none"}
                        style={{ border: "none" }}
                        focusBorderColor="transparent"
                        isInvalid={!isValidDebtInput() && debtInputText !== ""}
                        isDisabled={account === null || isTroveOpen()}
                        maxW={"100%"}
                        value={debtInputText}
                        onChange={handleDebtInputChange}
                      ></Input>
                    </VStack>
                  </HStack>
                  <HStack justifyContent="space-between" w="100%">
                    <ButtonGroup
                      w="100%"
                      spacing={0}
                      isAttached
                      variant="outline"
                    >
                      <Tooltip
                        label={`Minimum borrowable amount: ${parseBN(
                          new BN(MIN_DEBT),
                          9
                        )} USDF`}
                        hasArrow
                      >
                        <Button
                          size="sm"
                          w="25%"
                          onClick={() => {
                            setDebtInput(new BN(MIN_DEBT));
                            setDebtInputText(parseBN(new BN(MIN_DEBT), 9));
                          }}
                          isDisabled={isButtonDisabled(0)}
                        >
                          Min
                        </Button>
                      </Tooltip>
                      <Tooltip label={getTooltipLabel(25)} hasArrow>
                        <Button
                          size="sm"
                          w="25%"
                          onClick={() => setDebtSuggestion(25)}
                          isDisabled={isButtonDisabled(25)}
                        >
                          25%
                        </Button>
                      </Tooltip>
                      <Tooltip label={getTooltipLabel(50)} hasArrow>
                        <Button
                          size="sm"
                          w="25%"
                          onClick={() => setDebtSuggestion(50)}
                          isDisabled={isButtonDisabled(50)}
                        >
                          50%
                        </Button>
                      </Tooltip>
                      <Tooltip label={getTooltipLabel(75)} hasArrow>
                        <Button
                          size="sm"
                          w="25%"
                          onClick={() => setDebtSuggestion(75)}
                          isDisabled={isButtonDisabled(75)}
                        >
                          75%
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </HStack>

                  <TroveStats
                    borrowedUsdf={debtInput}
                    price={currentAsset.price}
                    collRatio={troveRatio ? troveRatio : 0}
                    usdfFee={usdfFee}
                    validUserInput={
                      isValidDebtInput() === true &&
                      isValidCollateralInput() === true
                    }
                    validCollateralInput={
                      isValidCollateralInput() === true &&
                      debtInput.isZero() === false
                    }
                  />
                </VStack>
              ) : account ? (
                <>
                  <Text style={{ marginBottom: "10px", minWidth: "400px" }}>
                    Loading trove data...
                  </Text>
                  <Spinner size="lg" />
                </>
              ) : (
                <>
                  <Text style={{ marginTop: "10px", marginBottom: "20px" }}>
                    Please connect your wallet to open a trove.
                  </Text>
                  <ConnectWalletButton />
                </>
              )}
            </Container>

            {hasLoadedData && account ? (
              <Button
                borderRadius={"10"}
                w="100%"
                colorScheme={"green"}
                isLoading={isLoading}
                isDisabled={!canOpenTrove() || isTroveOpen()}
                onClick={() => {
                  collateralInput &&
                    openTrove(collateralInput, debtInput, index);
                }}
              >
                {isTroveOpen() ? "Trove Already Open" : "Open Trove"}
              </Button>
            ) : null}
          </VStack>
        </CardBody>
      </Card>
    </>
  );
};

import React from "react";
import { MenuItem, HStack, Image, VStack, Text } from "@chakra-ui/react";
import {
  formatBNtoHumanReadable,
  parseBN,
  PRECISION,
} from "../../shared/format";
import { BN } from "fuels";

interface AssetMenuItemProps {
  symbol: string;
  balance: BN;
  imageUrl: string;
  price: BN;
  decimals?: number;
}

export const AssetMenuItem: React.FC<AssetMenuItemProps> = ({
  symbol,
  balance,
  imageUrl,
  price,
  decimals = 4,
}) => {
  return (
    <MenuItem
      backgroundColor={"bgLightGrey"}
      _hover={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      }}
      justifyContent={"space-between"}
    >
      <HStack spacing={3}>
        <Image borderRadius="full" src={imageUrl} alt={symbol} boxSize="24px" />
        <VStack alignItems="flex-start" spacing={0}>
          <Text fontSize="sm" fontWeight="bold">
            {symbol}
          </Text>
          <Text fontSize="xs" fontWeight={"bold"}>
            {parseBN(balance, 9, decimals)}
          </Text>
        </VStack>
      </HStack>
      {price.gt(0) && (
        <Text fontSize="sm" fontWeight="bold" color={"textSecondary"}>
          ${formatBNtoHumanReadable(balance.mul(price).div(PRECISION), 9, 3)}
        </Text>
      )}
    </MenuItem>
  );
};

import {
  Text,
  VStack,
  Card,
  CardBody,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Center,
  Spinner,
  TableContainer,
  useClipboard,
  useToast,
  HStack,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BN } from "fuels";
import { IAssetContext, useFluid } from "../../hooks/FluidProvider";
import { parseBN } from "../../shared/format";
import { CopyIcon } from "@chakra-ui/icons";
import { getTroveCollateralRatio, TrovePosition } from "../../shared/utils";
import { useTroveManager } from "../../hooks/TroveManagerHooks";
import { MultiTroveGetterContract } from "../../types/multi-trove-getter-contract";
import { MIN_COLLATERAL_RATIO } from "../../shared/constants";
import { SortedTrovesContract } from "../../types/sorted-troves-contract";

export interface TroveCardProps {
  iconUrl: string;
  symbol: string;
}

export const RiskyTrovesCard = () => {
  const MAX_TROVES = 10;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalTroves, setTotalTroves] = useState<number>(0);
  const { assets, wallet, contracts } = useFluid();
  const [troves, setTroves] = useState<TrovePosition[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<IAssetContext | undefined>(
    undefined
  );
  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const toast = useToast();
  const { liquidateTrove } = useTroveManager();

  useEffect(() => {
    if (value && !hasCopied) {
      onCopy();
      toast({
        title: "Address copied",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  }, [value]);

  useEffect(() => {
    if (selectedAsset === undefined && assets[0]) {
      setSelectedAsset(assets[0]);
    }
  }, [assets]);

  useEffect(() => {
    if (selectedAsset && selectedAsset.assetContract) {
      getTotalTroves();
    }
  }, [selectedAsset]);

  useEffect(() => {
    if (selectedAsset) {
      getMultipleSortedTroves();
    }
  }, [selectedAsset, currentPage]);

  async function getTotalTroves() {
    if (selectedAsset && selectedAsset.assetContract) {
      const sortedTrovesContract = new SortedTrovesContract(
        contracts.sortedTroves,
        wallet
      );

      try {
        const size = await sortedTrovesContract.functions
          .get_size({ bits: selectedAsset.assetContract.id.toB256() })
          .get();
        setTotalTroves(size.value.toNumber());
      } catch (error) {
        console.error("Error fetching total troves:", error);
      }
    }
  }

  async function getMultipleSortedTroves() {
    if (selectedAsset && selectedAsset.assetContract) {
      setIsLoading(true);
      const multiTroveGetterContract = new MultiTroveGetterContract(
        contracts.multiTroveGetter,
        wallet
      );

      try {
        const result = await multiTroveGetterContract.functions
          .get_multiple_sorted_troves(
            { bits: selectedAsset.troveManagerContract!.id.toB256() },
            { bits: selectedAsset.assetContract.id.toB256() },
            currentPage * MAX_TROVES,
            MAX_TROVES
          )
          .txParams({ tip: 1, gasLimit: 2000000 })
          .get();

        const positions: TrovePosition[] = result.value.map((trove) => ({
          owner: trove.address.Address?.bits || "",
          coll: trove.collateral,
          debt: trove.debt,
          coll_reward: trove.collateral_rewards,
          debt_reward: trove.debt_rewards,
        }));

        setTroves(positions);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching troves:", error);
        setIsLoading(false);
      }
    }
  }

  const totalPages = Math.ceil(totalTroves / MAX_TROVES);
  const canGoNext = currentPage < totalPages - 1;
  const canGoPrev = currentPage > 0;

  return (
    <Card variant={"darkCard"}>
      <CardBody w="100%" alignItems={"center"}>
        <VStack w={"100%"} gap={2}>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Text display={"flex"} flexDir={"row"} variant={"cardTitle"}>
              Risky Troves
            </Text>

            <HStack
              fontWeight={"semibold"}
              alignSelf={"start"}
              fontFamily={"IBM Plex Mono"}
              fontStyle={"bold"}
            >
              {assets
                .filter((asset) => !asset.contractIds.disabled)
                .map((asset, index, filteredAssets) => (
                  <HStack key={asset.symbol}>
                    <Text
                      textDecoration={
                        asset.symbol === selectedAsset?.symbol
                          ? "underline"
                          : "none"
                      }
                      cursor={"pointer"}
                      onClick={() => {
                        setSelectedAsset(asset);
                        setIsLoading(true);
                        setTroves([]);
                        setCurrentPage(0);
                      }}
                    >
                      {asset.symbol}
                    </Text>
                    {index < filteredAssets.length - 1 && <Text>·</Text>}
                  </HStack>
                ))}
            </HStack>
          </HStack>

          <TableContainer
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            pb={2}
            w={"100%"}
          >
            <Table w={"100%"} size="md" border={"transparent"}>
              <Thead w={"100%"}>
                <Tr w={"100%"}>
                  <Th border={"transparent"}>Owner</Th>
                  <Th border={"transparent"} isNumeric>
                    Collateral
                  </Th>
                  <Th border={"transparent"} isNumeric>
                    USDF Debt
                  </Th>
                  <Th border={"transparent"} isNumeric>
                    Coll. Ratio
                  </Th>
                </Tr>
              </Thead>

              <Tbody w={"100%"}>
                <>
                  {troves.map((trove) => {
                    return (
                      <Tooltip
                        key={trove.owner + "trove" + trove.coll + trove.debt}
                        label={
                          getTroveCollateralRatio(
                            trove,
                            selectedAsset ? selectedAsset.price : new BN(0)
                          ).lt(MIN_COLLATERAL_RATIO)
                            ? "Liquidate Trove"
                            : ""
                        }
                        hasArrow
                      >
                        <Tr
                          fontSize={"sm"}
                          bgColor={
                            getTroveCollateralRatio(
                              trove,
                              selectedAsset ? selectedAsset.price : new BN(0)
                            ).lt(MIN_COLLATERAL_RATIO)
                              ? "red.800"
                              : ""
                          }
                          cursor={
                            getTroveCollateralRatio(
                              trove,
                              selectedAsset ? selectedAsset.price : new BN(0)
                            ).lt(MIN_COLLATERAL_RATIO)
                              ? "pointer"
                              : "default"
                          }
                          // add hover highlight
                          _hover={{
                            backgroundColor: getTroveCollateralRatio(
                              trove,
                              selectedAsset ? selectedAsset.price : new BN(0)
                            ).lt(MIN_COLLATERAL_RATIO)
                              ? "red.600"
                              : "",
                          }}
                          onClick={() => {
                            if (
                              getTroveCollateralRatio(
                                trove,
                                selectedAsset ? selectedAsset.price : new BN(0)
                              ).lt(MIN_COLLATERAL_RATIO)
                            ) {
                              liquidateTrove(
                                trove.owner,
                                selectedAsset
                                  ? assets.indexOf(selectedAsset)
                                  : 0
                              );
                            }
                          }}
                        >
                          <Td border={"transparent"}>
                            {trove.owner.substring(0, 5) +
                              "..." +
                              trove.owner.substring(63, 100)}{" "}
                            &nbsp;
                            <CopyIcon
                              cursor={"pointer"}
                              onClick={() => {
                                setValue(trove.owner);
                              }}
                            />
                          </Td>
                          <Td border={"transparent"} isNumeric>
                            {parseBN(trove.coll, 9, 4)} {selectedAsset?.symbol}
                          </Td>
                          <Td border={"transparent"} isNumeric>
                            {parseBN(trove.debt, 9)}
                          </Td>
                          <Td border={"transparent"} isNumeric>
                            {parseBN(
                              getTroveCollateralRatio(
                                trove,
                                selectedAsset ? selectedAsset.price : new BN(0)
                              ),
                              7
                            )}
                            %
                          </Td>
                        </Tr>
                      </Tooltip>
                    );
                  })}
                </>
              </Tbody>
              {isLoading && (
                <Tbody w={"100%"}>
                  <Tr>
                    <Td colSpan={4}>
                      <Center>
                        <Spinner />
                      </Center>
                    </Td>
                  </Tr>
                </Tbody>
              )}
            </Table>
          </TableContainer>

          <HStack
            w="100%"
            justifyContent="space-between"
            pt={2}
            fontFamily="IBM Plex Mono"
            fontSize="md"
            fontWeight="semibold"
          >
            <Text>
              Total {selectedAsset?.symbol} Troves: {totalTroves}
            </Text>
            <HStack>
              <Text>
                Page {currentPage + 1} of {Math.max(1, totalPages)}
              </Text>
              <Button
                size="sm"
                onClick={() => setCurrentPage((p) => p - 1)}
                isDisabled={!canGoPrev}
                fontFamily="IBM Plex Mono"
              >
                Previous
              </Button>
              <Button
                size="sm"
                onClick={() => setCurrentPage((p) => p + 1)}
                isDisabled={!canGoNext}
                fontFamily="IBM Plex Mono"
              >
                Next
              </Button>
            </HStack>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

import { B256Address, Bech32Address } from "fuels";
import { AssetContracts, ProtocolContracts } from "./contracts";
import contracts from "./mainnet.contracts.json";
import { createAssetContracts } from "./utils";
import {
  ETH_CONFIG,
  RSETH_CONFIG,
  EZETH_CONFIG,
  WEETH_CONFIG,
  WSTETH_CONFIG,
  METH_CONFIG,
} from "./asset-configs";

export const ethContracts: AssetContracts = createAssetContracts(
  0,
  ETH_CONFIG,
  contracts as any
);
export const stEthContracts: AssetContracts = createAssetContracts(
  1,
  WSTETH_CONFIG,
  contracts as any
);

export const ezEthContracts: AssetContracts = createAssetContracts(
  2,
  EZETH_CONFIG,
  contracts as any
);
export const weEthContracts: AssetContracts = createAssetContracts(
  3,
  WEETH_CONFIG,
  contracts as any
);
export const rsEthContracts: AssetContracts = createAssetContracts(
  4,
  RSETH_CONFIG,
  contracts as any
);
export const methContracts: AssetContracts = createAssetContracts(
  5,
  METH_CONFIG,
  contracts as any
);

export const allCollateralContracts: AssetContracts[] = [
  ethContracts,
  stEthContracts,
  ezEthContracts,
  weEthContracts,
  rsEthContracts,
  methContracts,
];

export const Contracts: ProtocolContracts = {
  BorrowOperations: contracts.borrow_operations as Bech32Address,
  stabilityPoolImplementation:
    contracts.stability_pool_implementation_id as B256Address,
  Usdf: contracts.usdf as Bech32Address,
  UsdfImplementation: contracts.usdf_implementation_id as B256Address,
  UsdfAssetId: contracts.usdf_asset_id as string,
  fptAssetId: contracts.fpt_asset_id as string,
  stabilityPool: contracts.stability_pool as Bech32Address,
  sortedTroves: contracts.sorted_troves as Bech32Address,
  sortedTrovesImplementation:
    contracts.sorted_troves_implementation_id as B256Address,
  activePool: contracts.active_pool as Bech32Address,
  collateralSurplusPool: contracts.coll_surplus_pool as Bech32Address,
  defaultPool: contracts.default_pool as Bech32Address,
  fptStaking: contracts.fpt_staking as Bech32Address,
  fptToken: contracts.fpt_token as Bech32Address,
  communityIssuance: contracts.community_issuance as Bech32Address,
  communityIssuanceImplementation:
    contracts.community_issuance_implementation_id as B256Address,
  multiTroveGetter: contracts.multi_trove_getter as Bech32Address,
  vesting: contracts.vesting_contract as Bech32Address,
  hintHelper: contracts.hint_helper as Bech32Address,
  assets: allCollateralContracts,
};

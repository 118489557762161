import { Button, ButtonGroup } from "@chakra-ui/react";
import { useWallet } from "../../hooks/useWallet";

export const ConnectWalletButton = () => {
  const { isConnected, connect, disconnect } = useWallet();

  async function onClickConnect() {
    if (isConnected) {
      disconnect();
      return;
    } else {
      connect();
    }
  }

  return (
    <ButtonGroup
      flexDir={["column", "column", "row"]}
      justifySelf={"flex-end"}
      w={["100%", "100%", "auto"]}
      spacing={[0, 0, 4]}
      marginBottom={"20px"}
    >
      <Button
        mb={[0, 2, 2, 0]}
        onClick={() => {
          onClickConnect();
        }}
        mt={[2, 0, 0, 0]}
        size={["sm", "xs", "md"]}
      >
        Connect
      </Button>
    </ButtonGroup>
  );
};

import { VStack, Grid, HStack, GridItem } from "@chakra-ui/react";
import { OpenTroveCard } from "../components/home/OpenTroveCard";
import { PageWrapper } from "../components/common/PageWrapper";

export const Home = () => {
  return (
    <PageWrapper>
      <HStack
        justifyContent={"space-around"}
        display={"flex"}
        spacing={12}
        justifyItems={"flex-start"}
      >
        <VStack alignSelf={"center"} spacing={8} minW={["", "", "", "500px"]}>
          <Grid
            templateColumns={"repeat(2, 1fr)"}
            gap={6}
            justifyContent={"center"}
          >
            <GridItem colSpan={2}>
              <OpenTroveCard />
            </GridItem>
          </Grid>
        </VStack>
      </HStack>
    </PageWrapper>
  );
};

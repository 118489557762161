import { AssetConfig } from "./utils";
import ethTokenImage from "../images/eth-token.png";
import wstEthTokenImage from "../images/wsteth-token.png";
import ezEthTokenImage from "../images/ezeth-token.png";
import weEthTokenImage from "../images/weeth-token.png";
import rsEthTokenImage from "../images/rseth-token.svg";
import mEthTokenImage from "../images/meth-token.svg";

export const ETH_CONFIG: AssetConfig = {
  name: "Ethereum",
  symbol: "ETH",
  color: "rgba(98 126 234)",
  boxColor: "rgba(98, 126, 234, 0.6)",
  imageUrl: ethTokenImage,
};

export const WSTETH_CONFIG: AssetConfig = {
  name: "Wrapped Lido ETH",
  symbol: "wstETH",
  color: "rgb(1 162 255)",
  boxColor: "rgba(1, 162, 255, 0.6)",
  imageUrl: wstEthTokenImage,
};

export const EZETH_CONFIG: AssetConfig = {
  name: "Renzo Restaked ETH",
  symbol: "ezETH",
  color: "rgba(50, 205, 50, 0.7)", // Lime green
  boxColor: "rgba(50, 205, 50, 0.6)", // Lime green with opacity
  imageUrl: ezEthTokenImage,
};

export const WEETH_CONFIG: AssetConfig = {
  name: "Wrapped Etherfi ETH",
  symbol: "weETH",
  color: "rgba(147, 112, 219, 1)", // Light purple
  boxColor: "rgba(147, 112, 219, 0.6)", // Light purple with opacity
  imageUrl: weEthTokenImage,
};

export const RSETH_CONFIG: AssetConfig = {
  name: "Kelp DAO Restaked ETH",
  symbol: "rsETH",
  color: "rgba(34, 139, 34, 1)", // Forest green
  boxColor: "rgba(34, 139, 34, 0.6)", // Forest green with opacity
  imageUrl: rsEthTokenImage,
};

export const METH_CONFIG: AssetConfig = {
  name: "Mantle Restaked ETH",
  symbol: "mETH",
  color: "rgba(155, 17, 30, 1)", // Ruby red
  boxColor: "rgba(155, 17, 30, 0.8)", // Ruby red with opacity
  imageUrl: mEthTokenImage,
};

export const assetConfigs: AssetConfig[] = [ETH_CONFIG, WSTETH_CONFIG];

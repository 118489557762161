import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  UnorderedList,
  ListItem,
  Link,
  OrderedList,
} from "@chakra-ui/react";
import { PageWrapper } from "../components/common/PageWrapper";

const TermsOfService = () => {
  return (
    <PageWrapper>
      <Box
        maxW="70%"
        mx="auto"
        fontSize={{ base: "sm", md: "lg" }}
        textAlign="left"
      >
        <Text as="h4" mb={8}>
          <Text as="strong" display="block" mb={4}>
            Welcome to Fluid
          </Text>
          FLuid.com is a website-hosted user interface (the “Interface”) hosted
          by Hydrogen Labs, Inc. (“us” or “the Company”).
          <br />
          <br />
          Please read these terms and conditions carefully before using the
          Interface for any reason. Your use of the Interface is conditional
          upon your agreement to the Terms set out below. If you do not agree
          and you do not give your consent to be bound to the Terms, do not use
          the Interface and, if presented with the option to “accept” to the
          Terms of use, only select “accept” if you certify that you consent to
          be bound by the Terms.
          <br />
          <br />
          If you do not meet the eligibility requirements set forth in Section 7
          of the Terms or are otherwise not in strict compliance with these
          Terms, you are expressly prohibited from using, accessing, or deriving
          any benefit from the Interface and you must not attempt to access or
          use the Interface. Use of a virtual private network (e.g., a VPN) or
          other network (e.g., a VPN) or other means by ineligible persons to
          access or use the Interface is prohibited, and prohibited uses may
          attract legal liability for fraudulent use of the Interface.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            Terms of Use
          </Text>
          Use These Terms of Use and any terms and conditions incorporated by
          reference (collectively, the Terms) govern access to and the use of
          the Interface by each individual, entity, group, or association
          (collectively User, Users, You) who views, interacts, links to or
          otherwise uses or derives any benefit from the Interface.
          <br />
          <br />
          By accessing, browsing, or using the Interface, or by acknowledging
          your agreement to the Terms on the Interface, you agree that you have
          read, understood, and consented to be bound by all of the Terms,
          Privacy Policy, and Disclosure which are incorporated by reference
          into these Terms.
          <br />
          <br />
          Importantly, when you agree to these Terms by using or accessing the
          Interface, you agree to a binding arbitration provision and a class
          action waiver, both of which impact your rights as to how disputes are
          resolved.
          <br />
          <br />
          From time to time and at any time, the Terms may be changed, amended,
          or revised without notice or consultation. If you do not agree to the
          revised Terms, then you should not continue to access or use the
          Interface.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            Binding Provisions
          </Text>
          <Text as="strong" display="block" mb={4}>
            01. Dispute Resolution; Arbitration Agreement
          </Text>
          If you have any dispute or claim arising out of or relating in any way
          to the Interface or these Terms, you must send an email to
          support@hydrogenlabs.xyz to resolve the matter via a good faith
          negotiation process (the “Formal Complaint Process”). If that dispute
          or claim is not resolved within 60 days of sending such an email, then
          you agree that all unresolved disputes or claims shall be finally and
          exclusively settled by arbitration administered by the American
          Arbitration Association (“AAA”), in accordance with the Consumer
          Arbitration Rules (the "AAA Rules") then in effect. The arbitration
          shall be held in New York before a single arbitrator and shall be
          conducted in the English language on a confidential basis. Any award
          made by the arbitrator may be entered in any court of competent
          jurisdiction as necessary. This section shall survive termination of
          these Terms, the Interface, or any connection you may have to the
          information you obtained from the Interface.
          <br />
          <br />
          These Terms evidence a transaction involving interstate commerce; and
          notwithstanding any other provision herein with respect to the
          applicable substantive law, the Federal Arbitration Act, 9 U.S.C. § 1
          et seq., will govern the interpretation and enforcement of these Terms
          and any arbitration proceedings. If the Formal Complaint Process
          described above does not resolve satisfactorily within 60 business
          days after receipt of your complaint form, you and us agree that
          either party shall have the right to finally resolve the Dispute
          through binding arbitration. The arbitration will be administered by
          the American Arbitration Association (“AAA”), in accordance with the
          Consumer Arbitration Rules (the "AAA Rules") then in effect, except as
          modified by this section of this Arbitration Agreement. The AAA Rules
          are currently available at
          https://www.adr.org/sites/default/files/Consumer%20Rules.pdf.
          <br />
          <br />A party who wishes to initiate arbitration must provide the
          other party with a request for arbitration (the “Request”). If you
          initiate the arbitration, you must provide a copy of your Request by
          email at the support email provided above or through our registered
          agent for service of process. The Request must include: (1) the name,
          telephone number, mailing address, email address of the party seeking
          arbitration, and identifying information associated with the
          applicable account; (2) a statement of the legal claims being asserted
          and the factual bases of those claims; (3) a description of the remedy
          sought, including an accurate, good faith calculation of the amount in
          controversy in United States Dollars; (4) a statement certifying
          completion of the Formal Complaint Process as described above (if you
          are the party making the Request); and (5) evidence that the
          requesting party has paid any necessary filing fees in connection with
          such arbitration.
          <br />
          <br />
          If the party requesting arbitration is represented by counsel, the
          Request shall also include counsel’s name, telephone number, mailing
          address, and email address. Such counsel must also sign the Request.
          By signing the Request, counsel certifies to the best of counsel’s
          knowledge, information, and belief, formed after an inquiry reasonable
          under the circumstances, that: (1) the Request is not being presented
          for any improper purpose, such as to harass, cause unnecessary delay,
          or needlessly increase the cost of dispute resolution; (2) the claims,
          defenses and other legal contentions are warranted by existing law or
          by a nonfrivolous argument for extending, modifying, or reversing
          existing law or for establishing new law; and (3) the factual and
          damages contentions have evidentiary support or, if specifically so
          identified, will likely have evidentiary support after a reasonable
          opportunity for further investigation or discovery.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            02. Class Action and Jury Trial Waiver
          </Text>
          YOU AND US HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE
          IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and us
          are instead electing that all Disputes shall be resolved by
          arbitration, except as specified herein. There is no judge or jury in
          arbitration, and court review of an arbitration award is subject to
          very limited review.
          <br />
          <br />
          YOU AND US AGREE THAT, EXCEPT AS SPECIFIED IN THESE TERMS, EACH OF US
          MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT
          ON A CLASS, REPRESENTATIVE, OR COLLECTIVE BASIS, AND THE PARTIES
          HEREBY WAIVE ALL RIGHTS TO HAVE ANY DISPUTE BE BROUGHT, HEARD,
          ADMINISTERED, RESOLVED, OR ARBITRATED ON A CLASS, COLLECTIVE,
          REPRESENTATIVE, OR MASS ACTION BASIS. ONLY INDIVIDUAL RELIEF IS
          AVAILABLE, AND DISPUTES OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
          ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            03. Governing Law
          </Text>
          You agree that the laws of the State of Delaware, without regard to
          the principles of conflict of laws, govern these Terms.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            04. About the Interface
          </Text>
          The Interface aggregates and publishes publicly available third-party
          information about liquid staking technology. The Interface also offers
          interaction methods whereby the User can indicate a transaction that
          the User would like to perform in connection with the publicly
          available Fluid Smart Contract Systems (the Middleware) which are
          based on copies of the Fluid Smart Contract Protocol. The interaction
          methods include accessing the functionalities of publicly deployed
          Middleware for Users to self-authorize token transfers and self-mint
          utility tokens on relevant blockchains. When used in this way, the
          interface can generate a draft transaction message which a User can
          independently use in conjunction with a third-party wallet application
          or device to conduct transactions on any of the relevant blockchains.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            05. About the Middleware
          </Text>
          The Fluid Smart Contract Protocol are software source codes freely
          licensed to the public. Each Middleware is a copy of one of the Fluid
          Smart Contract Protocol that is compiled to bytecode and permanently
          associated with one or more specific public addresses on specific
          blockchains.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            06. Interface relationship to Middleware
          </Text>
          Using the relevant blockchain systems, third-party supplied wallets,
          devices, validator nodes, or the Middleware does not require use of
          this Interface. Anyone with an internet connection can connect
          directly to the Middleware or blockchain without accessing or using
          the Interface.
          <br />
          <br />
          The Interface maintainers do not own, operate or control the
          blockchain systems, wallets, devices, validator nodes, or the
          Middleware.
          <br />
          <br />
          The Interface aggregates and publishes publicly available information
          about the Middleware in a user-friendly and convenient format. Such
          information is also independently available from other sources—for
          example, a User may directly review the blockchain transaction
          history, account balances, and the individual Fluid Smart Contract
          Systems on compatible block explorers on each relevant blockchain.
          Users may also access code repositories for the various Fluid Smart
          Contract Protocol on platforms like Github.
          <br />
          <br />
          If the User chooses to initiate any transactions with the Fluid Smart
          Contract Protocol, the User must personally review and authorize all
          transaction messages that the User wishes to send to blockchain
          systems; this requires the User to sign the relevant transaction
          message with a private cryptographic key inaccessible to the Interface
          or the Interface maintainers, or Interface contributors. The use of
          such associated private cryptographic keys is beyond the control of
          the Interface, the Interface maintainers, or contributors.
          <br />
          <br />
          Any User-authorised message will be broadcasted to blockchain systems
          through the wallet application or device and the User may be required
          to pay a network fee to have the transaction message delivered and
          record the results on the appropriate blockchain. The Interface is not
          responsible for anything related to or arising from any transaction.
          <br />
          <br />
          The Interface maintainers and the Interface are not agents or
          intermediaries of the User. The Interface or the Interface maintainers
          do not store, have access to, or control over any tokens, private
          keys, passwords, accounts, or other property of the User. The
          Interface or the Interface maintainers are not capable of performing
          transactions or sending transaction messages on behalf of the User.
          The Interface or the Interface maintainers do not hold and cannot
          purchase, sell, or trade any tokens. All transactions relating to the
          Middleware are executed and recorded solely through the User’s
          interactions with the respective blockchains. The interactions are not
          under the control of or affiliated with the Interface maintainers or
          the Interface. The Interface maintainers do not collect any
          compensation from the User for use of the Interface. The Interface is
          only one method to display publicly available data and is not
          responsible for any of the data displayed – it is the User’s
          responsibility to personally verify any data displayed on the
          Interface.
          <br />
          <br />
          THE INTERFACE IS ONLY A TOOL TO ASSIST WITH VIEWING PUBLICLY AVAILABLE
          INFORMATION AND, IF APPLICABLE, CRAFTING TRANSACTIONS. Information
          displayed by the Interface and, if applicable, any transactions
          between the user and any smart contracts are made under the user’s own
          responsibility, and signed by the user’s own wallet. The Interface and
          the Company are not responsible for anything regarding how these
          transactions are used or made, or any resulting consequences.
          <br />
          <br />
          The Interface is wholly distinct from any smart contracts, including
          the Middleware, and is one, but not the exclusive, means of accessing
          the Middleware. The Company and the creators of the Interface do not
          control or operate any version of the Middleware or any smart
          contracts on any blockchain network or any other aspects of any
          blockchain network.
          <br />
          <br />
          To access the Interface, you must use a non-custodial wallet software,
          which allows you to interact with public blockchains. Your
          relationship with that non-custodial wallet provider is governed by
          the applicable terms of service of the wallet provider. We do not have
          custody or control over the contents of your wallet and have no
          ability to retrieve or transfer its contents. By connecting your
          wallet to our Interface, you agree to be bound by this Agreement and
          all of the terms incorporated herein by reference.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            07. Eligibility
          </Text>
          If you use the interface you represent and declare that you:
          <br />- are of legal age in the jurisdiction in which you reside to
          use the Interface and the Middlewares, and you have legal capacity to
          consent and agree to be bound by these Terms;
          <br />- have all technical knowledge necessary or advisable to
          understand and evaluate the risks of using the Interface and the
          Middlewares;
          <br />- comply with all applicable laws, rules, and regulations in
          your relevant jurisdiction and your use of the Interface is not
          prohibited by and does not otherwise violate or facilitate the
          violation of any applicable laws or regulations, or contribute to or
          facilitate any illegal activity;
          <br />- are not a US person, or currently or ordinarily located or
          resident in (or incorporated or organized in) the United States of
          America;
          <br />- are not a resident, citizen, national, or agent of, or an
          entity organized, incorporated or doing business in, Belarus, Burundi,
          Crimea and Sevastopol, Cuba, Democratic Republic of Congo, Iran, Iraq,
          Libya, North Korea, Somalia, Sudan, Syria, Venezuela, Zimbabwe or any
          other country to which the United States, the United Kingdom, the
          European Union or any of its member states or the United Nations or
          any of its member states (collectively, the Major Jurisdictions)
          embargoes goods or imposes similar sanctions (such embargoed or
          sanctioned territories, collectively, the Restricted Territories);
          <br />- are not, and do not directly or indirectly own or control, and
          have not received any assets from any blockchain address or protocol
          that is listed on any sanctions list or equivalent maintained by any
          of the Major Jurisdictions (such sanctions-listed persons,
          collectively, Sanctions Lists Persons);
          <br />- do not intend to transact in or with any Restricted
          Territories or Sanctions List Persons;
          <br />- you are not (a) the subject of economic or trade sanctions
          administered or enforced by any governmental authority or otherwise
          designated on any list of prohibited or restricted parties (including
          any governmental authority or otherwise designated on any list of
          prohibited or restricted parties (including but not limited to the
          list maintained by the Office of Foreign Assets Control of the U.S.
          Department of the Treasury) or (b) a citizen, resident, or organized
          in a jurisdiction or territory that is the subject of comprehensive
          country-wide, territory-wide, or regional economic sanctions by the
          United States;
          <br />- and you represent that your access and use of the Interface
          will fully comply with all applicable laws and regulations, and that
          you will not access the Interface to conduct, promote, or otherwise
          facilitate any illegal activity.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            08. Permitted Use
          </Text>
          The Permitted Use of the Interface is exclusively to aid
          technologically sophisticated persons who wish to use the Interface
          for informational purposes only as an aid to their own research, due
          diligence, and decision-making. Before using any information from the
          Interface (including any draft transaction messages) to engage in
          transactions, each User must independently verify the accuracy of such
          information (and, if applicable, the accuracy of any draft transaction
          messages applicable, the accuracy of any draft transaction messages
          created by the User with assistance from the Interface).
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            09. Prohibited Uses
          </Text>
          Each User must not, directly or indirectly, in connection with their
          use of the Interface:
          <br />
          <br />- use the Interface other than for the Permitted Use;
          <br />- use the Interface at any time when any representation of the
          User set forth in the Terms is untrue or inaccurate;
          <br />- rely on the Interface as a basis for or a source of advice
          concerning any financial or legal advice in making or transactions;
          <br />- employ any device, scheme or artifice to defraud, or otherwise
          materially mislead, any person; mislead, any person; - engage in any
          act, practice or course of business that operates or would operate as
          a fraud or deceit upon any person;
          <br /> - fail to comply with any applicable provision of these Terms
          or any other terms or conditions, privacy policy, or other policy
          governing the use of the Interface;
          <br />- engage, attempt, or assist in any hack of or attack on the
          Interface or any wallet application or device, including any “sybil
          attack”, “DoS attack”, “griefing attack”, virus deployment, or theft;
          <br />- commit any violation of applicable laws, rules, or regulations
          in your relevant jurisdiction;
          <br />- transact in securities, commodities futures, trading of
          commodities on a leveraged, margined or financed basis, binary options
          (including prediction-market transactions), real estate or real estate
          leases, equipment leases, debt financings, equity financings or other
          similar transactions, in each case, if such transactions do not comply
          with all laws, rules, and regulations applicable to the parties and
          assets engaged therein;
          <br />- engage in token-based or other financings of a business,
          enterprise, venture, DAO, software development project, or other
          initiative, including ICOs, DAICOs, IEOs, or other token-based
          fundraising events;
          <br />- engage in activity that violates any applicable law, rule, or
          regulation concerning the integrity of trading markets, including, but
          not limited to, the manipulative tactics commonly known as spoofing
          and wash trading.
          <br />- engage in any act, practice, or course of business that
          operates to circumvent any sanctions or export controls targeting the
          User or the country or territory where the User is located.
          <br />- engage in any activity that infringes on or violates any
          copyright, trademark, service mark, patent, right of publicity, right
          of privacy, or other proprietary or intellectual property rights under
          any law.
          <br />- engage in any activity that disguises or interferes in any way
          with the IP address of a computer used to access or use the Interface
          or that otherwise prevents correctly identifying the IP address of the
          computer used to access the Interface.
          <br />- engage in any activity that transmits, exchanges, or is
          otherwise supported by the direct or indirect proceeds of criminal or
          fraudulent activity; and
          <br />- engage in any activity that contributes to or facilitates any
          of the foregoing activities.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            10. Additional User Declarations
          </Text>
          Additionally, if you use the interface you consent to, represent, and
          declare that you agree:
          <br />- that the only duties and obligations connected with the
          Interface owed to the User are set forth in these Terms;
          <br />- that these Terms constitute legal, valid, and binding
          obligations enforceable against the Users;
          <br />- that the Interface is provided for informational purposes only
          and it is not directly or indirectly in control of the Middleware and
          related blockchain systems or capable of performing or effecting any
          transactions on your behalf;
          <br />- that the Interface is only being provided as an aid to your
          own independent research and evaluation of the Middleware and you
          should not take, or refrain from taking, any action based on any
          information on the Interface and without limitation from third party
          blog posts, articles, links news feeds, tutorials, tweets, and videos;
          <br />- that the ability of the Interface to connect with third-party
          wallet applications or devices is not an endorsement or recommendation
          by or on behalf of the Interface maintainers, and you assume all
          responsibility for selecting and evaluating, and incurring the risks
          of any bugs, defects, malfunctions, or interruptions of any
          third-party wallet applications or devices you directly or indirectly
          use in connection with the Interface;
          <br />- to not hold the Interface maintainers or any affiliates liable
          for any damages that you may suffer in connection with your use of the
          Interface or the Middleware;
          <br />- that the information available on the Interface is not
          professional, legal, business, investment, or any other advice related
          to any financial product;
          <br />- that the information is not an offer or recommendation or
          solicitation to buy or sell any particular digital asset or to use any
          particular investment strategy;
          <br />- that before you make any financial, legal, or other decision
          in connection with the interface, you should seek independent
          professional advice from an individual who is licensed and qualified
          in the area for which such advice would be appropriate;
          <br />- that the Terms are not intended to, and do not, create or
          impose any fiduciary duties on any party;
          <br />- to the fullest extent permitted by law, you acknowledge and
          agree that the Interface maintainers owe no fiduciary duties or
          liabilities to you or any other party;
          <br />- that to the extent any such duties or liabilities may exist at
          law or in equity, those duties and liabilities are hereby irrevocably
          disclaimed, waived, and eliminated;
          <br />- that you may suffer damages in connection with your use of the
          Interface or the Middleware and the Interface and Interface
          maintainers are not liable for such damages;
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            11. Certain risks
          </Text>
          Each User acknowledges, agrees, consents to, and assumes the risks of,
          the matters described in this Section 11.
          <br />
          <br />
          Interface Maintainers Have No Business Plan and May Discontinue,
          Limit, Terminate, or Refuse Support for the Interface There is no
          business plan or revenue model for the Interface.
          <br />
          <br />
          The Interface maintainers do not have revenues or a viable long-term
          business plan, and may become unable or unwilling to fund the
          operational costs of the Interface on a long-term basis or to fund the
          upgrade costs required to keep the Interface up to date with current
          and upcoming technologies.
          <br />
          <br />
          The Interface is a free web application maintained at the sole and
          absolute discretion of the Company. Individually and collectively they
          assume no duty, liability, obligation, or undertaking to continue to
          maintain, or to make available the Interface. The Interface
          maintainers may terminate or change the Interface with respect to any
          aspect of the Interface at any time.
          <br />
          <br />
          The Interface maintainers have no obligation, duty, or liability to
          ensure that the Interface is a complete and accurate source of all
          information relating to the Middleware or any other subject matter,
          Users need to rely on third-party resources such as block explorers or
          validator nodes in order to ensure the accuracy of any information,
          and, depending on the User’s level of expertise and the quality of
          such third-party resources, this may result in the User incurring
          damages due to delays or mistakes in processing information or
          transactions.
          <br />
          <br />
          The Protocols are available under a free open-source license, and the
          Interface maintainers do not have proprietary or exclusive rights to
          the Protocols. It is possible that additional copies of the Protocols
          or derivatives thereof will be deployed on blockchain systems in the
          future by any person, resulting in the existence of multiple
          Middlewares. The Interface maintainers are under no obligation to
          publish information for all such copies of the Protocols or to warn
          Users regarding the existence of such alternatives.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            No Regulatory Supervision
          </Text>
          The Interface maintainers and the Interface are not registered or
          qualified with or licensed by, do not report to, and are not under the
          active supervision of any government agency or financial regulatory
          authority or organization. No government or regulator has approved or
          has been consulted by the Interface maintainers regarding the accuracy
          or completeness of any information available on the Interface.
          Similarly, the technology, systems, blockchains, tokens, and persons
          relevant to information published on the Interface may not be
          registered with or under the supervision of or be registered or
          qualified with or licensed by any government agency or financial
          regulatory authority or organization. The Interface maintainers are
          not registered as brokers, dealers, advisors, transfer agents or other
          intermediaries.
          <br />
          <br />
          Regulatory Uncertainty Blockchain technologies and digital assets are
          subject to many legal and regulatory uncertainties, and the Middleware
          or any tokens or blockchains could be adversely impacted by one or
          more regulatory or legal inquiries, actions, suits, investigations,
          claims, fines, or judgments, which could impede or limit the ability
          of User to continue the use and enjoyment of such assets and
          technologies.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            No Warranty
          </Text>
          The Interface is provided on an “AS IS” and “AS AVAILABLE” basis. You
          acknowledge and agree that your access and use of the Interface are at
          your own risk. There is no representation or warranty that access to
          the Interface will be continuous, uninterrupted, timely, or secure;
          that the information contained in the Interface will be accurate,
          reliable, complete, or current, or that the Interface will be free
          from errors, defects, viruses, or other harmful elements. No advice,
          information, or statement made in connection with the Interface should
          be treated as creating any warranty concerning the Interface. There is
          no endorsement, guarantee, or assumption of responsibility for any
          advertisements, offers, or statements made by third parties concerning
          the Interface.
          <br />
          <br />
          Further, there is no representation or warranty, from anyone, as to
          the quality, origin, or ownership of any content found on or available
          through the Interface and there shall be no liability for any errors,
          misrepresentations, or omissions in, of, and about, the content, nor
          for the availability of the content attributable to any contributor to
          the Interface, including maintainers, and they shall not be liable for
          any losses, injuries, or damages from the use, inability to use, or
          the display of the content of the Interface. To the fullest extent
          permitted by applicable law, the Company and its affiliates, officers,
          directors, employees, agents, partners, and licensors shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, including but not limited to loss of profits,
          revenue, data, or use, incurred by you or any third party, whether in
          an action in contract, tort (including negligence), strict liability,
          or otherwise, arising from your access to or use of, or inability to
          access or use, the Interface, including any damages caused by or
          resulting from reliance on any information obtained through the
          Interface or any transactions crafted with the assistance of the
          Interface, or that result from mistakes, omissions, interruptions,
          deletion of files or emails, errors, defects, viruses, delays in
          operation or transmission, or any failure of performance, whether or
          not resulting from acts of God, communications failure, theft,
          destruction, or unauthorized access to the Company’s records,
          programs, or services.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            Third-Party Information
          </Text>
          In providing information on the Interface, the Interface may rely upon
          third-party resources which may not be accurate or may not conform to
          a given User’s expectations. Users must examine the specific smart
          contract or third-party source associated with any information
          displayed on the Interface and confirm that any information accords
          with the User’s expectations.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            User Responsibility for Accounts & Security
          </Text>
          Users are solely responsible for all matters relating to their
          accounts, private keys, addresses, and tokens and for ensuring that
          all uses thereof comply fully with these Terms. Users are solely
          responsible for protecting the data integrity and confidentiality of
          their information, and data or private keys for any wallet
          applications or devices used in connection with the Interface. The
          compatibility of the connection with the Interface. The compatibility
          of the Interface with wallet applications and devices or other
          third-party applications or devices is not intended as, and you hereby
          agree not to construe such compatibility as, an endorsement or
          recommendation thereof or a warranty, guarantee, promise, or assurance
          regarding the fitness or security thereof.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            No Interface Fees; Third-Party Fees Irreversible
          </Text>
          There are no fees or charges for the use of the Interface. Use of the
          Middleware and relevant blockchain may be subject to third-party
          transaction fees. The Interface maintainers do not receive such fees
          and have no ability to reverse or refund any amounts paid in error.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            12. License to Use Interface
          </Text>
          Each User, subject to their eligibility, acceptance, and adherence to
          adherence to these Terms, is hereby granted a personal, revocable,
          non-exclusive, non-transferable, non-sublicensable license to view,
          access and use the Interface for the Permitted Uses in accordance with
          these Terms. Unlike the Interface, the Middleware is open-source
          software running on public blockchains and is not the property of the
          Interface maintainers or the Company.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            13. Privacy Policy
          </Text>
          The Interface may directly or indirectly collect and temporarily store
          personally identifiable information for operational purposes,
          including for the purpose of identifying blockchain addresses or IP
          addresses that may indicate the use of the Interface from prohibited
          jurisdictions or by sanctioned persons or other Prohibited Uses. Any
          data collected through the use of the Interface will be subject to the
          terms of our privacy policy, available at Fluid.com.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            14. Non-Reliance
          </Text>
          The Users declare that they are knowledgeable, experienced, and
          sophisticated in using and evaluating blockchain and related
          technologies and assets, including blockchains, tokens, and proof of
          stake smart contract systems. The Users declare that they have
          conducted their own thorough independent investigation and analysis of
          the Middleware and the other matters contemplated by these Terms, and
          have not relied upon any information, statement, omission,
          representation, or warranty, express or implied, written or oral, made
          by or on behalf of Interface maintainers in connection therewith,
          except as expressly set forth in these Terms.
          <br />
          <br />
          <Text as="strong" display="block" mb={4}>
            15. Risks, Disclaimers, and Limitations of Liability
          </Text>
          Each User hereby acknowledges and agrees, and consents to, and assumes
          the risks of, the matters described in Section 15 of the Terms.
          <br />
          <br />
          1) Third-Party Offerings and Content References, links, or referrals
          to or connections with or reliance on third-party resources, products,
          services, or content, including smart contracts developed or operated
          by third parties, may be provided to Users in connection with the
          Interface. In addition, third parties may offer promotions related to
          the Interface. Interface maintainers do not endorse or assume any
          responsibility for any activities, resources, products, services,
          content, or promotions owned, controlled, operated, or sponsored by
          third parties. If Users access any such resources, products, services,
          or content or participate in any such promotions, Users do so solely
          at their own risk. Each User hereby expressly waives and releases
          Interface maintainers from all liability arising from the User’s use
          of any such resources, products, services, or content or participation
          in any such promotions. The User further acknowledges and agrees that
          Interface maintainers shall not be responsible or liable, directly or
          indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with the use of or reliance on any such resources,
          products, services, content, or promotions from third parties.
          <br />
          <br />
          2) Cryptography Risks Cryptography is a progressing field. Advances in
          code cracking or technical advances such as the development of quantum
          computers may present risks to blockchain systems, the Middleware, or
          tokens, including the theft, loss, or inaccessibility thereof.
          <br />
          <br />
          3) Fork Handling The Middleware, and all tokens may be subject to
          Forks. Forks occur when some or all persons running the software
          clients for a particular blockchain system adopt a new client or a new
          version of particular blockchain system adopt a new client or a new
          version of an existing client that: (i) changes the protocol rules in
          backward-compatible or backward-incompatible manner that affects which
          transactions can be added into later blocks, how later blocks are
          added to the blockchain, or other matters relating to the future
          operation of the protocol; or (ii) reorganizes or changes past blocks
          to alter the history of the blockchain. Some forks are “contentious”
          and thus may result in two or more persistent alternative versions of
          the protocol or blockchain, either of which may be viewed as or
          claimed to be the legitimate or genuine continuation of the original.
          Interface maintainers cannot anticipate, control or influence the
          occurrence or outcome of forks, and do not assume any risk, liability,
          or obligation in connection therewith. Without limiting the generality
          of the foregoing, Interface maintainers do not assume any
          responsibility to notify a User of pending, threatened, or completed
          forks. Interface maintainers will respond (or refrain from responding)
          to any forks in such manner as Interface maintainers determine in
          their sole and absolute discretion. Interface maintainers shall not
          have any duty or obligation, or liability to a User if such response
          (or lack of such response) acts to a User’s detriment. Each User
          assumes full responsibility to independently remain apprised of and
          informed about possible forks, and to manage the User’s own interests
          and risks in connection therewith.
          <br />
          <br />
          4) Essential Third-Party Software Dependencies The Middleware and
          other relevant blockchain systems and smart contracts are public
          software utilities that are accessible directly through any compatible
          third-party node or indirectly through any compatible third-party
          “wallet” application that interacts with such a node. Interacting with
          the Middleware does not require the use of the Interface, but the
          Interface is only one option of reading and displaying data from the
          Middleware and, if applicable, generating standard draft transaction
          messages compatible with the Middleware. The User may choose to
          interact with the Middleware using software other than the Interface.
          As the Interface does not provide wallet software applications or
          nodes for blockchain systems, such software constitutes an essential
          third-party software and user dependency without which the Middleware
          cannot be used and tokens cannot be traded or used. Furthermore, the
          Interface may use APIs and servers of Interface maintainers or third
          parties and there are no guarantees as to the continued operation,
          maintenance, availability, or security of any of the foregoing
          dependencies.
          <br />
          <br />
          5) Tax Issues The tax consequences of purchasing, selling, holding,
          transferring, or otherwise utilizing the Middleware are uncertain and
          may vary by jurisdiction. Interface Maintainers have undertaken no due
          diligence or investigation into such tax consequences and assume no
          obligation or liability to optimize, facilitate or bear the tax
          consequences to any person.
          <br />
          <br />
          6) Legal Limitations on Disclaimers Some jurisdictions do not allow
          the exclusion of certain warranties or the limitation or exclusion of
          limitation or exclusion of certain liabilities and damages.
          Accordingly, some of the disclaimers and limitations set forth in
          these Terms may not apply in full to specific Users. The disclaimers
          and limitations of liability provided in these terms shall apply to
          the fullest extent as permitted by applicable law.
          <br />
          <br />
          7) Indemnification Each User shall defend, indemnify, compensate,
          reimburse, and hold harmless the Interface maintainers from any claim,
          demand, action, damage, loss, cost, or expense, including without
          limitation reasonable attorneys’ fees, arising out or relating to (a)
          User's use of, or conduct in connection with, the Interface; (b)
          User's violation of these Terms or any other applicable policy or
          contract of Interface maintainers; or (c) User's violation of any
          rights of any other person or entity. 16. Entire Representation,
          Consent and Agreement These Terms, including the Privacy Policy,
          constitute your entire representation, consent, and agreement with
          respect to the subject matter, including the Interface. These Terms,
          including the Privacy Policy, and any disclosure and disclaimers
          incorporated by reference supersede all prior Terms, written or oral
          understandings, communications, and other agreements relating to the
          subject matter of the Terms.
        </Text>
      </Box>
    </PageWrapper>
  );
};

export { TermsOfService };

import { Progress, HStack, Box, Text, Tooltip } from "@chakra-ui/react";
import { getColorScheme } from "../../shared/format";
import { MIN_COLLATERAL_RATIO_DECIMAL } from "../../shared/constants";

const AnnotatedProgress = ({
  value,
  topPosition,
}: {
  value: number | undefined;
  topPosition: string;
}) => {
  const min = MIN_COLLATERAL_RATIO_DECIMAL;
  const max = 250;

  var percentagePosition =
    value !== undefined
      ? Math.max(Math.min(((value - min) / (max - min)) * 100, 85), 5)
      : 0;

  // if value has more digits than 3, we need to adjust the position a bit
  // a bit of a hack, but it works
  if (value && value.toString().length >= 4) {
    percentagePosition =
      percentagePosition - 0.75 * (value.toString().length - 3);
  }

  return (
    <Tooltip
      bg="textSecondary"
      color="black"
      placement="top"
      position={"relative"}
      top={"20px"}
      hasArrow
      label={"Trove Collateral Ratio"}
    >
      <HStack
        width={"100%"}
        spacing={4}
        flexDirection="column"
        align="center"
        marginLeft={"0 !important"}
        gap={0}
      >
        <HStack
          width={"100%"}
          px={0}
          justifyContent={"space-between"}
          marginLeft={"0 !important"}
        >
          <Text fontSize="sm">{MIN_COLLATERAL_RATIO_DECIMAL}%</Text>

          <Text m={0} fontSize="sm">
            250%+
          </Text>

          <Text
            position="absolute"
            left={`calc(${percentagePosition}%)`}
            top={topPosition}
            fontSize="sm"
            zIndex={0}
          >
            {value ? value + "%" : ""}
          </Text>
        </HStack>
        <Box width={"100%"} position="relative" marginLeft={"0 !important"}>
          <Progress
            size="sm"
            value={value ? value - MIN_COLLATERAL_RATIO_DECIMAL : 250}
            colorScheme={value ? getColorScheme(value) : "gray"}
          />
        </Box>
      </HStack>
    </Tooltip>
  );
};

export default AnnotatedProgress;

import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { CardStyles } from "./cardStyles";
import { TextStyles } from "./textStyles";

export const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: "dark",
    cssVarPrefix: "fluid",
    disableTransitionOnChange: false,
    storageKey: "fluid-color-mode",
    storageManager: {
      get: (key: string) => {
        let value = localStorage.getItem(key);
        if (!value) {
          localStorage.setItem(key, "dark");
          return "dark";
        }
        return value;
      },
      set: (key: string, value: string) => {
        localStorage.setItem(key, value);
      },
    },
  },
  colors: {
    textPrimary: "#ffffff",
    textSecondary: "#9ea6b1",
    bgDarkGrey: "#202020",
    bgLightGrey: "#424141",
    bgMediumGrey: "#313131",
  },
  components: {
    Card: CardStyles,
    Text: TextStyles,
  },

  styles: {
    global: (props: any) => ({
      "html, body": {
        fontFamily: "body",
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("white", "black")(props),
        transitionProperty: "background-color",
        transitionDuration: "normal",
        lineHeight: "base",
      },
    }),
  },
});

import { useFuel } from "@fuels/react";
import { useWallet } from "./useWallet";
import { Address, AssetId, B256Address, BN, Provider } from "fuels";
import React, {
  useState,
  PropsWithChildren,
  useContext,
  useEffect,
} from "react";
import { AssetContracts, ProtocolContracts } from "../shared/contracts";
import { Contracts as TestnetContracts } from "../shared/contracts.testnet";
import {
  Contracts as MainnetContracts,
  ethContracts as MainnetEthContracts,
} from "../shared/contracts.mainnet";
import { TokenContract } from "../types/token-contract";
import { OracleContract } from "../types/oracle-contract";
import {
  IdentityInput,
  TroveManagerContract,
} from "../types/trove-manager-contract/TroveManagerContract";
import { StabilityPoolContract } from "../types/stability-pool-contract";
import { BorrowOperationsContract } from "../types/borrow-operations-contract";
import { SortedTrovesContract } from "../types/sorted-troves-contract";
import { ActivePoolContract } from "../types/active-pool-contract/ActivePoolContract";
import { MockPythContract } from "../types/mock-pyth-contract";
import { MockRedstoneContract } from "../types/mock-redstone-contract";
import { FptStakingContract } from "../types/fpt-staking-contract/FptStakingContract";
import { VestingContract } from "../types/vesting-contract/VestingContract";
import { UsdfTokenContract } from "../types/usdf-token-contract/UsdfTokenContract";
import { MAINNET_ENDPOINT, TESTNET_ENDPOINT } from "../shared/constants";
import { ReadonlyMiraAmm } from "mira-dex-ts";
import { PRECISION } from "../shared/format";

type Props = {
  children?: React.ReactNode;
};

interface IFluidContext {
  contracts: ProtocolContracts;
  isTestnet: Network;
  setIsTestnet: (network: Network) => void;
  address: string | null;
  usdfBalance: BN;
  fptBalance: BN;
  borrowOperationsContract: BorrowOperationsContract | undefined;
  stabilityPoolContract: StabilityPoolContract | undefined;
  sortedTrovesContract: SortedTrovesContract | undefined;
  activePoolContract: ActivePoolContract | undefined;
  fptStakingContract: FptStakingContract | undefined;
  vestingContract: VestingContract | undefined;
  wallet: any;
  assetsLoaded: boolean;
  assets: IAssetContext[];
  reloadData: (assetIndex?: number, skipAssets?: boolean) => void;
  totalUsdfMinted: BN;
  usdfPrice: BN;
  fptPrice: BN;
}

export interface TrovePosition {
  debt: BN;
  coll: BN;
  debt_reward: BN;
  coll_reward: BN;
}

const defaultTrovePosition: TrovePosition = {
  debt: new BN(0),
  coll: new BN(0),
  debt_reward: new BN(0),
  coll_reward: new BN(0),
};

export interface IAssetContext {
  balance: BN;
  price: BN;
  symbol: string;
  troveColor: string;
  troveBoxColor: string;
  contractIds: AssetContracts;
  troveManagerContract: TroveManagerContract | undefined;
  oracleContract: OracleContract | undefined;
  oracleImplementation: B256Address | undefined;
  pythContract: MockPythContract | undefined;
  redstoneContract: MockRedstoneContract | undefined;
  assetContract: TokenContract | undefined;
  trove: TrovePosition;
  totalInActivePool: BN;
}

export function defaultAssetContext(): IAssetContext {
  return {
    troveColor: "",
    troveBoxColor: "",
    contractIds: MainnetEthContracts,
    balance: new BN(0),
    price: new BN(0),
    totalInActivePool: new BN(0),
    symbol: "",
    troveManagerContract: undefined,
    oracleContract: undefined,
    assetContract: undefined,
    oracleImplementation: undefined,
    trove: defaultTrovePosition,
    pythContract: undefined,
    redstoneContract: undefined,
  };
}

// define the network resolving | testnet or mainnet
type Network = "testnet" | "mainnet" | "unknown";

const defaultState: IFluidContext = {
  isTestnet: "unknown",
  setIsTestnet: () => {},
  contracts: MainnetContracts,
  wallet: undefined,
  address: null,
  usdfBalance: new BN(0),
  fptBalance: new BN(0),
  stabilityPoolContract: undefined,
  borrowOperationsContract: undefined,
  sortedTrovesContract: undefined,
  activePoolContract: undefined,
  fptStakingContract: undefined,
  vestingContract: undefined,
  assetsLoaded: false,
  assets: [],
  reloadData: () => {},
  totalUsdfMinted: new BN(0),
  usdfPrice: new BN(1_003_000_000),
  fptPrice: new BN(0),
};

const FluidContext = React.createContext<IFluidContext>(defaultState);

export function useFluid() {
  return useContext(FluidContext);
}

export const FluidProvider: React.FC<PropsWithChildren<Props>> = (
  children: Props
) => {
  const { fuel } = useFuel();

  const { account } = useWallet();
  const [isTestnet, setIsTestnet] = useState<Network>("unknown");
  const [assets, setAssets] = useState<IAssetContext[]>([]);
  const [assetsLoaded, setAssetsLoaded] = useState<boolean>(false);
  const [stabilityPoolContract, setStabilityPoolContract] = useState<
    StabilityPoolContract | undefined
  >(undefined);
  const [usdfBalance, setUSDFBalance] = useState<BN>(new BN(0));
  const [fptBalance, setFptBalance] = useState<BN>(new BN(0));
  const [borrowOperationsContract, setBorrowOperations] = useState<
    BorrowOperationsContract | undefined
  >(undefined);
  const [sortedTrovesContract, setSortedTroves] = useState<
    SortedTrovesContract | undefined
  >(undefined);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [activePoolContract, setActivePool] = useState<
    ActivePoolContract | undefined
  >(undefined);
  const [wallet, setWallet] = useState<any>(undefined);
  const [fptStakingContract, setFptStakingContract] = useState<
    FptStakingContract | undefined
  >(undefined);
  const [vestingContract, setVestingContract] = useState<
    VestingContract | undefined
  >(undefined);
  const [totalUsdfMinted, setTotalUsdfMinted] = useState<BN>(new BN(0));
  const [usdfTokenContract, setUsdfTokenContract] = useState<
    UsdfTokenContract | undefined
  >(undefined);
  const [usdfPrice, setUsdfPrice] = useState<BN>(new BN(1_003_000_000));
  const [fptPrice, setFptPrice] = useState<BN>(new BN(0));
  // TODO: Remove this once we have a real price feed
  const checkNetwork = async () => {
    if (fuel) {
      const network = await fuel.currentNetwork();

      setIsTestnet(network.chainId === 0 ? "testnet" : "mainnet");
    }
  };

  // Initial network check
  useEffect(() => {
    checkNetwork();
  }, [fuel]);

  // Create a variable to hold the correct contracts
  let Contracts = isTestnet === "testnet" ? TestnetContracts : MainnetContracts;

  useEffect(() => {
    if (!usdfTokenContract) return;

    const fetchTotalUsdfSupply = async () => {
      try {
        const totalSupply = await usdfTokenContract.functions
          .total_supply({
            bits: Contracts.UsdfAssetId,
          })
          .get();
        if (totalSupply.value) {
          setTotalUsdfMinted(totalSupply.value);
        }
      } catch (error) {
        console.error("Error fetching total USDF supply:", error);
      }
    };

    fetchTotalUsdfSupply();
  }, [usdfTokenContract]);

  async function reloadData(assetIndex?: number, skipAssets: boolean = false) {
    await checkNetwork();
    if (!account || isTestnet === "unknown") return;
    Contracts = isTestnet === "testnet" ? TestnetContracts : MainnetContracts;

    fuel.getWallet(account).then(async (wallet) => {
      setWallet(wallet);
      // Always reload USDF and FPT balances
      wallet.getBalance(Contracts.UsdfAssetId).then((balance: BN) => {
        setUSDFBalance(balance);
      });

      wallet.getBalance(Contracts.fptAssetId).then((balance: BN) => {
        setFptBalance(balance);
      });

      if (!skipAssets) {
        if (assetIndex !== undefined) {
          // Reload only the specified asset
          const updatedAsset = await reloadAsset(wallet, assetIndex);
          setAssets((prevAssets) => {
            const newAssets = [...prevAssets];
            newAssets[assetIndex] = updatedAsset;
            return newAssets;
          });
        } else {
          // Reload all assets concurrently
          setAssetsLoaded(false);
          const assetPromises = Contracts.assets.map((_, idx) =>
            reloadAsset(wallet, idx)
          );
          const tempassets = await Promise.all(assetPromises);
          setAssets(tempassets);
          setAssetsLoaded(true);
        }
      }

      setIsDataLoaded(true);
    });
  }

  async function reloadAsset(wallet: any, idx: number): Promise<IAssetContext> {
    if (!account || isTestnet === "unknown") return defaultAssetContext();
    let assetContracts = Contracts.assets[idx];
    // console.log("isTestnet", isTestnet);
    // console.log("assetContracts", assetContracts);
    let provider = await Provider.create(
      isTestnet === "testnet"
        ? "https://testnet.fuel.network/v1/graphql"
        : MAINNET_ENDPOINT,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          Accept: "*/*",
        },
      }
    );
    let assetContext = defaultAssetContext();
    assetContext.symbol = assetContracts.assetSymbol;
    assetContext.contractIds = assetContracts;
    assetContext.troveColor = assetContracts.color;
    assetContext.troveBoxColor = assetContracts.boxColor;

    let contractAddress = assetContracts.assetId;
    // Create a promise for balance retrieval
    const balancePromise = wallet.getBalance(contractAddress);

    let oracleContractAddress = new Address(assetContracts.oracle).toB256();
    const oracle = new OracleContract(oracleContractAddress, provider);
    assetContext.oracleContract = oracle;

    assetContext.oracleImplementation = assetContracts.oracleImplementation;

    let troveManagerAddress = new Address(assetContracts.troveManager).toB256();
    const troveManager = new TroveManagerContract(
      troveManagerAddress,
      provider
    );
    assetContext.troveManagerContract = troveManager;

    let assetAddress = assetContracts.assetId;
    const assetContract = new TokenContract(assetAddress, provider);
    assetContext.assetContract = assetContract;

    let pythContractAddress = new Address(assetContracts.pythContract).toB256();
    const pythContract = new MockPythContract(pythContractAddress, provider);
    assetContext.pythContract = pythContract;

    // Create a promise for price retrieval
    const pricePromise = oracle.functions
      .get_price()
      .addContracts([pythContract, oracle])
      .get();

    // console.log(
    //   "DEBUG account",
    //   account,
    //   typeof account,
    //   account as Bech32Address
    // );
    let myB256Address = Address.fromString(account).toB256();
    // console.log("DEBUG myB256Address", myB256Address);
    let myAddress = {
      bits: myB256Address,
    };
    const identityInput: IdentityInput = {
      Address: myAddress,
    };

    // console.log("getting trove", identityInput);
    // Create a promise for trove data retrieval
    const troveDataPromise = assetContext.troveManagerContract.functions
      .get_entire_debt_and_coll(identityInput)
      .get();

    // Add this new section to query the total amount in the Active Pool
    let activePoolAddress = new Address(Contracts.activePool).toB256();
    const activePool = new ActivePoolContract(activePoolAddress, provider);
    let totalInActivePoolPromise = activePool.functions
      .get_asset({
        bits: assetContracts.assetId,
      })
      .get();

    // Await both promises here
    const [balance, priceResult, troveData, totalInActivePool] =
      await Promise.all([
        balancePromise.catch((e: any) => {
          console.log("error fetching balance", e);
          return new BN(0);
        }),
        pricePromise.catch((e: any) => {
          console.log("error fetching price", e);
          return { value: new BN(0) };
        }),
        troveDataPromise.catch((e: any) => {
          return null;
        }),
        totalInActivePoolPromise.catch((e: any) => {
          console.log("error fetching total in active pool", e);
          return { value: new BN(0) };
        }),
      ]);
    assetContext.balance = balance;
    assetContext.price = priceResult.value;
    assetContext.totalInActivePool = totalInActivePool.value;

    // console.log("DEBUG troveData", troveData);
    // console.log("DEBUG priceResult", priceResult);
    // console.log("DEBUG balance", balance);
    // console.log("DEBUG totalInActivePool", totalInActivePool);

    if (troveData) {
      assetContext.trove = {
        debt: troveData.value[0],
        coll: troveData.value[1],
        debt_reward: troveData.value[2],
        coll_reward: troveData.value[3],
      };
    }

    return assetContext;
  }

  useEffect(() => {
    let retryTimer: NodeJS.Timeout;

    const attemptConnection = () => {
      if (!account) {
        setUSDFBalance(new BN(0));
        retryTimer = setTimeout(attemptConnection, 10000);
      } else {
        reloadData();
      }
    };

    attemptConnection();

    // Clean up function
    return () => {
      if (retryTimer) {
        clearTimeout(retryTimer);
      }
    };
  }, [account, isTestnet]);

  useEffect(() => {
    if (!wallet || isTestnet === "unknown") return;

    const setupContracts = async () => {
      let provider = await Provider.create(
        isTestnet === "testnet" ? TESTNET_ENDPOINT : MAINNET_ENDPOINT
      );
      let contractAddress = new Address(Contracts.BorrowOperations).toB256();
      const contract = new BorrowOperationsContract(contractAddress, provider);
      setBorrowOperations(contract);

      let stabilityPoolAddress = new Address(Contracts.stabilityPool).toB256();
      const stabilityPool = new StabilityPoolContract(
        stabilityPoolAddress,
        provider
      );
      setStabilityPoolContract(stabilityPool);

      let sortedTrovesAddress = new Address(Contracts.sortedTroves).toB256();
      const sortedTroves = new SortedTrovesContract(
        sortedTrovesAddress,
        provider
      );
      setSortedTroves(sortedTroves);

      let activePoolAddress = new Address(Contracts.activePool).toB256();
      const activePool = new ActivePoolContract(activePoolAddress, provider);
      setActivePool(activePool);

      let fptStakingAddress = new Address(Contracts.fptStaking).toB256();
      const fptStaking = new FptStakingContract(fptStakingAddress, provider);
      setFptStakingContract(fptStaking);

      let vestingAddress = new Address(Contracts.vesting).toB256();
      const vesting = new VestingContract(vestingAddress, wallet);
      setVestingContract(vesting);

      let usdfTokenAddress = new Address(Contracts.Usdf).toB256();
      const usdfToken = new UsdfTokenContract(usdfTokenAddress, provider);
      setUsdfTokenContract(usdfToken);
    };

    setupContracts();
  }, [wallet, isTestnet]);

  useEffect(() => {
    // This effect will run when the component mounts and when dependencies change
    const fetchUsdfPrice = async () => {
      try {
        // For now, we're using the hardcoded value
        // In the future, replace this with the actual query
        // TO
        const price = new BN(1_003_000_000);
        setUsdfPrice(price);
      } catch (error) {
        console.error("Error fetching USDF price:", error);
      }
    };

    fetchUsdfPrice();
  }, []);

  // Add this function to get Mira provider
  const getMiraProvider = () => {
    return Provider.create(
      isTestnet === "testnet" ? TESTNET_ENDPOINT : MAINNET_ENDPOINT,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
          Accept: "*/*",
        },
      }
    ).then((provider) => new ReadonlyMiraAmm(provider));
  };

  // Add effect to calculate FPT price
  useEffect(() => {
    if (assets[0] && assets[0].price ) {
      getMiraProvider()
        .then((miraProvider) => {
          console.log("miraProvider", miraProvider);
          const fptAssetId = { bits: Contracts.fptAssetId };
          const ethAssetId = { bits: Contracts.assets[0].assetId };

          type PoolId = [AssetId, AssetId, boolean];
          const fptPricePools: PoolId[] = [[ethAssetId, fptAssetId, false]];

          return miraProvider.previewSwapExactInput(
            fptAssetId,
            new BN(PRECISION),
            fptPricePools
          );
        })
        .then((fptPriceInETH) => {
          const fptPriceInUSD = fptPriceInETH[1]
            .mul(assets[0].price)
            .div(new BN(PRECISION));
          setFptPrice(fptPriceInUSD);
        })
        .catch((error) => {
          console.error("Error calculating FPT price:", error);
        });
    }
  }, [assets, Contracts]);

  return (
    <FluidContext.Provider
      value={{
        isTestnet, // Add this to the context
        contracts: Contracts,
        stabilityPoolContract,
        address: account ? account : "",
        usdfBalance,
        borrowOperationsContract,
        activePoolContract,
        sortedTrovesContract,
        fptStakingContract,
        vestingContract,
        wallet,
        fptBalance,
        assets,
        assetsLoaded,
        reloadData,
        totalUsdfMinted,
        usdfPrice,
        fptPrice,
        setIsTestnet,
      }}
    >
      {children.children}
    </FluidContext.Provider>
  );
};

/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.0
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
  StrSlice,
} from 'fuels';

import type { Enum } from "./common";

export enum AccessErrorInput { NotOwner = 'NotOwner' };
export enum AccessErrorOutput { NotOwner = 'NotOwner' };
export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;
export enum InitializationErrorInput { CannotReinitialized = 'CannotReinitialized' };
export enum InitializationErrorOutput { CannotReinitialized = 'CannotReinitialized' };

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type AdjustTroveEventInput = { user: IdentityInput, asset_id: AssetIdInput, collateral_change: BigNumberish, debt_change: BigNumberish, is_collateral_increase: boolean, is_debt_increase: boolean, total_collateral: BigNumberish, total_debt: BigNumberish };
export type AdjustTroveEventOutput = { user: IdentityOutput, asset_id: AssetIdOutput, collateral_change: BN, debt_change: BN, is_collateral_increase: boolean, is_debt_increase: boolean, total_collateral: BN, total_debt: BN };
export type AssetIdInput = { bits: string };
export type AssetIdOutput = AssetIdInput;
export type CloseTroveEventInput = { user: IdentityInput, asset_id: AssetIdInput, collateral: BigNumberish, debt: BigNumberish };
export type CloseTroveEventOutput = { user: IdentityOutput, asset_id: AssetIdOutput, collateral: BN, debt: BN };
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;
export type OpenTroveEventInput = { user: IdentityInput, asset_id: AssetIdInput, collateral: BigNumberish, debt: BigNumberish };
export type OpenTroveEventOutput = { user: IdentityOutput, asset_id: AssetIdOutput, collateral: BN, debt: BN };
export type OwnershipRenouncedInput = { previous_owner: IdentityInput };
export type OwnershipRenouncedOutput = { previous_owner: IdentityOutput };
export type OwnershipSetInput = { new_owner: IdentityInput };
export type OwnershipSetOutput = { new_owner: IdentityOutput };
export type OwnershipTransferredInput = { new_owner: IdentityInput, previous_owner: IdentityInput };
export type OwnershipTransferredOutput = { new_owner: IdentityOutput, previous_owner: IdentityOutput };

export type BorrowOperationsContractConfigurables = Partial<{
  INITIALIZER: IdentityInput;
}>;

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "bool",
      "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
    },
    {
      "type": "enum standards::src5::AccessError",
      "concreteTypeId": "3f702ea3351c9c1ece2b84048006c8034a24cbc2bad2e740d0412b4172951d3d",
      "metadataTypeId": 1
    },
    {
      "type": "enum std::identity::Identity",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "metadataTypeId": 2
    },
    {
      "type": "enum sway_libs::ownership::errors::InitializationError",
      "concreteTypeId": "1dfe7feadc1d9667a4351761230f948744068a090fe91b1bc6763a90ed5d3893",
      "metadataTypeId": 3
    },
    {
      "type": "str",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "type": "struct events::AdjustTroveEvent",
      "concreteTypeId": "5d86a53534b154ff98732e6057dc10eec4dd6751988367290dba6324dbe44de5",
      "metadataTypeId": 4
    },
    {
      "type": "struct events::CloseTroveEvent",
      "concreteTypeId": "20c54d50821f305c7bded39faabe41cdf0fa398991ae6968969e24e6bef995aa",
      "metadataTypeId": 5
    },
    {
      "type": "struct events::OpenTroveEvent",
      "concreteTypeId": "ed0cd46d47c992b590c2ab4626cc9272d1bcbc071e91e6571b191f6e01f51ea6",
      "metadataTypeId": 6
    },
    {
      "type": "struct std::asset_id::AssetId",
      "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
      "metadataTypeId": 8
    },
    {
      "type": "struct std::contract_id::ContractId",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "metadataTypeId": 9
    },
    {
      "type": "struct sway_libs::ownership::events::OwnershipRenounced",
      "concreteTypeId": "43c4fa7b3297401afbf300127e59ea913e5c8f0c7ae69abbec789ab0bb872bed",
      "metadataTypeId": 10
    },
    {
      "type": "struct sway_libs::ownership::events::OwnershipSet",
      "concreteTypeId": "e1ef35033ea9d2956f17c3292dea4a46ce7d61fdf37bbebe03b7b965073f43b5",
      "metadataTypeId": 11
    },
    {
      "type": "struct sway_libs::ownership::events::OwnershipTransferred",
      "concreteTypeId": "b3fffbcb3158d7c010c31b194b60fb7857adb4ad61bdcf4b8b42958951d9f308",
      "metadataTypeId": 12
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    }
  ],
  "metadataTypes": [
    {
      "type": "b256",
      "metadataTypeId": 0
    },
    {
      "type": "enum standards::src5::AccessError",
      "metadataTypeId": 1,
      "components": [
        {
          "name": "NotOwner",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 2,
      "components": [
        {
          "name": "Address",
          "typeId": 7
        },
        {
          "name": "ContractId",
          "typeId": 9
        }
      ]
    },
    {
      "type": "enum sway_libs::ownership::errors::InitializationError",
      "metadataTypeId": 3,
      "components": [
        {
          "name": "CannotReinitialized",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "struct events::AdjustTroveEvent",
      "metadataTypeId": 4,
      "components": [
        {
          "name": "user",
          "typeId": 2
        },
        {
          "name": "asset_id",
          "typeId": 8
        },
        {
          "name": "collateral_change",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "debt_change",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "is_collateral_increase",
          "typeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
        },
        {
          "name": "is_debt_increase",
          "typeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
        },
        {
          "name": "total_collateral",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "total_debt",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct events::CloseTroveEvent",
      "metadataTypeId": 5,
      "components": [
        {
          "name": "user",
          "typeId": 2
        },
        {
          "name": "asset_id",
          "typeId": 8
        },
        {
          "name": "collateral",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "debt",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct events::OpenTroveEvent",
      "metadataTypeId": 6,
      "components": [
        {
          "name": "user",
          "typeId": 2
        },
        {
          "name": "asset_id",
          "typeId": 8
        },
        {
          "name": "collateral",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "debt",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 7,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::asset_id::AssetId",
      "metadataTypeId": 8,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 9,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct sway_libs::ownership::events::OwnershipRenounced",
      "metadataTypeId": 10,
      "components": [
        {
          "name": "previous_owner",
          "typeId": 2
        }
      ]
    },
    {
      "type": "struct sway_libs::ownership::events::OwnershipSet",
      "metadataTypeId": 11,
      "components": [
        {
          "name": "new_owner",
          "typeId": 2
        }
      ]
    },
    {
      "type": "struct sway_libs::ownership::events::OwnershipTransferred",
      "metadataTypeId": 12,
      "components": [
        {
          "name": "new_owner",
          "typeId": 2
        },
        {
          "name": "previous_owner",
          "typeId": 2
        }
      ]
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        },
        {
          "name": "trove_manager_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "oracle_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "add_asset",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "upper_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "lower_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "add_coll",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "asset",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "claim_collateral",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "close_trove",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_is_paused",
      "output": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_pauser",
      "output": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_usdf_asset_id",
      "output": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "usdf_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "fpt_staking_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "protocol_manager",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "coll_surplus_pool_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "active_pool_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "sorted_troves_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        }
      ],
      "name": "initialize",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "usdf_amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "upper_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "lower_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "open_trove",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [],
      "name": "renounce_owner",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "upper_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "lower_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "repay_usdf",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "is_paused",
          "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
        }
      ],
      "name": "set_pause_status",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "pauser",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "set_pauser",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "new_owner",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "transfer_owner",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "upper_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "lower_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "withdraw_coll",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "upper_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "lower_hint",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "asset_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        }
      ],
      "name": "withdraw_usdf",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "10098701174489624218",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "logId": "6739255540342412543",
      "concreteTypeId": "5d86a53534b154ff98732e6057dc10eec4dd6751988367290dba6324dbe44de5"
    },
    {
      "logId": "2361378587801497692",
      "concreteTypeId": "20c54d50821f305c7bded39faabe41cdf0fa398991ae6968969e24e6bef995aa"
    },
    {
      "logId": "2161305517876418151",
      "concreteTypeId": "1dfe7feadc1d9667a4351761230f948744068a090fe91b1bc6763a90ed5d3893"
    },
    {
      "logId": "16280289466020123285",
      "concreteTypeId": "e1ef35033ea9d2956f17c3292dea4a46ce7d61fdf37bbebe03b7b965073f43b5"
    },
    {
      "logId": "17081261052530365109",
      "concreteTypeId": "ed0cd46d47c992b590c2ab4626cc9272d1bcbc071e91e6571b191f6e01f51ea6"
    },
    {
      "logId": "4571204900286667806",
      "concreteTypeId": "3f702ea3351c9c1ece2b84048006c8034a24cbc2bad2e740d0412b4172951d3d"
    },
    {
      "logId": "4883303303013154842",
      "concreteTypeId": "43c4fa7b3297401afbf300127e59ea913e5c8f0c7ae69abbec789ab0bb872bed"
    },
    {
      "logId": "12970362301975156672",
      "concreteTypeId": "b3fffbcb3158d7c010c31b194b60fb7857adb4ad61bdcf4b8b42958951d9f308"
    }
  ],
  "messagesTypes": [],
  "configurables": [
    {
      "name": "INITIALIZER",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "offset": 70456
    }
  ]
};

const storageSlots: StorageSlot[] = [
  {
    "key": "02cc116a7843dc97261c8bfea75078719db8b85506939ef0c477d632e3bb307f",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2aa95707a42f70b4725e4552a7188e45ac9ed6175dafb119b55b2abc7e96247d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2b1353bd3957665c0d731dcc29c245f50c99e1256a252a98fbcebc1c642348f4",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "38a1b038206f348ec947ba5770c6d713148c9d8bb0b312f47acc88777da1c09d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "43de1d1ba52ad631c9976519ca580ac839503f1189d6c232abb81d2724b13d82",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "82364c88cfc6f6d7a89bb2c2a21170eb8c7bfebc3fe4a45221ce35a05295e4ad",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "8acc4ae8995b85711fe6b544b21f80799f86f33621bd1cfebdbf42e9feb958e0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "950e5b0363693fc1167865ef17cd457443e4546f7f9bfc7b7bea1e7fc320404b",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cc4aebd93a7a806b3ea1a19b18d3ba721807d66771b1c2696f03c15fb84e1fd0",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cf227e666ed3074e15eeb2923304091f467211295519917b0ba3f1dfd12c6207",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "dd6f6572f63d1dd02a85ade83ac810de142d03eeaeab3ae057e4568f1690e946",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "dd6f6572f63d1dd02a85ade83ac810de142d03eeaeab3ae057e4568f1690e947",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "ef2903cc032f2abc91fea2c0bb6c9554d7a8f949169dfb5adc8009cb4796f8a8",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class BorrowOperationsContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    add_asset: FunctionFragment;
    add_coll: FunctionFragment;
    claim_collateral: FunctionFragment;
    close_trove: FunctionFragment;
    get_is_paused: FunctionFragment;
    get_pauser: FunctionFragment;
    get_usdf_asset_id: FunctionFragment;
    initialize: FunctionFragment;
    open_trove: FunctionFragment;
    renounce_owner: FunctionFragment;
    repay_usdf: FunctionFragment;
    set_pause_status: FunctionFragment;
    set_pauser: FunctionFragment;
    transfer_owner: FunctionFragment;
    withdraw_coll: FunctionFragment;
    withdraw_usdf: FunctionFragment;
  };
}

export class BorrowOperationsContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: BorrowOperationsContractInterface;
  declare functions: {
    add_asset: InvokeFunction<[asset_contract: AssetIdInput, trove_manager_contract: ContractIdInput, oracle_contract: ContractIdInput], void>;
    add_coll: InvokeFunction<[upper_hint: IdentityInput, lower_hint: IdentityInput], void>;
    claim_collateral: InvokeFunction<[asset: AssetIdInput], void>;
    close_trove: InvokeFunction<[asset_contract: AssetIdInput], void>;
    get_is_paused: InvokeFunction<[], boolean>;
    get_pauser: InvokeFunction<[], IdentityOutput>;
    get_usdf_asset_id: InvokeFunction<[], AssetIdOutput>;
    initialize: InvokeFunction<[usdf_contract: ContractIdInput, fpt_staking_contract: ContractIdInput, protocol_manager: ContractIdInput, coll_surplus_pool_contract: ContractIdInput, active_pool_contract: ContractIdInput, sorted_troves_contract: ContractIdInput], void>;
    open_trove: InvokeFunction<[usdf_amount: BigNumberish, upper_hint: IdentityInput, lower_hint: IdentityInput], void>;
    renounce_owner: InvokeFunction<[], void>;
    repay_usdf: InvokeFunction<[upper_hint: IdentityInput, lower_hint: IdentityInput, asset_contract: AssetIdInput], void>;
    set_pause_status: InvokeFunction<[is_paused: boolean], void>;
    set_pauser: InvokeFunction<[pauser: IdentityInput], void>;
    transfer_owner: InvokeFunction<[new_owner: IdentityInput], void>;
    withdraw_coll: InvokeFunction<[amount: BigNumberish, upper_hint: IdentityInput, lower_hint: IdentityInput, asset_contract: AssetIdInput], void>;
    withdraw_usdf: InvokeFunction<[amount: BigNumberish, upper_hint: IdentityInput, lower_hint: IdentityInput, asset_contract: AssetIdInput], void>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}

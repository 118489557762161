import { Grid, GridItem, Spacer, Text, VStack } from "@chakra-ui/react";

import { RiskyTrovesCard } from "../components/earn/RiskyTrovesCard";
import { StabilityPoolCard } from "../components/earn/StabilityPoolCard";
import { PageWrapper } from "../components/common/PageWrapper";
import { ConnectWalletButton } from "../components/common/ConnectWalletButton";
import { useWallet } from "../hooks/useWallet";

export const RiskyTroves = () => {
  const { account } = useWallet();

  return (
    <PageWrapper>
      {!account ? (
        <VStack alignSelf={"center"} spacing={8} minW={["", "", "", "500px"]}>
          <Text>Please connect your wallet to view the Stability Pool.</Text>
          <ConnectWalletButton />
        </VStack>
      ) : (
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          justifyContent={"flex-start"}
        >
          <GridItem colSpan={[3, 3, 3, 2]} order={[2, 2, 2, 1]}>
            <RiskyTrovesCard />
          </GridItem>
          <GridItem colSpan={[3, 3, 3, 1]} order={[1, 1, 1, 2]}>
            <StabilityPoolCard />
          </GridItem>
        </Grid>
      )}

      <Spacer />
    </PageWrapper>
  );
};

import { Box, Flex, Text, IconButton } from "@chakra-ui/react";
import { FaGithub, FaDiscord, FaBook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export function Footer() {
  return (
    <Box color="white">
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        p={4}
      >
        <Box flex="1 1 100%" mb={{ base: 4, md: 0 }}>
          <Flex justifyContent="center">
            <IconButton
              as="a"
              href="https://twitter.com/Fluid_Protocol"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
              icon={<FaXTwitter />}
              size="lg"
              variant="ghost"
              mx={2}
            />
            <IconButton
              as="a"
              href="https://github.com/Hydrogen-Labs"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub"
              icon={<FaGithub />}
              size="lg"
              variant="ghost"
              mx={2}
            />

            <IconButton
              as="a"
              href="https://discord.gg/PEQJZcPX8u"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Discord"
              icon={<FaDiscord />}
              size="lg"
              variant="ghost"
              mx={2}
            />
            <IconButton
              as="a"
              href="https://docs.hydrogenlabs.xyz/fluid-protocol-community"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Gitbook"
              icon={<FaBook />}
              size="lg"
              variant="ghost"
              mx={2}
            />
          </Flex>
          <Flex justifyContent="center" alignItems="center" gap={2}>
            <Text fontSize="sm">
              © 2024 Fluid Protocol. All rights reserved.
            </Text>
            <Text fontSize="sm" color="gray.400">
              |
            </Text>
            <Text
              as="a"
              href="/privacy-policy"
              fontSize="sm"
              color="gray.200"
              _hover={{ color: "white", textDecoration: "underline" }}
            >
              Privacy Policy
            </Text>
            <Text fontSize="sm" color="gray.400">
              |
            </Text>
            <Text
              as="a"
              href="/terms-of-service"
              fontSize="sm"
              color="gray.200"
              _hover={{ color: "white", textDecoration: "underline" }}
            >
              Terms of Service
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

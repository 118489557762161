import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  HStack,
  Image,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Account, Address, BN } from "fuels";
import { TokenContract } from "../../types/token-contract";
import { useFluid } from "../../hooks/FluidProvider";
import { useWallet } from "../../hooks/useWallet";
import { useFuel } from "@fuels/react";
import { PRECISION } from "../../shared/format";
import { WalletAssets } from "./wallet-assets";

export const MintTestAssets = () => {
  const { account } = useWallet();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { reloadData, contracts } = useFluid();
  const { fuel } = useFuel();
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  async function addAssets() {
    const assets = await fuel.assets();
    setAlreadyAdded(true);
    let containsUSDF = false;
    let containsfETH = false;
    let containsstETH = false;
    let containsFPT = false;

    if (assets) {
      for (let i = 0; i < assets.length; i++) {
        if (assets[i].name === contracts.UsdfAssetId) {
          containsUSDF = true;
        }
        if (assets[i].name === contracts.assets[0].assetId) {
          containsfETH = true;
        }
        if (assets[i].name === contracts.assets[1].assetId) {
          containsstETH = true;
        }
        if (assets[i].name === contracts.fptAssetId) {
          containsFPT = true;
        }
      }
    }

    if (!containsUSDF || !containsfETH || !containsstETH || !containsFPT) {
      await fuel.addAssets(WalletAssets);
    }
  }

  async function mintAsset(wallet: Account, index: number, symbol: string) {
    setLoading(true);
    try {
      if (!alreadyAdded) {
        await addAssets();
      }
    } catch (e: any) {
      console.log("Error adding assets", e.message);
    }

    let contractAddress = new Address(
      contracts.assets[index].asset_contract
    ).toB256();
    const contract = new TokenContract(contractAddress, wallet);

    let myAddress = {
      bits: wallet.address.toB256(),
    };
    const identityInput = {
      Address: myAddress,
    };

    try {
      await contract.functions
        .mint_to_id(new BN(5 * PRECISION), identityInput)
        .txParams({ tip: 1, gasLimit: 500000, variableOutputs: 1 })
        .call()
        .then(() => {
          toast({
            title: `5 ${symbol} minted!`,
            description: "You can now use them to test the app!",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          //   wait 1.5 seconds before reloading data
          setTimeout(() => {
            reloadData();
          }, 1500);
        })
        .catch((e: any) => {
          toast({
            title: "Error",
            description: e.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        });
    } catch (e: any) {
      toast({
        title: "Error",
        description: e.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    setLoading(false);
  }
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        isLoading={loading}
      >
        Mint Test Assets
      </MenuButton>
      <MenuList backgroundColor={"bgLightGrey"}>
        {contracts.assets.map((asset, index) => (
          <MenuItem
            key={asset.assetId}
            backgroundColor={"bgLightGrey"}
            _hover={{
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            }}
            onClick={async () => {
              if (account) {
                const wallet = await fuel.getWallet(account);
                await mintAsset(wallet, index, asset.assetSymbol);
              }
            }}
            justifyContent={"space-between"}
          >
            <HStack spacing={3}>
              <Image
                borderRadius="full"
                src={asset.imageUrl}
                alt={asset.assetSymbol}
                boxSize="24px"
              />
              <Text fontSize="sm" fontWeight="bold">
                {asset.assetSymbol}
              </Text>
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

import {
  Text,
  VStack,
  Button,
  HStack,
  Card,
  CardBody,
  Divider,
  Container,
  Tooltip,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Link,
  Image,
} from "@chakra-ui/react";
import { Address, BN } from "fuels";
import { useEffect, useState } from "react";
import { useFluid } from "../../hooks/FluidProvider";
import {
  formatBNtoHumanReadable,
  parseBN,
  PRECISION,
} from "../../shared/format";
import { CommunityIssuanceContract } from "../../types/community-issuance-contract";
import { UsdfTokenContract } from "../../types/usdf-token-contract";
import {
  IdentityInput,
  StabilityPoolContract,
} from "../../types/stability-pool-contract/StabilityPoolContract";
import { FaInfoCircle } from "react-icons/fa";
import { css } from "@emotion/react";
import { OracleContract } from "../../types/oracle-contract";
import { TroveManagerContract } from "../../types/trove-manager-contract";
import { MockPythContract } from "../../types/mock-pyth-contract";
import { SortedTrovesContract } from "../../types/sorted-troves-contract";
import FuelLogo from "../../images/fuel-logo.svg";

export const StabilityPoolCard = () => {
  const [depositAmount, setDepositAmount] = useState<BN>(new BN(0));
  const [depositAmountText, setDepositAmountText] = useState<string>("");
  const [withdrawAmount, setWithdrawAmount] = useState<BN>(new BN(0));
  const [withdrawAmountText, setWithdrawAmountText] = useState<string>("");
  const [compoundedAmount, setCompoundedAmount] = useState<BN>(new BN(0));
  const [fptGain, setFptGain] = useState<BN>(new BN(0));
  const [remainingFpt, setRemainingFpt] = useState<BN>(new BN(0));
  const [apr, setApr] = useState<BN>(new BN(0));
  const [totalUSDFinPool, settotalUSDFinPoolinPool] = useState<BN>(new BN(0));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);
  const [stabilityPoolContract, setStabilityPoolContract] =
    useState<StabilityPoolContract | null>(null);
  const [earnedCollateral, setEarnedCollateral] = useState<{
    [key: string]: BN;
  }>({});
  const toast = useToast();
  const {
    usdfBalance,
    address,
    assets,
    reloadData,
    contracts,
    fptPrice,
    wallet,
    sortedTrovesContract,
    activePoolContract,
    fptStakingContract,
  } = useFluid();

  const [isClaimingRewards, setIsClaimingRewards] = useState<boolean>(false);

  const hoverStyle = css`
    &:hover {
      border: 2px solid rgba(0, 255, 0, 0.4); // 40% opacity
      box-shadow: 0 0 50px rgba(0, 255, 0, 0.25);
      transition: box-shadow 0.25s ease-out 0s, border 0.25s ease-out 0s;
    }
  `;

  function isValidDeposit() {
    return depositAmount.gt(new BN(0)) && depositAmount.lte(usdfBalance);
  }

  function isValidWithdraw() {
    return withdrawAmount.gt(new BN(0)) && withdrawAmount.lte(compoundedAmount);
  }

  function hasPosition() {
    return compoundedAmount.gt(new BN(0));
  }

  function hasRewards() {
    return (
      fptGain.gt(new BN(0)) ||
      Object.entries(earnedCollateral).some(([_, amount]) =>
        amount.gt(new BN(0))
      )
    );
  }

  function calculateDailyFptIssuance(remainingFpt: BN): BN {
    const dailyIssuanceFraction = new BN((1 - 0.5 ** (1 / 365)) * PRECISION);
    return remainingFpt
      .mul(dailyIssuanceFraction)
      .div(new BN(PRECISION))
      .div(new BN(2)); // Half is issued to stability pool without transition period
  }

  useEffect(() => {
    if (fptPrice && stabilityPoolContract && totalUSDFinPool) {
      calculateFptAPR(fptPrice);
    }
  }, [fptPrice, stabilityPoolContract, totalUSDFinPool]);

  useEffect(() => {
    if (contracts && wallet) {
      setStabilityPoolContract(
        new StabilityPoolContract(contracts.stabilityPool, wallet)
      );
    }
  }, [contracts, wallet]);

  async function calculateFptAPR(fptPrice: BN) {
    if (stabilityPoolContract && totalUSDFinPool.gt(new BN(0))) {
      let communityIssuance = new CommunityIssuanceContract(
        new Address(contracts.communityIssuance).toB256(),
        wallet
      );
      const remainingFptInStabilityPool = (
        await communityIssuance.getBalance(contracts.fptAssetId)
      ).div(new BN(PRECISION));
      setRemainingFpt(remainingFptInStabilityPool);

      const fptIssuanceOneDay = calculateDailyFptIssuance(
        remainingFptInStabilityPool
      );
      const fptIssuanceOneDayInUSD = fptIssuanceOneDay.mul(fptPrice);
      const aprPercentage = fptIssuanceOneDayInUSD
        .mul(new BN(365))
        .mul(new BN(100))
        .div(totalUSDFinPool);
      setApr(aprPercentage);
    }
  }

  useEffect(() => {
    getStabilityPosition();
  }, [stabilityPoolContract]);

  async function getStabilityPosition() {
    if (stabilityPoolContract && wallet && address) {
      let myAddress = {
        bits: wallet.address.toB256(),
      };
      const identityInput: IdentityInput = {
        Address: myAddress,
      };
      try {
        stabilityPoolContract.functions
          .get_compounded_usdf_deposit(identityInput)
          .txParams({ tip: 1, gasLimit: 2000000 })
          .get()
          .then((res) => {
            setCompoundedAmount(res.value);
          });
      } catch (e: any) {
        setIsLoading(false);
      }
      try {
        // Fetch gains for all collateral assets in parallel
        const gainPromises = contracts.assets.map((collateral) =>
          stabilityPoolContract.functions
            .get_depositor_asset_gain(identityInput, {
              bits: collateral.assetId,
            })
            .txParams({ tip: 1, gasLimit: 2000000 })
            .get()
            .then((res) => ({
              symbol: collateral.assetSymbol,
              value: res.value,
            }))
        );

        const gains = await Promise.all(gainPromises);
        const collateralGains = gains.reduce(
          (acc, { symbol, value }) => ({
            ...acc,
            [symbol]: value,
          }),
          {}
        );

        setEarnedCollateral(collateralGains);
      } catch (e: any) {
        setIsLoading(false);
      }
      try {
        stabilityPoolContract.functions
          .get_depositor_fpt_gain(identityInput)
          .txParams({ tip: 1, gasLimit: 2000000 })
          .get()
          .then((res) => {
            setFptGain(res.value);
          });
      } catch (e: any) {
        setIsLoading(false);
      }
      try {
        stabilityPoolContract.functions
          .get_total_usdf_deposits()
          .txParams({ tip: 1, gasLimit: 2000000, variableOutputs: 3 })
          .get()
          .then((res) => {
            settotalUSDFinPoolinPool(res.value);
          });
      } catch (e: any) {
        setIsLoading(false);
      }
    }
  }

  async function withdrawFromStabilityPool() {
    if (
      stabilityPoolContract &&
      wallet &&
      sortedTrovesContract &&
      activePoolContract &&
      fptStakingContract
    ) {
      if (withdrawAmount.eq(new BN(0))) {
        setIsClaimingRewards(true);
      } else {
        setIsWithdrawing(true);
      }

      // Initialize USDF contract
      let usdf = new UsdfTokenContract(
        new Address(contracts.Usdf).toB256(),
        wallet
      );

      // Initialize community issuance contract
      let communityIssuance = new CommunityIssuanceContract(
        new Address(contracts.communityIssuance).toB256(),
        wallet
      );

      let stabilityPoolImplementation = new StabilityPoolContract(
        contracts.stabilityPoolImplementation,
        wallet
      );

      let usdfImplementation = new UsdfTokenContract(
        contracts.UsdfImplementation,
        wallet
      );

      let communityIssuanceImplementation = new CommunityIssuanceContract(
        contracts.communityIssuanceImplementation,
        wallet
      );

      let sortedTrovesImplementation = new SortedTrovesContract(
        contracts.sortedTrovesImplementation,
        wallet
      );

      // Get all Pyth contracts from assets
      const pythContracts = assets
        .map((asset) => {
          if (!asset.pythContract) {
            console.log(`Missing Pyth contract for asset: ${asset.symbol}`);
            return new MockPythContract(asset.contractIds.pythContract, wallet);
          }
          return asset.pythContract;
        })
        .filter((contract) => contract !== undefined);

      const oracleContracts = assets
        .map((asset) => {
          if (!asset.oracleContract) {
            console.log(`Missing Oracle contract for asset: ${asset.symbol}`);
            return new OracleContract(asset.contractIds.oracle, wallet);
          }
          return asset.oracleContract;
        })
        .filter((contract) => contract !== undefined);

      const oracleImplementations = assets.map((asset) => {
        if (!asset.oracleImplementation) {
          console.log(
            `Missing Oracle implementation for asset: ${asset.symbol}`
          );
          return new OracleContract(
            asset.contractIds.oracleImplementation,
            wallet
          );
        }
        return new OracleContract(
          asset.contractIds.oracleImplementation,
          wallet
        );
      });

      const troveManagerContracts = assets
        .map((asset) => {
          if (!asset.troveManagerContract) {
            console.log(
              `Missing Trove Manager contract for asset: ${asset.symbol}`
            );
            return new TroveManagerContract(
              asset.contractIds.troveManager,
              wallet
            );
          }
          return asset.troveManagerContract;
        })
        .filter((contract) => contract !== undefined);

      try {
        const result = await stabilityPoolContract.functions
          .withdraw_from_stability_pool(withdrawAmount)
          .addContracts([
            stabilityPoolContract,
            stabilityPoolImplementation,
            usdf,
            usdfImplementation,
            communityIssuance,
            communityIssuanceImplementation,
            fptStakingContract,
            sortedTrovesContract,
            sortedTrovesImplementation,
            activePoolContract,
            ...pythContracts,
            ...oracleContracts,
            ...troveManagerContracts,
            ...oracleImplementations,
          ])
          .txParams({
            gasLimit: 4500000,
            variableOutputs: 3,
          })
          .call();

        console.log(result);

        toast({
          title: "Transaction Successful",
          description: withdrawAmount.eq(new BN(0))
            ? "You have successfully claimed your rewards!"
            : "You have successfully withdrawn from the stability pool!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        setTimeout(() => {
          reloadData(undefined, true);
          setWithdrawAmount(new BN(0));
          setWithdrawAmountText("");
          getStabilityPosition();
          setIsWithdrawing(false);
          setIsClaimingRewards(false);
        }, 1000);
      } catch (e: any) {
        console.log(e);
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsWithdrawing(false);
        setIsClaimingRewards(false);
      }
    } else {
      console.log("No wallet or contracts");
    }
  }

  async function depositToStabilityPool() {
    if (stabilityPoolContract && wallet) {
      setIsLoading(true);

      let usdfAssetId = contracts.UsdfAssetId;

      let communityIssuanceAddress = new Address(
        contracts.communityIssuance
      ).toB256();
      let communityIssuance = new CommunityIssuanceContract(
        communityIssuanceAddress,
        wallet
      );

      try {
        stabilityPoolContract.functions
          .provide_to_stability_pool()
          .addContracts([communityIssuance])
          .callParams({
            forward: {
              amount: depositAmount,
              assetId: usdfAssetId,
            },
          })
          .txParams({ tip: 1, gasLimit: 2000000, variableOutputs: 3 })
          .call()
          .then(() => {
            toast({
              title: "Transaction Successful",
              description:
                "You have successfully deposited to the stability pool!",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            // wait for 1 second before reloading data
            setTimeout(() => {
              setDepositAmount(new BN(0));
              setDepositAmountText("");
              reloadData(undefined, true);
              getStabilityPosition();
              setIsLoading(false);
            }, 1000);
          })
          .catch((e: any) => {
            toast({
              title: "Error",
              description: e.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            setIsLoading(false);
          });
      } catch (e: any) {
        setIsLoading(false);

        return;
      }
    }
  }

  return (
    <Card
      variant={"darkCard"}
      fontFamily={"IBM Plex Mono"}
      fontWeight={"bold"}
      css={hoverStyle}
      border={"2px solid transparent"}
      transition={"all 0.3s ease"}
    >
      <CardBody w="100%" alignItems={"center"}>
        <VStack w={"100%"} gap={4}>
          <Text
            fontWeight={"semibold"}
            alignSelf={"start"}
            fontFamily={"IBM Plex Mono"}
            fontStyle={"bold"}
            display={"flex"}
            flexDir={"row"}
          >
            Stability Pool
          </Text>
          <Link
            style={{
              marginTop: "-26px",
              alignSelf: "end",
              fontSize: "16px",
              display: "flex",
              lineHeight: "16px",
              fontWeight: "100",
            }}
            href="https://docs.hydrogenlabs.xyz/fluid-protocol-community/protocol-design/liquidation-and-stability-pool"
            isExternal
          >
            <Text>Learn more</Text>
            <FaInfoCircle style={{ marginLeft: "5px" }} />
          </Link>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            pb={4}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <HStack w="100%" alignItems="center" justifyContent="center">
                <Text>APR:</Text>
                {apr && apr.gt(new BN(0)) ? (
                  <>
                    <Tooltip
                      label={`APR is calculated based on daily FPT rewards (${formatBNtoHumanReadable(
                        calculateDailyFptIssuance(remainingFpt),
                        0,
                        2
                      )} FPT/day) distributed to the stability pool. This does not include Fuel points based on trove deposits or gains from liquidations.`}
                      bg="textSecondary"
                      color="black"
                      hasArrow
                    >
                      <Text>{apr.toString()}%</Text>
                    </Tooltip>
                  </>
                ) : (
                  <Text>Loading...</Text>
                )}

              </HStack>

              <Text fontSize={"md"} textAlign={"left"}>
                Deposit USDF in the stability pool to earn{" "}
                {assets
                  .filter((asset) => !asset.contractIds.disabled)
                  .map((asset) => asset.symbol)
                  .join(", ")}{" "}
                and FPT rewards.
              </Text>
              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Text color={"textSecondary"}>Total USDF in Pool</Text>
                <Text pr={2}>{parseBN(totalUSDFinPool, 9) + " USDF"}</Text>
              </HStack>
              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Text color={"textSecondary"}>FPT Remaining</Text>
                <Text pr={2}>
                  {formatBNtoHumanReadable(remainingFpt, 0)} FPT
                </Text>
              </HStack>
            </VStack>
          </Container>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            pb={4}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text>{hasPosition() ? "Your Position" : "Deposit USDF"}</Text>
              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Text color={"textSecondary"}>USDF in Wallet</Text>
                <Text pr={2}> {parseBN(usdfBalance, 9) + " USDF"}</Text>
              </HStack>

              <Tooltip bg="textSecondary" color="black" hasArrow>
                <VStack w="100%">
                  <InputGroup size="md">
                    <Input
                      backgroundColor={"blackAlpha.300"}
                      textAlign={"right"}
                      placeholder="200 USDF"
                      color={"textPrimary"}
                      fontWeight={"bold"}
                      fontSize={"xl"}
                      value={depositAmountText}
                      onChange={(e) => {
                        setDepositAmountText(e.target.value);

                        const inputValue = e.target.value.trim();
                        const isValidNumber = /^-?\d*(\.\d+)?$/.test(
                          inputValue
                        );
                        if (isValidNumber) {
                          const parsedValue = parseFloat(inputValue);
                          setDepositAmount(new BN(parsedValue * PRECISION));
                        }
                      }}
                    />
                    <InputLeftElement width="4.5rem">
                      <Button
                        backgroundColor={"bgDarkGrey"}
                        color={"textPrimary"}
                        h="1.75rem"
                        size="xs"
                        px={4}
                        onClick={() => {
                          setDepositAmount(usdfBalance);
                          setDepositAmountText(parseBN(usdfBalance, 9));
                        }}
                      >
                        MAX
                      </Button>
                    </InputLeftElement>
                  </InputGroup>

                  <Button
                    onClick={depositToStabilityPool}
                    colorScheme={"green"}
                    w={"100%"}
                    isDisabled={!isValidDeposit()}
                    isLoading={isLoading}
                  >
                    Deposit
                  </Button>
                </VStack>
              </Tooltip>

              {hasPosition() && (
                <>
                  <Divider />
                  <HStack
                    fontSize={"md"}
                    w={"100%"}
                    justifyContent={"space-between"}
                  >
                    <Text color={"textSecondary"}>USDF Deposited</Text>
                    <Text pr={2}>
                      {" "}
                      {parseBN(compoundedAmount, 9) + " USDF"}
                    </Text>
                  </HStack>
                  <HStack
                    fontSize={"md"}
                    w={"100%"}
                    justifyContent={"space-between"}
                  >
                    <Text color={"textSecondary"}>FPT Earned</Text>
                    <Text pr={2}> {parseBN(fptGain, 9) + " FPT"}</Text>
                  </HStack>
                  {Object.entries(earnedCollateral)
                    .filter(([symbol]) =>
                      assets.some(
                        (asset) =>
                          asset.symbol === symbol && !asset.contractIds.disabled
                      )
                    )
                    .map(([symbol, amount]) => (
                      <HStack
                        key={symbol}
                        fontSize={"md"}
                        w={"100%"}
                        justifyContent={"space-between"}
                      >
                        <Text color={"textSecondary"}>{symbol} Earned</Text>
                        <Text pr={2}> {parseBN(amount, 9) + ` ${symbol}`}</Text>
                      </HStack>
                    ))}

                  {hasRewards() && (
                    <>
                      <Button
                        onClick={() => {
                          setWithdrawAmount(new BN(0));
                          withdrawFromStabilityPool();
                        }}
                        backgroundColor={"bgDarkGrey"}
                        w={"100%"}
                        isLoading={isClaimingRewards}
                      >
                        Claim Rewards
                      </Button>
                      <Divider />
                    </>
                  )}

                  <Tooltip bg="textSecondary" color="black" hasArrow>
                    <VStack w="100%">
                      <InputGroup size="md">
                        <Input
                          backgroundColor={"blackAlpha.300"}
                          textAlign={"right"}
                          placeholder="200 USDF"
                          color={"textPrimary"}
                          fontWeight={"bold"}
                          fontSize={"xl"}
                          isDisabled={address !== null ? false : true}
                          isInvalid={
                            !isValidWithdraw() && withdrawAmountText !== ""
                          }
                          value={withdrawAmountText}
                          onChange={(e) => {
                            setWithdrawAmountText(e.target.value);

                            const inputValue = e.target.value.trim();
                            const isValidNumber = /^-?\d*(\.\d+)?$/.test(
                              inputValue
                            );
                            if (isValidNumber) {
                              const parsedValue = parseFloat(inputValue);
                              setWithdrawAmount(
                                new BN(parsedValue * PRECISION)
                              );
                            }
                          }}
                        />
                        <InputLeftElement width="4.5rem">
                          <Button
                            backgroundColor={"bgDarkGrey"}
                            color={"textPrimary"}
                            h="1.75rem"
                            size="xs"
                            px={4}
                            onClick={() => {
                              setWithdrawAmount(compoundedAmount);
                              setWithdrawAmountText(
                                parseBN(compoundedAmount, 9)
                              );
                            }}
                          >
                            MAX
                          </Button>
                        </InputLeftElement>
                      </InputGroup>

                      <Button
                        onClick={withdrawFromStabilityPool}
                        backgroundColor={"bgDarkGrey"}
                        w={"100%"}
                        isDisabled={!isValidWithdraw()}
                        isLoading={isWithdrawing}
                      >
                        Withdraw
                      </Button>
                    </VStack>
                  </Tooltip>
                </>
              )}
            </VStack>
          </Container>
        </VStack>
      </CardBody >
    </Card >
  );
};

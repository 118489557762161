/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.0
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
  StrSlice,
} from 'fuels';

import type { Enum } from "./common";

export enum AccessErrorInput { NotOwner = 'NotOwner' };
export enum AccessErrorOutput { NotOwner = 'NotOwner' };
export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;
export enum InitializationErrorInput { CannotReinitialized = 'CannotReinitialized' };
export enum InitializationErrorOutput { CannotReinitialized = 'CannotReinitialized' };
export type StateInput = Enum<{ Uninitialized: undefined, Initialized: IdentityInput, Revoked: undefined }>;
export type StateOutput = Enum<{ Uninitialized: void, Initialized: IdentityOutput, Revoked: void }>;

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type AssetIdInput = { bits: string };
export type AssetIdOutput = AssetIdInput;
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;
export type OwnershipSetInput = { new_owner: IdentityInput };
export type OwnershipSetOutput = { new_owner: IdentityOutput };

export type CommunityIssuanceContractConfigurables = Partial<{
  INITIALIZER: IdentityInput;
}>;

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "bool",
      "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
    },
    {
      "type": "enum standards::src5::AccessError",
      "concreteTypeId": "3f702ea3351c9c1ece2b84048006c8034a24cbc2bad2e740d0412b4172951d3d",
      "metadataTypeId": 1
    },
    {
      "type": "enum standards::src5::State",
      "concreteTypeId": "192bc7098e2fe60635a9918afb563e4e5419d386da2bdbf0d716b4bc8549802c",
      "metadataTypeId": 2
    },
    {
      "type": "enum std::identity::Identity",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "metadataTypeId": 3
    },
    {
      "type": "enum sway_libs::ownership::errors::InitializationError",
      "concreteTypeId": "1dfe7feadc1d9667a4351761230f948744068a090fe91b1bc6763a90ed5d3893",
      "metadataTypeId": 4
    },
    {
      "type": "str",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "type": "struct std::asset_id::AssetId",
      "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
      "metadataTypeId": 6
    },
    {
      "type": "struct std::contract_id::ContractId",
      "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54",
      "metadataTypeId": 7
    },
    {
      "type": "struct sway_libs::ownership::events::OwnershipSet",
      "concreteTypeId": "e1ef35033ea9d2956f17c3292dea4a46ce7d61fdf37bbebe03b7b965073f43b5",
      "metadataTypeId": 8
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    }
  ],
  "metadataTypes": [
    {
      "type": "b256",
      "metadataTypeId": 0
    },
    {
      "type": "enum standards::src5::AccessError",
      "metadataTypeId": 1,
      "components": [
        {
          "name": "NotOwner",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "enum standards::src5::State",
      "metadataTypeId": 2,
      "components": [
        {
          "name": "Uninitialized",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        },
        {
          "name": "Initialized",
          "typeId": 3
        },
        {
          "name": "Revoked",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 3,
      "components": [
        {
          "name": "Address",
          "typeId": 5
        },
        {
          "name": "ContractId",
          "typeId": 7
        }
      ]
    },
    {
      "type": "enum sway_libs::ownership::errors::InitializationError",
      "metadataTypeId": 4,
      "components": [
        {
          "name": "CannotReinitialized",
          "typeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 5,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::asset_id::AssetId",
      "metadataTypeId": 6,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 7,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct sway_libs::ownership::events::OwnershipSet",
      "metadataTypeId": 8,
      "components": [
        {
          "name": "new_owner",
          "typeId": 3
        }
      ]
    }
  ],
  "functions": [
    {
      "inputs": [],
      "name": "get_current_time",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Retrieves the current timestamp used by the contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Returns the debug timestamp if debugging is enabled, otherwise returns the current block timestamp"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @return The current timestamp as a u64 value"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "stability_pool_contract",
          "concreteTypeId": "29c10735d33b5159f0c71ee1dbd17b36a3e69e41f00fab0d42e1bd9f428d8a54"
        },
        {
          "name": "fpt_token_contract",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        },
        {
          "name": "admin",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "debugging",
          "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
        }
      ],
      "name": "initialize",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Initializes the Community Issuance contract with essential parameters"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can only be called once, sets up the contract for FPT token issuance"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param stability_pool_contract The address of the Stability Pool contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param fpt_token_contract The asset ID of the FPT token"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param admin The address of the contract administrator"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param debugging A boolean flag to enable or disable debug mode"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:throws \"CommunityIssuance: Contract is already initialized\" if the contract has been previously initialized"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "issue_fpt",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Issues FPT tokens based on the current issuance schedule"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can only be called by the Stability Pool contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:access-control Stability Pool only"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @return The amount of FPT tokens issued in this call"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "public_start_rewards_increase_transition_after_deadline",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Allows public initiation of rewards increase transition after a period of inactivity"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can be called by anyone after 1 year of inactivity since deployment"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:throws \"CommunityIssuance: Rewards have already transitioned\" if transition has already occurred"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:throws \"CommunityIssuance: Rewards can only be publicly increased after 1 year of inactivity\" if called before 1 year has passed"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "write",
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "account",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        },
        {
          "name": "amount",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "send_fpt",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Sends FPT tokens to a specified account"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can only be called by the Stability Pool contract"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param account The Identity of the account to receive the FPT tokens"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param amount The amount of FPT tokens to send"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:access-control Stability Pool only"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "time",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "set_current_time",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Sets the current time for debugging purposes"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev This function can only be called when debugging is enabled"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param time The timestamp to set as the current time"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:access-control Debug mode only"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:throws \"CommunityIssuance: Debugging must be enabled to set current time\" if debugging is not enabled"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "write",
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "total_transition_time_seconds",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "start_rewards_increase_transition",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "doc-comment",
          "arguments": [
            " @notice Initiates a transition period for increasing rewards"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @dev Can only be called by the admin and only once"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @param total_transition_time_seconds The duration of the transition period in seconds"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:throws \"CommunityIssuance: Rewards have already transitioned\" if transition has already occurred"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:throws \"CommunityIssuance: Total transition time must be greater than 1 week\" if transition time is too short"
          ]
        },
        {
          "name": "doc-comment",
          "arguments": [
            " @custom:access-control Admin only"
          ]
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "owner",
      "output": "192bc7098e2fe60635a9918afb563e4e5419d386da2bdbf0d716b4bc8549802c",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "10098701174489624218",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "logId": "2161305517876418151",
      "concreteTypeId": "1dfe7feadc1d9667a4351761230f948744068a090fe91b1bc6763a90ed5d3893"
    },
    {
      "logId": "16280289466020123285",
      "concreteTypeId": "e1ef35033ea9d2956f17c3292dea4a46ce7d61fdf37bbebe03b7b965073f43b5"
    },
    {
      "logId": "4571204900286667806",
      "concreteTypeId": "3f702ea3351c9c1ece2b84048006c8034a24cbc2bad2e740d0412b4172951d3d"
    }
  ],
  "messagesTypes": [],
  "configurables": [
    {
      "name": "INITIALIZER",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "offset": 33280
    }
  ]
};

const storageSlots: StorageSlot[] = [
  {
    "key": "0d83fc112bbd40ab067424e49b5553328952ec4d9aecf7293558c9eb587a4386",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "149465af6c44daacbf65695525602515464426a600032468b3553592af776160",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "1aaf6dfbee54188c1ff1477eec69d0ebc5a26204dcbbaef8a407322c1f65ac92",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "2236b8c9f4a76aec120c3785aded7237952d02a33085be4e6f9c2be26a730c8c",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "38a1b038206f348ec947ba5770c6d713148c9d8bb0b312f47acc88777da1c09d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "6769b4eb811dd1947ef58665029b20eedd6426ea56f362d08609a19bb589422a",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "96113b960922374414a5d983ed550ba527cf9ab442fb58cace4a7ac1180e4967",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "9e39cce573e16c4eb034ec33f6641ef797822070b45760ef0edafbb57a145b1d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cc5bf69931a9fba7deb4205a92fae41171ad8acc207c028e815dd033f5462cba",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "cd0031e9aadb10fa2fb019d9421055d5d8cda9a16889093e844448256be46c61",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class CommunityIssuanceContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    get_current_time: FunctionFragment;
    initialize: FunctionFragment;
    issue_fpt: FunctionFragment;
    public_start_rewards_increase_transition_after_deadline: FunctionFragment;
    send_fpt: FunctionFragment;
    set_current_time: FunctionFragment;
    start_rewards_increase_transition: FunctionFragment;
    owner: FunctionFragment;
  };
}

export class CommunityIssuanceContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: CommunityIssuanceContractInterface;
  declare functions: {
    get_current_time: InvokeFunction<[], BN>;
    initialize: InvokeFunction<[stability_pool_contract: ContractIdInput, fpt_token_contract: AssetIdInput, admin: IdentityInput, debugging: boolean], void>;
    issue_fpt: InvokeFunction<[], BN>;
    public_start_rewards_increase_transition_after_deadline: InvokeFunction<[], void>;
    send_fpt: InvokeFunction<[account: IdentityInput, amount: BigNumberish], void>;
    set_current_time: InvokeFunction<[time: BigNumberish], void>;
    start_rewards_increase_transition: InvokeFunction<[total_transition_time_seconds: BigNumberish], void>;
    owner: InvokeFunction<[], StateOutput>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}

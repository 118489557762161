import {
  Text,
  VStack,
  Button,
  HStack,
  Card,
  CardBody,
  Divider,
  Container,
  Tooltip,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { BN } from "fuels";
import { useEffect, useState } from "react";
import { useFluid } from "../../hooks/FluidProvider";
import { parseBN, PRECISION } from "../../shared/format";
import { FptStakingContract } from "../../types/fpt-staking-contract/FptStakingContract";

export const StakingCard = () => {
  const [stakeAmount, setStakeAmount] = useState<BN>(new BN(0));
  const [stakeAmountText, setStakeAmountText] = useState<string>("");
  const [unstakeAmount, setUnstakeAmount] = useState<BN>(new BN(0));
  const [unstakeAmountText, setUnstakeAmountText] = useState<string>("");
  const [stakedAmount, setStakedAmount] = useState<BN>(new BN(0));
  const [earnedUSDF, setEarnedUSDF] = useState<BN>(new BN(0));
  const [earnedCollateral, setEarnedCollateral] = useState<{
    [key: string]: BN;
  }>({});
  const [totalStakedFPT, setTotalStakedFPT] = useState<BN>(new BN(0));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUnstaking, setIsUnstaking] = useState<boolean>(false);
  const [fptStakingContract, setFptStakingContract] =
    useState<FptStakingContract | null>(null);
  const [isClaimingRewards, setIsClaimingRewards] = useState<boolean>(false);

  const toast = useToast();
  const { fptBalance, address, reloadData, contracts, isTestnet, wallet } =
    useFluid();

  function isValidStake() {
    return stakeAmount.gt(new BN(0)) && stakeAmount.lte(fptBalance);
  }

  function isValidUnstake() {
    return unstakeAmount.gt(new BN(0)) && unstakeAmount.lte(stakedAmount);
  }

  function hasStake() {
    return stakedAmount.gt(new BN(0));
  }

  function hasRewards() {
    return (
      earnedUSDF.gt(new BN(0)) ||
      Object.entries(earnedCollateral).some(([_, amount]) =>
        amount.gt(new BN(0))
      )
    );
  }

  useEffect(() => {
    getStakingPosition();
    getTotalStakedFPT();
  }, [fptStakingContract, fptBalance]);

  useEffect(() => {
    if (contracts && wallet) {
      setFptStakingContract(
        new FptStakingContract(contracts.fptStaking, wallet)
      );
    }
  }, [contracts, wallet]);

  async function getStakingPosition() {
    if (fptStakingContract && wallet) {
      try {
        const stakedFPT = await fptStakingContract.functions
          .get_staking_balance({ Address: { bits: wallet.address.toB256() } })
          .get();
        setStakedAmount(stakedFPT.value);

        const earnedRewards = await fptStakingContract.functions
          .get_pending_usdf_gain({ Address: { bits: wallet.address.toB256() } })
          .get();
        setEarnedUSDF(earnedRewards.value);

        // Fetch pending gains for all collateral assets in parallel
        const gainPromises = contracts.assets.map((collateral) =>
          fptStakingContract.functions
            .get_pending_asset_gain(
              { Address: { bits: wallet.address.toB256() } },
              { bits: collateral.assetId }
            )
            .get()
            .then((res) => ({
              symbol: collateral.assetSymbol,
              value: res.value,
            }))
        );

        const gains = await Promise.all(gainPromises);
        const collateralGains = gains.reduce(
          (acc, { symbol, value }) => ({
            ...acc,
            [symbol]: value,
          }),
          {}
        );

        setEarnedCollateral(collateralGains);
      } catch (e: any) {
        console.error("Error fetching staking position:", e);
      }
    }
  }

  async function unstakeFromFPTStaking() {
    if (fptStakingContract && address) {
      if (unstakeAmount.eq(new BN(0))) {
        setIsClaimingRewards(true);
      } else {
        setIsUnstaking(true);
      }

      try {
        await fptStakingContract.functions
          .unstake(unstakeAmount)
          .txParams({ gasLimit: 900000, variableOutputs: 3 })
          .call();

        toast({
          title: unstakeAmount.eq(new BN(0))
            ? "Claim Successful"
            : "Unstake Successful",
          description: unstakeAmount.eq(new BN(0))
            ? "You have successfully claimed your rewards!"
            : "You have successfully unstaked your FPT!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        setTimeout(() => {
          reloadData(undefined, true);
          setUnstakeAmount(new BN(0));
          setUnstakeAmountText("");
          getStakingPosition();
          setIsUnstaking(false);
          setIsClaimingRewards(false);
        }, 1300);
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsUnstaking(false);
        setIsClaimingRewards(false);
      }
    }
  }

  async function getTotalStakedFPT() {
    if (fptStakingContract) {
      const totalStakedFPT = await fptStakingContract.functions
        .get_storage()
        .get();
      setTotalStakedFPT(totalStakedFPT.value.total_fpt_staked);
    }
  }

  async function stakeToFPTStaking() {
    if (fptStakingContract && address) {
      setIsLoading(true);

      try {
        await fptStakingContract.functions
          .stake()
          .callParams({
            forward: {
              amount: stakeAmount,
              assetId: contracts.fptAssetId,
            },
          })
          .txParams({ gasLimit: 1000000, variableOutputs: 3 })
          .call();

        toast({
          title: "Stake Successful",
          description: "You have successfully staked your FPT!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        // wait for 1 second before reloading data
        setTimeout(() => {
          setStakeAmount(new BN(0));
          setStakeAmountText("");
          reloadData(undefined, true);
          getStakingPosition();
          setIsLoading(false);
        }, 1300);
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <Card
      variant={"darkCard"}
      fontFamily={"IBM Plex Mono"}
      fontWeight={"bold"}
      maxW="600px"
    >
      <CardBody w="100%" alignItems={"center"}>
        <VStack w={"100%"} gap={4}>
          <Text
            fontWeight={"semibold"}
            alignSelf={"start"}
            fontFamily={"IBM Plex Mono"}
            fontStyle={"bold"}
            display={"flex"}
            flexDir={"row"}
          >
            FPT Staking
          </Text>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            pb={4}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              {isTestnet === "testnet" && (
                <>
                  <VStack>
                    <Text>≈ 6.3% APR</Text>
                    <Text
                      color={"textSecondary"}
                      fontSize={"sm"}
                      mt={"0 !important"}
                    >
                      3% USDF + 3.3% Redemptions
                    </Text>
                  </VStack>
                  <Divider />
                </>
              )}
              <Text fontSize={"md"} textAlign={"left"}>
                Deposit FPT to earn{" "}
                {contracts.assets
                  .filter((asset) => !asset.disabled)
                  .map((asset) => asset.assetSymbol)
                  .join(", ")}{" "}
                and USDF rewards.
              </Text>

              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Text color={"textSecondary"}>Total FPT Staked</Text>
                <Text pr={2}>{parseBN(totalStakedFPT, 9) + " FPT"}</Text>
              </HStack>
            </VStack>
          </Container>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            pb={4}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text>{hasStake() ? "Your Position" : "Deposit FPT"}</Text>
              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Text color={"textSecondary"}>FPT in Wallet</Text>
                <Text pr={2}> {parseBN(fptBalance, 9) + " FPT"}</Text>
              </HStack>

              <Tooltip bg="textSecondary" color="black" hasArrow>
                <VStack w="100%">
                  <InputGroup size="md">
                    <Input
                      backgroundColor={"blackAlpha.300"}
                      textAlign={"right"}
                      placeholder="200 FPT"
                      color={"textPrimary"}
                      fontWeight={"bold"}
                      fontSize={"xl"}
                      isDisabled={address !== null ? false : true}
                      isInvalid={!isValidStake() && stakeAmountText !== ""}
                      value={stakeAmountText}
                      onChange={(e) => {
                        setStakeAmountText(e.target.value);

                        const inputValue = e.target.value.trim();
                        const isValidNumber = /^-?\d*(\.\d+)?$/.test(
                          inputValue
                        );
                        if (isValidNumber) {
                          const parsedValue = parseFloat(inputValue);
                          setStakeAmount(new BN(parsedValue * PRECISION));
                        }
                      }}
                    />
                    <InputLeftElement width="4.5rem">
                      <Button
                        backgroundColor={"bgDarkGrey"}
                        color={"textPrimary"}
                        h="1.75rem"
                        size="xs"
                        px={4}
                        onClick={() => {
                          setStakeAmount(fptBalance);
                          setStakeAmountText(parseBN(fptBalance, 9));
                        }}
                      >
                        MAX
                      </Button>
                    </InputLeftElement>
                  </InputGroup>

                  <Button
                    onClick={stakeToFPTStaking}
                    colorScheme={"green"}
                    w={"100%"}
                    isDisabled={!isValidStake()}
                    isLoading={isLoading}
                  >
                    Stake
                  </Button>
                </VStack>
              </Tooltip>

              {hasStake() && (
                <>
                  <Divider />
                  <HStack
                    fontSize={"md"}
                    w={"100%"}
                    justifyContent={"space-between"}
                  >
                    <Text color={"textSecondary"}>FPT Staked</Text>
                    <Text pr={2}> {parseBN(stakedAmount, 9) + " FPT"}</Text>
                  </HStack>
                  <Divider />

                  <HStack
                    fontSize={"md"}
                    w={"100%"}
                    justifyContent={"space-between"}
                  >
                    <Text color={"textSecondary"}>USDF Earned</Text>
                    <Text pr={2}> {parseBN(earnedUSDF, 9) + " USDF"}</Text>
                  </HStack>
                  {Object.entries(earnedCollateral)
                    .filter(([symbol]) =>
                      contracts.assets.some(
                        (asset) =>
                          asset.assetSymbol === symbol && !asset.disabled
                      )
                    )
                    .map(([symbol, amount]) => (
                      <HStack
                        key={symbol}
                        fontSize={"md"}
                        w={"100%"}
                        justifyContent={"space-between"}
                      >
                        <Text color={"textSecondary"}>{symbol} Earned</Text>
                        <Text pr={2}> {parseBN(amount, 9) + ` ${symbol}`}</Text>
                      </HStack>
                    ))}
                  {hasRewards() && (
                    <>
                      <Button
                        onClick={() => {
                          setUnstakeAmount(new BN(0));
                          unstakeFromFPTStaking();
                        }}
                        backgroundColor={"bgDarkGrey"}
                        w={"100%"}
                        isLoading={isClaimingRewards}
                      >
                        Claim Rewards
                      </Button>
                      <Divider />
                    </>
                  )}
                  <Tooltip bg="textSecondary" color="black" hasArrow>
                    <VStack w="100%">
                      <InputGroup size="md">
                        <Input
                          backgroundColor={"blackAlpha.300"}
                          textAlign={"right"}
                          placeholder="200 FPT"
                          color={"textPrimary"}
                          fontWeight={"bold"}
                          fontSize={"xl"}
                          isDisabled={address !== null ? false : true}
                          isInvalid={
                            !isValidUnstake() && unstakeAmountText !== ""
                          }
                          value={unstakeAmountText}
                          onChange={(e) => {
                            setUnstakeAmountText(e.target.value);

                            const inputValue = e.target.value.trim();
                            const isValidNumber = /^-?\d*(\.\d+)?$/.test(
                              inputValue
                            );
                            if (isValidNumber) {
                              const parsedValue = parseFloat(inputValue);
                              setUnstakeAmount(new BN(parsedValue * PRECISION));
                            }
                          }}
                        />
                        <InputLeftElement width="4.5rem">
                          <Button
                            backgroundColor={"bgDarkGrey"}
                            color={"textPrimary"}
                            h="1.75rem"
                            size="xs"
                            px={4}
                            onClick={() => {
                              setUnstakeAmount(stakedAmount);
                              setUnstakeAmountText(parseBN(stakedAmount, 9));
                            }}
                          >
                            MAX
                          </Button>
                        </InputLeftElement>
                      </InputGroup>

                      <Button
                        onClick={unstakeFromFPTStaking}
                        backgroundColor={"bgDarkGrey"}
                        w={"100%"}
                        isDisabled={!isValidUnstake()}
                        isLoading={isUnstaking}
                      >
                        Unstake
                      </Button>
                    </VStack>
                  </Tooltip>
                </>
              )}
            </VStack>
          </Container>
        </VStack>
      </CardBody>
    </Card>
  );
};

import { useToast } from "@chakra-ui/react";
import { useFluid } from "./FluidProvider";
import { useState } from "react";

export const useAdminOperations = () => {
  const { borrowOperationsContract, reloadData } = useFluid();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  async function setPauseStatus(isPaused: boolean) {
    if (borrowOperationsContract) {
      setIsLoading(true);
      try {
        await borrowOperationsContract.functions
          .set_pause_status(isPaused)
          .txParams({ gasLimit: 500000 })
          .call();

        toast({
          title: "Status Updated",
          description: `Contract has been ${
            isPaused ? "paused" : "unpaused"
          } successfully!`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        setTimeout(() => {
          reloadData();
          setIsLoading(false);
        }, 1000);
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
      }
    }
  }

  async function setPauser(address: string) {
    if (borrowOperationsContract) {
      setIsLoading(true);
      try {
        await borrowOperationsContract.functions
          .set_pauser({ Address: { bits: address } })
          .txParams({ gasLimit: 500000 })
          .call();

        toast({
          title: "Pauser Updated",
          description: "New pauser has been set successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });

        setTimeout(() => {
          reloadData();
          setIsLoading(false);
        }, 1000);
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
      }
    }
  }

  return {
    setPauseStatus,
    setPauser,
    isLoading,
  };
};

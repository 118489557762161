import {
  useAccount,
  useDisconnect,
  useIsConnected,
  useConnectUI,
  useBalance,
  useWallet as useFuelWallet,
  useCurrentConnector,
  useConnectors,
  useFuel,
} from "@fuels/react";
import { useEffect } from "react";
import Cookies from "js-cookie";

export const useWallet = () => {
  const {
    connect,
    isConnecting,
    isLoading: isLoadingConnectors,
  } = useConnectUI();

  const {
    account,
    isLoading: isLoadingAccount,
    isFetching: isFetchingAccount,
  } = useAccount();
  const { isLoading: isLoadingCurrentConnector, currentConnector } =
    useCurrentConnector();

  const { fuel } = useFuel();
  const { connectors } = useConnectors();

  const {
    balance,
    isLoading: isLoadingBalance,
    isFetching: isFetchingBalance,
    refetch: refetchBalance,
  } = useBalance({
    account,
    query: {
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
    },
  });

  useEffect(() => {
    const checkAndSetConnector = async () => {
      const hasConnector = await fuel.hasConnector();
      if (!hasConnector) {
        const savedConnector = Cookies.get("selectedConnector");
        const connectorToUse =
          savedConnector && connectors.find((c) => c.name === savedConnector)
            ? savedConnector
            : "Fuel Wallet";

        await fuel.selectConnector(connectorToUse);
      }
    };

    checkAndSetConnector();
  }, [fuel, connectors]);

  useEffect(() => {
    if (currentConnector) {
      Cookies.set("selectedConnector", currentConnector.name, { expires: 30 });
    }
  }, [currentConnector]);

  const { wallet } = useFuelWallet();
  const isLoading = [isLoadingAccount, isLoadingCurrentConnector].some(Boolean);
  const isFetching = [isFetchingAccount].some(Boolean);
  const { isConnected } = useIsConnected();

  const { disconnect } = useDisconnect();

  return {
    account,
    balance,
    isConnected,
    isConnecting,
    isLoading,
    isFetching,
    isLoadingConnectors,
    wallet,
    connect,
    disconnect,
  };
};

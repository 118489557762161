import {
  Text,
  Link,
  Image,
  Button,
  HStack,
  ButtonGroup,
  Hide,
  Divider,
  Grid,
  GridItem,
  Show,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import Logo from "../../images/LogoH.png";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { BN } from "fuels";
import { useFluid } from "../../hooks/FluidProvider";
import { useFuel } from "@fuels/react";
import { useWallet } from "../../hooks/useWallet";
import {
  ChevronDownIcon,
  CopyIcon,
  ExternalLinkIcon,
  UnlockIcon,
  RepeatIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import { formatBNtoHumanReadable, PRECISION } from "../../shared/format";
import { TestnetWarning } from "./TestnetWarning";
import { AssetMenuItem } from "./AssetMenuItem";
import { WalletAssets } from "./wallet-assets";
import { MintTestAssets } from "./MintTestAssets";
import FuelLogo from "../../images/fuel-logo.svg";

export const TitleBar = () => {
  const { account, isConnected, disconnect, connect } = useWallet();
  const toast = useToast();
  const {
    fptBalance,
    usdfBalance,
    assets,
    totalUsdfMinted,
    fptPrice,
    reloadData,
    isTestnet,
    contracts,
    setIsTestnet,
  } = useFluid();
  const { fuel } = useFuel();
  const [assetsAdded, setAssetsAdded] = useState(false);

  async function onClickConnect() {
    if (isConnected) {
      disconnect();
      return;
    } else {
      connect();
    }
  }

  async function switchNetwork() {
    try {
      const currentNetwork = await fuel.currentNetwork();
      const currentConnector = await fuel.currentConnector();

      if (currentConnector?.name === "Ethereum Wallets") {
        if (isTestnet === "mainnet") {
          await fuel.selectNetwork({ chainId: 0 });
          setIsTestnet("testnet");
        } else {
          await fuel.selectNetwork({ chainId: 9889 });
          setIsTestnet("mainnet");
        }
      } else {
        if (currentNetwork.chainId === 0) {
          await fuel.selectNetwork({ chainId: 9889 });
        } else {
          await fuel.selectNetwork({ chainId: 0 });
        }
      }

      setTimeout(() => {
        reloadData();
      }, 1500);
    } catch (error) {
      console.error("Error switching network:", error);
    }
  }

  function copyAddress() {
    if (account) {
      navigator.clipboard.writeText(account);
      toast({
        description: "Address copied to clipboard!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  }

  function openExplorer() {
    if (account) {
      window.open(`https://app.fuel.network/account/${account}`, "_blank");
    }
  }
  const currentPage = window.location.pathname;
  const calculateTVL = () => {
    return assets.reduce((total, asset) => {
      const assetValue = asset.totalInActivePool
        .mul(asset.price)
        .div(PRECISION);
      return total.add(assetValue);
    }, new BN(0));
  };

  const tvl = calculateTVL();

  async function checkAndAddAssets() {
    const assets = await fuel.assets();
    let containsAll = true;

    if (assets) {
      const requiredAssets = [
        contracts.UsdfAssetId,
        contracts.assets[0].assetId,
        contracts.assets[1].assetId,
        contracts.fptAssetId,
      ];

      const existingAssets = assets.map((a) => a.name);
      containsAll = requiredAssets.every((asset) =>
        existingAssets.includes(asset)
      );
    }

    if (!containsAll) {
      await fuel.addAssets(WalletAssets);
    }
    setAssetsAdded(true);
    return !containsAll;
  }

  return (
    <>
      {isTestnet === "testnet" && <TestnetWarning />}
      <Grid
        templateColumns={[
          "1fr auto",
          "1fr auto",
          "2fr 2fr 1fr auto",
          "1fr 2fr 1fr auto",
        ]}
        alignItems="center"
        gap={[2, 0]}
        width="100%"
      >
        <Hide below="lg">
          <GridItem>
            <a href="/" style={{ display: "flex", alignItems: "center" }}>
              <Image
                position={"relative"}
                w={"250px"}
                right={[0, 5]}
                top={"-0.1"}
                height={"80px"}
                src={Logo}
                alt="Logo"
              />
            </a>
          </GridItem>
        </Hide>

        <GridItem
          colSpan={[1, 1, 2, 1]}
          justifySelf={["flex-start", "center", "flex-start", "center"]}
          w={["100%", "100%", "auto", "auto"]}
        >
          <Hide below="sm">
            <ButtonGroup
              gap={8}
              color={"textSecondary"}
              fontFamily="IBM Plex Mono"
              fontSize={["xxx-small", "small", "md"]}
              fontWeight={"bold"}
              display="flex"
              justifyContent="center"
            >
              <Link color={currentPage === "/" ? "textPrimary" : ""} href="/">
                Borrow
              </Link>
              <Link
                color={currentPage === "/my-troves" ? "textPrimary" : ""}
                href="/my-troves"
              >
                My Troves
              </Link>
              <Link
                color={currentPage === "/earn" ? "textPrimary" : ""}
                href="/earn"
              >
                Earn
              </Link>
              <Link
                color={currentPage === "/staking" ? "textPrimary" : ""}
                href="/staking"
              >
                Staking
              </Link>
              <Tooltip label="Swap on Mira" hasArrow>
                <Link
                  color={currentPage === "/staking" ? "textPrimary" : ""}
                  href="https://mira.ly/swap/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Swap <ExternalLinkIcon mx="2px" />
                </Link>
              </Tooltip>
            </ButtonGroup>
          </Hide>
          <Show below="sm">
            <Menu>
              <MenuButton
                size={"sm"}
                justifySelf={"flex-start"}
                w={"100%"}
                as={Button}
                rightIcon={<ChevronDownIcon />}
                mt={[2, 0, 0, 0]}
              >
                Menu
              </MenuButton>
              <MenuList>
                <Link href="/">
                  <MenuItem>Borrow</MenuItem>
                </Link>
                <Link href="/my-troves">
                  <MenuItem>My Troves</MenuItem>
                </Link>
                <Link href="/earn">
                  <MenuItem>Earn</MenuItem>
                </Link>
                <Link href="/staking">
                  <MenuItem>Staking</MenuItem>
                </Link>
              </MenuList>
            </Menu>
          </Show>
        </GridItem>

        <GridItem colSpan={[1, 1, 2, 1]} justifySelf="flex-end">
          {!isConnected ? (
            <ButtonGroup display="flex" justifyContent="flex-end" width="100%">
              <Button
                onClick={() => {
                  onClickConnect();
                }}
                size={["sm", "xs", "md"]}
              >
                Connect
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup
              flexDir={["column", "column", "row"]}
              justifySelf={"flex-end"}
              fontFamily={"IBM Plex Mono"}
              fontWeight={"bold"}
              w={["100%", "100%", "auto"]}
              spacing={[0, 0, 4]}
            >
              <Hide below="md">
                {isTestnet !== "testnet" && (
                  <Button
                    size={["sm", "xs", "md"]}
                    onClick={() =>
                      window.open(
                        "https://app.fuel.network/earn-points/",
                        "_blank"
                      )
                    }
                    leftIcon={
                      <Image src={FuelLogo} alt="Fuel Logo" boxSize="20px" />
                    }
                  >
                    Points
                  </Button>
                )}
                {isTestnet === "testnet" && <MintTestAssets />}
              </Hide>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  {account?.slice(0, 8) + "..." + account?.slice(-3)}
                </MenuButton>
                <MenuList
                  className="wallet-dropdown"
                  backgroundColor={"bgLightGrey"}
                  fontFamily={"IBM Plex Mono"}
                  fontWeight={"bold"}
                >
                  <MenuItem
                    backgroundColor={"bgLightGrey"}
                    _hover={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={copyAddress}
                    fontSize={["sm", "md"]}
                    fontWeight={"bold"}
                    zIndex={1000}
                  >
                    <CopyIcon mr={2} /> Copy Address
                  </MenuItem>
                  <MenuItem
                    backgroundColor={"bgLightGrey"}
                    _hover={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={openExplorer}
                    fontSize={["sm", "md"]}
                    fontWeight={"bold"}
                  >
                    <ExternalLinkIcon mr={2} /> View on Explorer
                  </MenuItem>
                  <MenuItem
                    backgroundColor={"bgLightGrey"}
                    _hover={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={() => {
                      onClickConnect();
                    }}
                    fontSize={["sm", "md"]}
                    fontWeight={"bold"}
                  >
                    <UnlockIcon mr={2} /> Disconnect
                  </MenuItem>
                  <MenuItem
                    backgroundColor={"bgLightGrey"}
                    _hover={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={() => {
                      switchNetwork();
                    }}
                    fontSize={["sm", "md"]}
                    fontWeight={"bold"}
                  >
                    <RepeatIcon mr={2} /> Switch to{" "}
                    {isTestnet === "testnet" ? "Mainnet" : "Testnet"}
                  </MenuItem>
                  {!assetsAdded && (
                    <MenuItem
                      backgroundColor={"bgLightGrey"}
                      _hover={{
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                      onClick={checkAndAddAssets}
                      fontSize={["sm", "md"]}
                      fontWeight={"bold"}
                    >
                      <DownloadIcon mr={2} /> Add Assets to Wallet
                    </MenuItem>
                  )}
                  <Divider />
                  <AssetMenuItem
                    symbol="FPT"
                    balance={fptBalance}
                    imageUrl="https://i.imgur.com/SLGOAB7.png"
                    price={fptPrice}
                    decimals={2}
                  />
                  <AssetMenuItem
                    symbol="USDF"
                    balance={usdfBalance}
                    imageUrl="https://i.imgur.com/qI2cyjF.jpg"
                    price={new BN(PRECISION)}
                    decimals={2}
                  />
                  {assets
                    .filter((asset) => !asset.balance.isZero())
                    .sort((a, b) => {
                      const valueA = a.balance.mul(a.price);
                      const valueB = b.balance.mul(b.price);
                      return valueB.gt(valueA) ? 1 : -1;
                    })
                    .map((asset) => (
                      <AssetMenuItem
                        key={asset.contractIds.assetId}
                        symbol={asset.symbol}
                        balance={asset.balance}
                        imageUrl={asset.contractIds.imageUrl}
                        price={asset.price}
                        decimals={4}
                      />
                    ))}
                </MenuList>
              </Menu>
            </ButtonGroup>
          )}
        </GridItem>
      </Grid>

      <Divider mb={2} />
      <Hide below="md">
        <HStack mb={10} minHeight={"20px"}>
          <Text
            fontSize={"sm"}
            fontWeight={"semibold"}
            fontFamily={"IBM Plex Mono"}
            textAlign={"left"}
          >
            TVL&nbsp;
            {tvl.isZero() ? "-----" : formatBNtoHumanReadable(tvl, 9, 2)}
            &nbsp;USD &#183;{" "}
            {totalUsdfMinted.isZero()
              ? "-----"
              : formatBNtoHumanReadable(totalUsdfMinted, 9, 2)}
            &nbsp;USDF&nbsp;Minted
          </Text>
        </HStack>
      </Hide>
    </>
  );
};

import { BN } from "@fuel-ts/math";

export function formatBNtoHumanReadable(
  bn: BN,
  precision: number,
  sigFigs: number = 3
): string {
  const divisor = new BN(10).pow(new BN(precision));
  const absNum = bn.abs();
  const billion = new BN(10).pow(new BN(9 + precision));
  const million = new BN(10).pow(new BN(6 + precision));
  const thousand = new BN(10).pow(new BN(3 + precision));

  if (absNum.gte(billion)) {
    return formatWithSuffix(bn, billion, "B", sigFigs);
  } else if (absNum.gte(million)) {
    return formatWithSuffix(bn, million, "M", sigFigs);
  } else if (absNum.gte(thousand)) {
    return formatWithSuffix(bn, thousand, "k", sigFigs);
  } else {
    return formatDecimal(bn, divisor, sigFigs);
  }
}

function formatWithSuffix(
  bn: BN,
  divisor: BN,
  suffix: string,
  sigFigs: number
): string {
  const quotient = bn.div(divisor);
  const remainder = bn.mod(divisor);
  const decimalPart = remainder
    .mul(new BN(10).pow(new BN(sigFigs)))
    .div(divisor);
  return `${quotient.toString()}.${decimalPart
    .toString()
    .padStart(sigFigs, "0")}${suffix}`;
}

function formatDecimal(bn: BN, divisor: BN, sigFigs: number): string {
  const quotient = bn.div(divisor);
  const remainder = bn.mod(divisor);
  const decimalPart = remainder
    .mul(new BN(10).pow(new BN(sigFigs)))
    .div(divisor);
  return `${quotient.toString()}.${decimalPart
    .toString()
    .padStart(sigFigs, "0")}`;
}

export function parseBN(
  bn: BN,
  precision: number,
  decimals: number = 2
): string {
  try {
    var num = parseInt(bn.toString());
    num = num / 10 ** precision;
    num = Math.round(num * 10 ** decimals) / 10 ** decimals;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  } catch {
    return "0." + "0".repeat(decimals);
  }
}

export const getColorScheme = (percentage: number) => {
  if (percentage < 135) return "red";
  if (percentage < 150) return "orange";
  if (percentage < 170) return "yellow";
  return "green";
};

export const PRECISION = 10 ** 9;

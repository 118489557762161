import {
  Text,
  VStack,
  Button,
  Card,
  CardBody,
  Container,
  HStack,
  Input,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFluid } from "../../hooks/FluidProvider";
import { useAdminOperations } from "../../hooks/useAdminOperations";

export const AdminCard = () => {
  const { borrowOperationsContract } = useFluid();
  const { setPauseStatus, isLoading, setPauser } = useAdminOperations();
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [newPauser, setNewPauser] = useState<string>("");

  useEffect(() => {
    getContractStatus();
  }, [borrowOperationsContract]);

  async function getContractStatus() {
    if (borrowOperationsContract) {
      const status = await borrowOperationsContract.functions
        .get_is_paused()
        .get();
      setIsPaused(status.value);
    }
  }

  const handleToggle = () => {
    setPauseStatus(!isPaused);
  };

  const handleSetPauser = () => {
    if (newPauser) {
      setPauser(newPauser);
    }
  };

  return (
    <Card variant={"darkCard"} fontFamily={"IBM Plex Mono"} fontWeight={"bold"}>
      <CardBody w="100%" alignItems={"center"}>
        <VStack w={"100%"} gap={4}>
          <Text
            fontWeight={"semibold"}
            alignSelf={"start"}
            fontFamily={"IBM Plex Mono"}
            fontStyle={"bold"}
          >
            Contract Administration
          </Text>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text fontSize={"md"} textAlign={"left"}>
                Manage contract pause status. When paused, users cannot perform
                any operations.
              </Text>

              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
                pt={4}
              >
                <Text color={"textSecondary"}>Contract Status</Text>
                <Button
                  onClick={handleToggle}
                  isLoading={isLoading}
                  colorScheme={isPaused ? "green" : "red"}
                >
                  {isPaused ? "Unpause Contract" : "Pause Contract"}
                </Button>
              </HStack>
              <Text fontSize={"sm"} color={"textSecondary"}>
                {isPaused
                  ? "Contract is currently paused"
                  : "Contract is currently active"}
              </Text>
            </VStack>
          </Container>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text fontSize={"md"} textAlign={"left"}>
                Set contract pauser address
              </Text>

              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
                pt={4}
              >
                <Input
                  placeholder="Enter new pauser address"
                  value={newPauser}
                  onChange={(e) => setNewPauser(e.target.value)}
                />
                <Button
                  onClick={handleSetPauser}
                  isLoading={isLoading}
                  colorScheme="blue"
                >
                  Set Pauser
                </Button>
              </HStack>
            </VStack>
          </Container>
        </VStack>
      </CardBody>
    </Card>
  );
};

import {
  Text,
  VStack,
  Button,
  HStack,
  Card,
  CardBody,
  Divider,
  Container,
  useToast,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { BN } from "fuels";
import { useEffect, useState } from "react";
import { useFluid } from "../../hooks/FluidProvider";
import { parseBN, PRECISION } from "../../shared/format";
import {
  IdentityInput,
  VestingContract,
  VestingScheduleOutput,
} from "../../types/vesting-contract/VestingContract";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";

// Mock data for the vesting schedule
const mockVestingSchedule: VestingScheduleOutput = {
  total_amount: new BN("20000000000"), // 1,000 FPT (assuming 9 decimals)
  claimed_amount: new BN("0"), // 100 FPT
  cliff_amount: new BN("10000000000"), // 200 FPT
  cliff_timestamp: new BN(Math.floor(Date.now() / 1000)), // Today
  end_timestamp: new BN(Math.floor(Date.now() / 1000) + 365 * 24 * 60 * 60), // 1 year from now
  recipient: {
    Address: { bits: "0x0000000000000000000000000000000000000000" },
  },
};
function convertTai64ToUnix(num: BN): BN {
  return num.sub(new BN(2).pow(62)).sub(new BN(10));
}

function convertUnixToTai64(num: BN): BN {
  return num.add(new BN(2).pow(62)).add(new BN(10));
}

export const VestingCard = () => {
  const [vestingSchedule, setVestingSchedule] =
    useState<VestingScheduleOutput | null>(mockVestingSchedule);
  const [claimableAmount, setClaimableAmount] = useState<BN>(
    new BN("50000000000000000")
  ); // 50 FPT
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const { address, reloadData, contracts, wallet } = useFluid();

  const [chartData, setChartData] = useState<
    Array<{ date: Date; amount: number }>
  >([]);

  const [vestingContract, setVestingContract] =
    useState<VestingContract | null>(null);

  useEffect(() => {
    if (contracts) {
      setVestingContract(new VestingContract(contracts.vesting, wallet));
    }
  }, [wallet, contracts]);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getVestingSchedule();
    getClaimableAmount();
  }, [vestingContract, address]);

  useEffect(() => {
    if (vestingSchedule) {
      generateChartData();
    }
  }, [vestingSchedule]);

  function generateChartData() {
    if (!vestingSchedule) return;

    const endDate = new Date(
      Number(convertTai64ToUnix(vestingSchedule.end_timestamp)) * 1000
    );
    const totalAmount = Number(vestingSchedule.total_amount) / PRECISION;
    const cliffAmount = Number(vestingSchedule.cliff_amount) / PRECISION;
    const cliffDate = new Date(
      Number(convertTai64ToUnix(vestingSchedule.cliff_timestamp)) * 1000
    );
    const startDate = new Date(cliffDate.getTime() - 3 * 24 * 60 * 60 * 1000); // - 3 days

    const data = [];
    data.push({ date: startDate, amount: 0 });
    data.push({ date: cliffDate, amount: cliffAmount });

    const remainingAmount = totalAmount - cliffAmount;
    const remainingDays =
      (endDate.getTime() - cliffDate.getTime()) / (1000 * 60 * 60 * 24);
    const dailyVesting = remainingAmount / remainingDays;

    for (let i = 1; i <= remainingDays; i++) {
      const currentDate = new Date(
        cliffDate.getTime() + i * 24 * 60 * 60 * 1000
      );
      const currentAmount = cliffAmount + dailyVesting * i;
      data.push({ date: currentDate, amount: currentAmount });
    }
    setChartData(data);
  }

  async function getClaimableAmount() {
    if (vestingContract && address) {
      try {
        let addressInput: IdentityInput = { Address: { bits: address } };
        let timestamp = convertUnixToTai64(
          new BN(Math.floor(Date.now() / 1000))
        );
        const claimableAmount = await vestingContract.functions
          .get_redeemable_amount(timestamp, addressInput)
          .get();
        setClaimableAmount(claimableAmount.value);
      } catch (e) {
        setClaimableAmount(new BN(0));
      }
    }
  }

  async function getVestingSchedule() {
    if (vestingContract && address) {
      try {
        let addressInput: IdentityInput = { Address: { bits: address } };
        const vestingSchedule = await vestingContract.functions
          .get_vesting_schedule(addressInput)
          .get();

        setVestingSchedule(vestingSchedule.value);
        setError(null);
      } catch (e) {
        setVestingSchedule(null);
        setError("There is no vesting schedule associated with this address.");
      }
    }
  }

  async function claimVestedTokens() {
    if (vestingContract && address) {
      setIsLoading(true);

      try {
        await vestingContract.functions
          .claim_vested_tokens()
          .txParams({ gasLimit: 500000 })
          .call();

        toast({
          title: "Claim Successful",
          description: "You have successfully claimed your vested tokens!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        // wait for 1 second before reloading data
        setTimeout(() => {
          reloadData();
          setIsLoading(false);
        }, 1000);
      } catch (e: any) {
        toast({
          title: "Error",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <Card variant={"darkCard"} fontFamily={"IBM Plex Mono"} fontWeight={"bold"}>
      <CardBody w="100%" alignItems={"center"}>
        <VStack w={"100%"} gap={4}>
          <Text fontWeight={"semibold"} alignSelf={"start"}>
            FPT Vesting
          </Text>

          {error ? (
            <Container
              backgroundColor={"bgLightGrey"}
              py={4}
              borderRadius={10}
              pb={4}
              width={"100%"}
              maxW="none"
            >
              <Text fontSize={"md"} textAlign={"center"}>
                {error}
              </Text>
            </Container>
          ) : (
            <>
              <Container
                backgroundColor={"bgLightGrey"}
                py={4}
                borderRadius={10}
                pb={4}
                width={"100%"}
                maxW="none"
              >
                <VStack w={"100%"} gap={"2"}>
                  <Text fontSize={"md"} textAlign={"left"}>
                    Your FPT tokens are vested over time. Claim your vested
                    tokens as they become available.
                  </Text>

                  {vestingSchedule && (
                    <>
                      <Divider />
                      <HStack
                        fontSize={"md"}
                        w={"100%"}
                        justifyContent={"space-between"}
                      >
                        <Text color={"textSecondary"}>
                          Total Vesting Amount
                        </Text>
                        <Text pr={2}>
                          {parseBN(vestingSchedule.total_amount, 9) + " FPT"}
                        </Text>
                      </HStack>
                      <HStack
                        fontSize={"md"}
                        w={"100%"}
                        justifyContent={"space-between"}
                      >
                        <Text color={"textSecondary"}>Claimed Amount</Text>
                        <Text pr={2}>
                          {parseBN(vestingSchedule.claimed_amount, 9) + " FPT"}
                        </Text>
                      </HStack>
                      <HStack
                        fontSize={"md"}
                        w={"100%"}
                        justifyContent={"space-between"}
                      >
                        <Text color={"textSecondary"}>Cliff Amount</Text>
                        <Text pr={2}>
                          {parseBN(vestingSchedule.cliff_amount, 9) + " FPT"}
                        </Text>
                      </HStack>
                      <HStack
                        fontSize={"md"}
                        w={"100%"}
                        justifyContent={"space-between"}
                      >
                        <Text color={"textSecondary"}>Cliff Date</Text>
                        <Text pr={2}>
                          {new Date(
                            Number(
                              convertTai64ToUnix(
                                vestingSchedule.cliff_timestamp
                              )
                            ) * 1000
                          ).toLocaleString()}
                        </Text>
                      </HStack>
                      <HStack
                        fontSize={"md"}
                        w={"100%"}
                        justifyContent={"space-between"}
                      >
                        <Text color={"textSecondary"}>End Date</Text>
                        <Text pr={2}>
                          {new Date(
                            Number(
                              convertTai64ToUnix(vestingSchedule.end_timestamp)
                            ) * 1000
                          ).toLocaleString()}
                        </Text>
                      </HStack>
                    </>
                  )}
                </VStack>
              </Container>

              <Container
                backgroundColor={"bgLightGrey"}
                py={4}
                borderRadius={10}
                pb={4}
                width={"100%"}
                maxW="none"
              >
                <VStack w={"100%"} gap={"2"}>
                  <Text>Claimable Tokens</Text>
                  <HStack
                    fontSize={"md"}
                    w={"100%"}
                    justifyContent={"space-between"}
                  >
                    <Text color={"textSecondary"}>Available to Claim</Text>
                    <Text pr={2}>{parseBN(claimableAmount, 9) + " FPT"}</Text>
                  </HStack>

                  <Button
                    onClick={claimVestedTokens}
                    colorScheme={"green"}
                    w={"100%"}
                    isDisabled={claimableAmount.lte(new BN(0))}
                    isLoading={isLoading}
                  >
                    Claim Vested Tokens
                  </Button>
                </VStack>
              </Container>

              {vestingSchedule && (
                <Container
                  backgroundColor={"bgLightGrey"}
                  py={4}
                  borderRadius={10}
                  pb={4}
                  width={"100%"}
                  maxW="none"
                >
                  <Text mb={4}>Vesting Schedule</Text>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={chartData}>
                      <XAxis
                        dataKey="date"
                        tickFormatter={(date) =>
                          new Date(date).toLocaleDateString()
                        }
                        interval={Math.floor(chartData.length / 5)} // Show about 5 ticks
                      />
                      <YAxis />
                      <RechartsTooltip
                        labelFormatter={(label) =>
                          new Date(label).toLocaleDateString()
                        }
                        formatter={(value) => [
                          `${value.toFixed(2)} FPT`,
                          "Vested Amount",
                        ]}
                      />
                      <Line
                        type="monotone"
                        dataKey="amount"
                        stroke="#8884d8"
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Container>
              )}
            </>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

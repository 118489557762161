/* Autogenerated file. Do not edit manually. */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */

/*
  Fuels version: 0.97.0
*/

import { Contract, Interface } from "fuels";
import type {
  Provider,
  Account,
  StorageSlot,
  AbstractAddress,
  BigNumberish,
  BN,
  FunctionFragment,
  InvokeFunction,
  StrSlice,
} from 'fuels';

import type { Enum, Vec } from "./common";

export type IdentityInput = Enum<{ Address: AddressInput, ContractId: ContractIdInput }>;
export type IdentityOutput = Enum<{ Address: AddressOutput, ContractId: ContractIdOutput }>;

export type AddressInput = { bits: string };
export type AddressOutput = AddressInput;
export type AssetIdInput = { bits: string };
export type AssetIdOutput = AssetIdInput;
export type ContractIdInput = { bits: string };
export type ContractIdOutput = ContractIdInput;
export type VestingScheduleInput = { cliff_timestamp: BigNumberish, end_timestamp: BigNumberish, cliff_amount: BigNumberish, total_amount: BigNumberish, claimed_amount: BigNumberish, recipient: IdentityInput };
export type VestingScheduleOutput = { cliff_timestamp: BN, end_timestamp: BN, cliff_amount: BN, total_amount: BN, claimed_amount: BN, recipient: IdentityOutput };

export type VestingContractConfigurables = Partial<{
  INITIALIZER: IdentityInput;
  TOTAL_AMOUNT: BigNumberish;
}>;

const abi = {
  "programType": "contract",
  "specVersion": "1",
  "encodingVersion": "1",
  "concreteTypes": [
    {
      "type": "()",
      "concreteTypeId": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d"
    },
    {
      "type": "bool",
      "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
    },
    {
      "type": "enum std::identity::Identity",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "metadataTypeId": 1
    },
    {
      "type": "str",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    },
    {
      "type": "struct data_structures::VestingSchedule",
      "concreteTypeId": "5e482a533e1f58051d0900ed13001ef71ce797519f59c8493b864979aea1cbe6",
      "metadataTypeId": 4
    },
    {
      "type": "struct std::asset_id::AssetId",
      "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974",
      "metadataTypeId": 6
    },
    {
      "type": "struct std::vec::Vec<struct data_structures::VestingSchedule>",
      "concreteTypeId": "f969bc0a23d9af9b1d595b7d8b4d2ccb89c5c0ede7d15131858dad5a32eb248f",
      "metadataTypeId": 9,
      "typeArguments": [
        "5e482a533e1f58051d0900ed13001ef71ce797519f59c8493b864979aea1cbe6"
      ]
    },
    {
      "type": "u64",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
    }
  ],
  "metadataTypes": [
    {
      "type": "b256",
      "metadataTypeId": 0
    },
    {
      "type": "enum std::identity::Identity",
      "metadataTypeId": 1,
      "components": [
        {
          "name": "Address",
          "typeId": 5
        },
        {
          "name": "ContractId",
          "typeId": 7
        }
      ]
    },
    {
      "type": "generic T",
      "metadataTypeId": 2
    },
    {
      "type": "raw untyped ptr",
      "metadataTypeId": 3
    },
    {
      "type": "struct data_structures::VestingSchedule",
      "metadataTypeId": 4,
      "components": [
        {
          "name": "cliff_timestamp",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "end_timestamp",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "cliff_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "total_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "claimed_amount",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "recipient",
          "typeId": 1
        }
      ]
    },
    {
      "type": "struct std::address::Address",
      "metadataTypeId": 5,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::asset_id::AssetId",
      "metadataTypeId": 6,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::contract_id::ContractId",
      "metadataTypeId": 7,
      "components": [
        {
          "name": "bits",
          "typeId": 0
        }
      ]
    },
    {
      "type": "struct std::vec::RawVec",
      "metadataTypeId": 8,
      "components": [
        {
          "name": "ptr",
          "typeId": 3
        },
        {
          "name": "cap",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "typeParameters": [
        2
      ]
    },
    {
      "type": "struct std::vec::Vec",
      "metadataTypeId": 9,
      "components": [
        {
          "name": "buf",
          "typeId": 8,
          "typeArguments": [
            {
              "name": "",
              "typeId": 2
            }
          ]
        },
        {
          "name": "len",
          "typeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "typeParameters": [
        2
      ]
    }
  ],
  "functions": [
    {
      "inputs": [],
      "name": "claim_vested_tokens",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "asset",
          "concreteTypeId": "c0710b6731b1dd59799cf6bef33eee3b3b04a2e40e80a0724090215bbf2ca974"
        },
        {
          "name": "schedules",
          "concreteTypeId": "f969bc0a23d9af9b1d595b7d8b4d2ccb89c5c0ede7d15131858dad5a32eb248f"
        },
        {
          "name": "debugging",
          "concreteTypeId": "b760f44fa5965c2474a3b471467a22c43185152129295af588b022ae50b50903"
        }
      ],
      "name": "constructor",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "write",
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_current_time",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "at_timestamp",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        },
        {
          "name": "address",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_redeemable_amount",
      "output": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "address",
          "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335"
        }
      ],
      "name": "get_vesting_schedule",
      "output": "5e482a533e1f58051d0900ed13001ef71ce797519f59c8493b864979aea1cbe6",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "time",
          "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0"
        }
      ],
      "name": "set_current_time",
      "output": "2e38e77b22c314a449e91fafed92a43826ac6aa403ae6a8acb6cf58239fbaf5d",
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "write",
            "read"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": "10098701174489624218",
      "concreteTypeId": "8c25cb3686462e9a86d2883c5688a22fe738b0bbc85f458d2d2b5f3f667c6d5a"
    }
  ],
  "messagesTypes": [],
  "configurables": [
    {
      "name": "INITIALIZER",
      "concreteTypeId": "ab7cd04e05be58e3fc15d424c2c4a57f824a2a2d97d67252440a3925ebdc1335",
      "offset": 32312
    },
    {
      "name": "TOTAL_AMOUNT",
      "concreteTypeId": "1506e6f44c1d6291cdf46395a8e573276a4fa79e8ace3fc891e092ef32d1b0a0",
      "offset": 32352
    }
  ]
};

const storageSlots: StorageSlot[] = [
  {
    "key": "1aaf6dfbee54188c1ff1477eec69d0ebc5a26204dcbbaef8a407322c1f65ac92",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "38a1b038206f348ec947ba5770c6d713148c9d8bb0b312f47acc88777da1c09d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "7665901a6526e8582efbdd717856a5f8de5f79fa9a784e46e257f28a0e6e8874",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  },
  {
    "key": "9e39cce573e16c4eb034ec33f6641ef797822070b45760ef0edafbb57a145b1d",
    "value": "0000000000000000000000000000000000000000000000000000000000000000"
  }
];

export class VestingContractInterface extends Interface {
  constructor() {
    super(abi);
  }

  declare functions: {
    claim_vested_tokens: FunctionFragment;
    constructor: FunctionFragment;
    get_current_time: FunctionFragment;
    get_redeemable_amount: FunctionFragment;
    get_vesting_schedule: FunctionFragment;
    set_current_time: FunctionFragment;
  };
}

export class VestingContract extends Contract {
  static readonly abi = abi;
  static readonly storageSlots = storageSlots;

  declare interface: VestingContractInterface;
  declare functions: {
    claim_vested_tokens: InvokeFunction<[], void>;
    constructor: InvokeFunction<[asset: AssetIdInput, schedules: Vec<VestingScheduleInput>, debugging: boolean], void>;
    get_current_time: InvokeFunction<[], BN>;
    get_redeemable_amount: InvokeFunction<[at_timestamp: BigNumberish, address: IdentityInput], BN>;
    get_vesting_schedule: InvokeFunction<[address: IdentityInput], VestingScheduleOutput>;
    set_current_time: InvokeFunction<[time: BigNumberish], void>;
  };

  constructor(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider,
  ) {
    super(id, abi, accountOrProvider);
  }
}

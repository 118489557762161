import { VStack, Grid, GridItem, Text } from "@chakra-ui/react";
import { PageWrapper } from "../components/common/PageWrapper";
import { VestingCard } from "../components/vesting/VestingCard";
import { ConnectWalletButton } from "../components/common/ConnectWalletButton";
import { useWallet } from "../hooks/useWallet";

export const Vesting = () => {
  const { account } = useWallet();

  return (
    <PageWrapper>
      {!account ? (
        <VStack alignSelf={"center"} spacing={8} minW={["", "", "", "500px"]}>
          <Text>Please connect your wallet to view vesting information.</Text>
          <ConnectWalletButton />
        </VStack>
      ) : (
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          justifyContent="center"
          alignItems="center"
        >
          <GridItem colSpan={[3, 3, 3, 3]} justifySelf="center">
            <VestingCard />
          </GridItem>
        </Grid>
      )}
    </PageWrapper>
  );
};
